import types from './types';

const initialState = {
    isLoading: true,
    scheduleEvents: []
}

export default function schedule(state = initialState, action) {
    switch (action.type) {
        case types.SET_SCHEDULE_EVENTS:
            return {
                ...state,
                scheduleEvents: action.payload,
                isLoading: false
            }
        default:
            return state;
    }
}