import types from './types';

const initialState = {
    diagnosesLoading: true,
    diagnoses: [],
    diagnosesTotalElements: 0,
    diagnosesParams: {
        page: 0,
        size: 20,
        sort: 'code,asc'
    },
    diagnosesList: [],
    isLoadingImport: false
}

export default function diagnosis(state = initialState, action) {
    switch (action.type) {
        case types.SET_DIAGNOSES:
            return {
                ...state,
                diagnoses: action.payload.content,
                diagnosesTotalElements: action.payload ? action.payload.totalElements : 0,
                diagnosesLoading: false
            }
        case types.SET_DIAGNOSES_PAGE:
            return {
                ...state, diagnosesParams: { ...state.diagnosesParams, page: action.payload }
            }
        case types.SET_DIAGNOSES_SIZE:
            return {
                ...state, diagnosesParams: { ...state.diagnosesParams, size: action.payload, page: 0 }
            }
        case types.SET_DIAGNOSES_PARAMS:
            return {
                ...state,
                diagnosesParams: action.payload
            }
        case types.SET_DIAGNOSES_LIST:
            return {
                ...state,
                diagnosesList: action.payload
            }
        case types.SET_LOADING_IMPORT:
            return {
                ...state,
                isLoadingImport: action.payload
            }
        case types.RESET_DIAGNOSES_DATA:
            return {
                ...initialState,
                diagnosesParams: {
                    page: 0,
                    size: 20,
                    sort: 'code,asc'
                }
            }
        default:
            return state;
    }
}