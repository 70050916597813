import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    FormControl,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Check } from '@mui/icons-material'
import PropTypes from 'prop-types';
import {
    createService,
    updateService,
    getServiceById
} from '../../redux/service/actions';
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { CODE_REGEX, NAME_REGEX } from '../../components/common/regex';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class CreateOrUpdateService extends Component {

    emptyService = {
        serviceGroupId: null,
        code: '',
        name: '',
        comment: '',
        price: null
    };

    constructor(props) {
        super(props);
        this.state = {
            initialService: JSON.parse(JSON.stringify(this.emptyService)),
            service: JSON.parse(JSON.stringify(this.emptyService)),
            selectedServiceGroup: null
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.serviceToEdit && prevProps.serviceToEdit !== this.props.serviceToEdit) {
            this.props.getServiceById(this.props.serviceToEdit.toString()).then(response => {
                this.setState({
                    service: JSON.parse(JSON.stringify(response.data)),
                    initialService: JSON.parse(JSON.stringify(response.data)),
                    selectedServiceGroup: this.props.serviceGroupReducer.serviceGroupsList.find(s => s.id === response.data.serviceGroupId)
                });
            })
        }
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            service: JSON.parse(JSON.stringify(this.emptyService)),
            selectedServiceGroup: null
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let { service } = this.state;
        service[target.name] = target.value.trimStart()
        this.setState({ service: service })
    };

    handleChangePrice = (values) => {
        const { floatValue } = values;
        let { service } = this.state
        service.price = floatValue;
        this.setState({ service: service })
    }

    handleChangeServiceGroup = (e, group) => {
        let { service } = this.state;
        if (group !== null) {
            service.serviceGroupId = group.id
            this.setState({ selectedServiceGroup: group, service: service })
        } else {
            service.serviceGroupId = null
            this.setState({ selectedServiceGroup: null, service: service })
        }
    }

    createOrUpdateServiceGroup = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { service } = this.state;

        let serviceObject = {
            ...service,
            comment: service.comment && service.comment.toUpperCase()
        }
        const action = this.props.serviceToEdit ?
            this.props.updateService(this.props.serviceToEdit, serviceObject) :
            this.props.createService(serviceObject);
        action.then(response => {
            if (response.code !== 400) {
                this.setState({ service: JSON.parse(JSON.stringify(this.emptyService)), selectedServiceGroup: null });
                this.props.onSave();
            }
        })
    };

    validateForm() {
        let { service, initialService } = this.state;
        let valid = true;
        if (!NAME_REGEX.test(service.name)) {
            valid = false;
        }
        if (!CODE_REGEX.test(service.code)) {
            valid = false;
        }
        if (JSON.stringify(service) === JSON.stringify(initialService)) {
            valid = false
        }
        if (service.price === null) {
            valid = false
        }
        if (!service.serviceGroupId
            || !service.name
            || !service.code
            || service.price === null
        ) {
            valid = false;
        }
        return valid;
    }

    getNameHelperText() {
        let { service } = this.state;
        if (!service.name.trim()) {
            return this.props.t("required_field_message");
        }
        if (service.name.length > 0 && !NAME_REGEX.test(service.name)) {
            return this.props.t("name_regex_message", { min: 1, max: 255 });
        }
    }

    getNameHelperTextCode() {
        let { service } = this.state;
        if (!service.code.trim()) {
            return this.props.t("required_field_message");
        }
        if (service.code.length > 0 && !CODE_REGEX.test(service.code)) {
            return this.props.t("name_regex_message", { min: 1, max: 50 });
        }
    }

    render() {
        let { service, selectedServiceGroup } = this.state
        const { serviceGroupsList } = this.props.serviceGroupReducer

        return (
            <BootstrapDialog
                onClose={this.closeDialog}
                open={this.props.isOpen}
                TransitionComponent={Transition}>
                <BootstrapDialogTitle onClose={this.closeDialog}>
                    {this.props.serviceToEdit ? this.props.t("edit_service") : this.props.t("new_service")}
                </BootstrapDialogTitle>
                <form onSubmit={this.createOrUpdateServiceGroup} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container>
                                        <FormControl>
                                            <WrappedAutocomplete
                                                label={this.props.t("select_service_group")}
                                                placeholder={this.props.t("select_service_group")}
                                                name="selectedServiceGroup"
                                                value={selectedServiceGroup}
                                                getOptionLabel={(option) => option ? option.name : ""}
                                                options={serviceGroupsList}
                                                onChange={(event, group) => {
                                                    this.handleChangeServiceGroup(event, group)
                                                }}
                                                required
                                                error={!selectedServiceGroup}
                                                helperText={!selectedServiceGroup && this.props.t("required_field_message")}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("code")}
                                            name="code"
                                            value={service.code}
                                            onChange={this.handleChange}
                                            required
                                            error={!CODE_REGEX.test(service.code) || !service.code.trim()}
                                            helperText={this.getNameHelperTextCode()} />
                                    </Grid>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("name_service")}
                                            name="name"
                                            value={(service.name && service.name.charAt(0).toUpperCase() + service.name.slice(1))}
                                            onChange={this.handleChange}
                                            required
                                            error={!NAME_REGEX.test(service.name) || !service.name.trim()}
                                            helperText={this.getNameHelperText()} />
                                    </Grid>
                                    <Grid item container>
                                        <TextField
                                            multiline
                                            rows={3}
                                            label={this.props.t("comment")}
                                            name="comment"
                                            value={service.comment || ''}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                autoComplete: 'off',
                                                spellCheck: false,
                                                style: { textTransform: "uppercase" }
                                            }} />
                                    </Grid>
                                    <Grid item container>
                                        <NumberFormat
                                            label={this.props.t("price")}
                                            customInput={TextField}
                                            decimalScale={2}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            value={service.price || null}
                                            onValueChange={(values) => this.handleChangePrice(values)}
                                            allowNegative={false}
                                            suffix={" EUR"}
                                            required
                                            error={service.price === null}
                                            helperText={service.price === null && this.props.t("required_field_message")}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" className="default-button" onClick={this.closeDialog} >
                            <Close />{this.props.t("close")}
                        </Button>
                        <Button type="submit" color="primary" className="default-button" disabled={!this.validateForm()} >
                            <Check />{this.props.serviceToEdit ? this.props.t("update") : this.props.t("create")}
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    serviceGroupReducer: state.serviceGroupReducer
})

const mapActionsToProps = { createService, updateService, getServiceById }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateOrUpdateService)))
