import React, { Component } from 'react';
import { Grid, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'

class PatientInfo extends Component {

    render() {
        const { currentPatient } = this.props.patientReducer
        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={4} sm={4} md={4} lg={4} xl={4}>
                    {currentPatient.firstName && <Typography variant="h7" >
                        <strong>{this.props.t("first_name")}</strong>: {currentPatient.firstName}
                    </Typography>}
                    {currentPatient.lastName && <Typography variant="h7" >
                        <strong>{this.props.t("last_name")}</strong>: {currentPatient.lastName}
                    </Typography>}
                    {currentPatient.dateOfBirth && <Typography variant="h7" >
                        <strong>{this.props.t("date_of_birth")}</strong>: {currentPatient.dateOfBirth}
                    </Typography>}
                    {currentPatient.gender && <Typography variant="h7">
                        <strong>{this.props.t("gender")}</strong>: {this.props.t(currentPatient.gender)}
                    </Typography>}
                </Grid>
                {(currentPatient.email || currentPatient.address || currentPatient.city || currentPatient.county) && <Grid item container direction="column" xs={4} sm={4} md={4} lg={4} xl={4}>
                    {currentPatient.email && <Typography variant="h7">
                        <strong>{this.props.t("email")}</strong>: {currentPatient.email}
                    </Typography>}
                    {currentPatient.address && <Typography variant="h7">
                        <strong>{this.props.t("address")}</strong>: {currentPatient.address}
                    </Typography>}
                    {currentPatient.city && <Typography variant="h7">
                        <strong>{this.props.t("city")}</strong>: {currentPatient.city}
                    </Typography>}
                    {currentPatient.county && <Typography variant="h7">
                        <strong>{this.props.t("county")}</strong>: {currentPatient.county}
                    </Typography>}
                </Grid>}
                <Grid item container direction="column" xs={4} sm={4} md={4} lg={4} xl={4}>
                    {currentPatient.country && <Typography variant="h7">
                        <strong>{this.props.t("country")}</strong>: {currentPatient.country}
                    </Typography>}
                    {currentPatient.phone && <Typography variant="h7">
                        <strong>{this.props.t("phone")}</strong>: {currentPatient.phone}
                    </Typography>}
                    {currentPatient.source && <Typography variant="h7">
                        <strong>{this.props.t("source")}</strong>: {this.props.t(currentPatient.source)}
                    </Typography>}
                    {currentPatient.recommendedBy && <Typography variant="h7">
                        <strong>{this.props.t("recommended_by")}</strong>: {currentPatient.recommendedBy}
                    </Typography>}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer
})

export default connect(mapStateToProps, {})(withTranslation()(PatientInfo))