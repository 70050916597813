// https://material.io/resources/icons/?style=baseline

export const getSidebarItems = () => {
  return [
    {
      title: "statistics",
      icon: "dashboard",
      link: "/dashboard",
      roles: ["SUPERADMIN", "ADMIN", "SYSTEM"]
    },
    {
      title: "dashboard",
      icon: "dashboard",
      link: "/dashboard",
      roles: ["RECEPTIONIST"]
    },
    {
      title: "calendar",
      icon: "calendar_month",
      link: "/calendar",
      roles: ["RECEPTIONIST"]
    },
    {
      title: "lounge",
      icon: "weekend",
      link: "/dashboard",
      roles: ["DOCTOR"]
    },
    {
      title: "patients",
      icon: "personal_injury",
      link: "/patients",
      roles: ["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM", "RECEPTIONIST"]
    },
    {
      title: "infirmaries",
      icon: "local_hospital",
      link: "/infirmaries",
      roles: ["SUPERADMIN", "ADMIN", "SYSTEM"]
    },
    {
      title: "users",
      icon: "masks",
      link: "/users",
      roles: ["SUPERADMIN", "ADMIN", "SYSTEM"]
    },
    {
      title: "service_groups",
      icon: "medical_services",
      link: "/service-groups",
      roles: ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]
    },
    {
      title: "services",
      icon: "medical_information",
      link: "/services",
      roles: ["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM", "RECEPTIONIST"]
    },
    {
      title: "calendar",
      icon: "calendar_month",
      link: "/calendar",
      roles: ["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM"]
    },
    {
      title: "diagnoses",
      icon: "content_paste",
      link: "/diagnoses",
      roles: ["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM"]
    },
    {
      title: "offers",
      icon: "receipt",
      link: "/offers",
      roles: ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]
    },
    {
      title: "schedule",
      icon: "pending_actions",
      link: "/schedule",
      roles: ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]
    },
    {
      title: "settings",
      icon: "settings",
      link: "/settings",
      roles: ["SUPERADMIN", "ADMIN", "SYSTEM"]
    },
    {
      title: "change_logs",
      icon: "menu_book",
      link: "/change-logs",
      roles: ["SUPERADMIN"]
    }
  ];
}
