import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Container,
    Grid,
    Card,
    Typography,
    CardContent,
    CardActions
} from '@mui/material';
import { getInfirmaryList, occupyInfirmary } from '../../redux/infrimary/actions'
import { FaClinicMedical } from 'react-icons/fa';

class InfirmaryForDoctors extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.getInfirmaryList()
    }

    render() {
        const { infirmaryList } = this.props.infirmaryReducer;

        let cards = infirmaryList.map((data, index) => {
            return <Grid item container direction="column" xs={6} sm={6} md={6} lg={4} xl={4} key={index}>
                <Card style={{ textAlign: 'center', paddingTop: 70, backgroundColor: '#ebf0ed', borderRadius: '16%', boxShadow: data.currentOccupant ? '3px 5px 5px #EFA2A2' : '3px 5px 5px #c1edc0', cursor: 'pointer', marginBottom: '10px', height: '350px' }} onClick={data.currentOccupant ? null : () => this.props.occupyInfirmary(data.id)} >
                    <FaClinicMedical style={{ fontSize: '44px', color: '#87A397' }} />
                    <CardContent style={{ height: '150px' }}>
                        <Typography variant="h5" >{data.name}</Typography>
                    </CardContent>
                    <CardActions style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="body" style={{ color: data.currentOccupant ? '' : 'green' }}>{data.currentOccupant ? this.props.t("current_occupant") + ": " + data.currentOccupant.firstName + " " + data.currentOccupant.lastName : this.props.t("free")}</Typography>
                    </CardActions>
                </Card>
            </Grid>
        })

        return (<>
            <Container style={{ marginTop: 40 }}>
                <Grid container direction="row" spacing={4}>
                    {infirmaryList && infirmaryList.length > 0 ? cards : <Typography variant="h4" className='loading-position' style={{ color: 'red' }}>{this.props.t("no_infirmaries_message")}</Typography>}
                </Grid>
            </Container>
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    infirmaryReducer: state.infirmaryReducer
})

const mapActionsToProps = { getInfirmaryList, occupyInfirmary }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(InfirmaryForDoctors))
