import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Check } from '@mui/icons-material'
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { createGoogleCredentials } from '../../redux/setting/actions'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class CreateCredentialModal extends Component {

    emptyCredential = {
        type: '',
        project_id: '',
        private_key_id: '',
        private_key: '',
        client_email: '',
        client_id: '',
        auth_uri: '',
        token_uri: '',
        auth_provider_x509_cert_url: '',
        client_x509_cert_url: '',
        universe_domain: '',
        gmail_username: '',
        gmail_password: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            credential: JSON.parse(JSON.stringify(this.emptyCredential)),
        };
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({ credential: JSON.parse(JSON.stringify(this.emptyCredential)) });
    }

    handleChange = (event) => {
        const target = event.target;
        let { credential } = this.state;

        credential[target.name] = target.value;
        this.setState({ credential: credential })
    };


    createGoogleCredential = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { credential } = this.state;
        const requestData = {
            ...credential,
            private_key: credential.private_key.replaceAll("\\n", "\n")
        }
        this.props.createGoogleCredentials(requestData).then((response) => {
            if (response.status !== 400) {
                this.setState({ credential: JSON.parse(JSON.stringify(this.emptyCredential)) });
                this.props.onSave();
            }
        })
    };

    validateForm() {
        let { credential } = this.state;
        let valid = true;
        if (!credential.type || !credential.project_id || !credential.private_key || !credential.private_key_id ||
            !credential.client_email || !credential.client_id || !credential.auth_uri || !credential.token_uri || !credential.auth_provider_x509_cert_url ||
            !credential.client_x509_cert_url || !credential.universe_domain
        ) {
            valid = false;
        }
        return valid;
    }

    render() {
        let { credential } = this.state

        return (
            <BootstrapDialog
                onClose={this.closeDialog}
                open={this.props.isOpen}
                TransitionComponent={Transition}
                fullWidth
                maxWidth="md">
                <BootstrapDialogTitle onClose={this.closeDialog}>
                    {this.props.t("new_credentials")}
                </BootstrapDialogTitle>
                <form onSubmit={this.createGoogleCredential} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("type")}
                                        name="type"
                                        value={credential.type || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("project_id")}
                                        name="project_id"
                                        value={credential.project_id || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("private_key_id")}
                                        name="private_key_id"
                                        value={credential.private_key_id || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        type="text"
                                        label={this.props.t("private_key")}
                                        name="private_key"
                                        value={credential.private_key || ''}
                                        multiline
                                        rows={8}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("client_email")}
                                        name="client_email"
                                        value={credential.client_email || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("client_id")}
                                        name="client_id"
                                        value={credential.client_id || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("auth_uri")}
                                        name="auth_uri"
                                        value={credential.auth_uri || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("token_uri")}
                                        name="token_uri"
                                        value={credential.token_uri || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("auth_provider_x509_cert_url")}
                                        name="auth_provider_x509_cert_url"
                                        value={credential.auth_provider_x509_cert_url || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("client_x509_cert_url")}
                                        name="client_x509_cert_url"
                                        value={credential.client_x509_cert_url || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("universe_domain")}
                                        name="universe_domain"
                                        value={credential.universe_domain || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("gmail_username")}
                                        name="gmail_username"
                                        value={credential.gmail_username || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("gmail_password")}
                                        name="gmail_password"
                                        value={credential.gmail_password || ''}
                                        onChange={this.handleChange}
                                        required />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" className="default-button" onClick={this.closeDialog} >
                            <Close /> {this.props.t("close")}
                        </Button>
                        <Button type="submit" color="primary" className="default-button" disabled={!this.validateForm()} >
                            <Check /> {this.props.t("create")}
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = { createGoogleCredentials }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateCredentialModal)))
