import axios from '../axiosConfig';

export function getPatients(config = {}) {
    return axios.get(`/v1/patient`, config);
}

export function getPatientById(id, config = {}) {
    return axios.get(`/v1/patient/${id}`, config);
}

export function getPatientList(config = {}) {
    return axios.get(`/v1/patient/list`, config);
}

export function createPatient(data) {
    return axios.post(`/v1/patient`, data);
}

export function updatePatient(id, data) {
    return axios.patch(`/v1/patient/${id}`, data);
}

export function deletePatient(id) {
    return axios.delete(`/v1/patient/${id}`);
}

export function getGenders() {
    return axios.get(`/v1/patient/genders`);
}

export function getSources() {
    return axios.get(`/v1/patient/sources`);
}

export function getCounties() {
    return axios.get(`/v1/patient/counties`);
}

export function sendSignerEvent(config = {}) {
    return axios.get(`/v1/patient/signer-event`, config);
}

export function sendSignature(id, data, config = {}) {
    return axios.patch(`/v1/patient/${id}/gdpr`, data, config);
}

export function downloadGDPRDocument(id, config = {}) {
    return axios.get(`/v1/patient/signed-gdpr-doc/${id}`, config);
}

export function importFiles(id, data, config = {}) {
    return axios.post(`/v1/patients-file/${id}`, data, config);
}

export function getPatientFiles(id, config = {}) {
    return axios.get(`/v1/patients-file/list-of-files/${id}`, config);
}

export function filterPatientFiles(id, config = {}) {
    return axios.get(`/v1/patients-file/${id}/filtered-results`, config);
}

export function deleteFile(id, config = {}) {
    return axios.delete(`/v1/patients-file/${id}`, config)
}

export function downloadPatientFile(id, config = {}) {
    return axios.get(`/v1/patients-file/${id}`, config);
}

export function closeDocument(config = {}) {
    return axios.get(`/v1/patient/signer-event-close`, config);
}

const actions = {
    getPatients,
    getPatientById,
    createPatient,
    updatePatient,
    deletePatient,
    getGenders,
    getSources,
    getPatientList,
    sendSignerEvent,
    sendSignature,
    downloadGDPRDocument,
    getCounties,
    getPatientFiles,
    importFiles,
    deleteFile,
    downloadPatientFile,
    filterPatientFiles,
    closeDocument
}

export default actions;
