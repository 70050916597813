import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton } from '@mui/material'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class LogoutDialog extends Component {

    render() {
        return (

            <BootstrapDialog
                fullWidth
                onClose={() => this.props.onClose()}
                aria-labelledby="customized-dialog-title"
                open={this.props.isOpen}
                TransitionComponent={Transition}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => this.props.onClose()}>
                    {this.props.title}
                </BootstrapDialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" className="default-button" onClick={() => this.props.onClose()}>
                        {this.props.closeButtonTitle}
                    </Button>
                    <Button color="primary" className="default-button" onClick={() => this.props.onConfirm()}>
                        {this.props.confirmButtonTitle}
                    </Button>
                </DialogActions>
            </BootstrapDialog>

        );
    }
}

export default LogoutDialog;
