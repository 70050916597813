import axios from '../axiosConfig';

export function getInfirmaries(config = {}) {
    return axios.get(`/v1/infirmary`, config);
}

export function getInfirmaryList(config = {}) {
    return axios.get(`/v1/infirmary/list`, config);
}

export function createInfirmary(data) {
    return axios.post(`/v1/infirmary`, data);
}

export function getInfirmaryById(id, config = {}) {
    return axios.get(`/v1/infirmary/${id}`, config);
}

export function updateInfirmary(id, data) {
    return axios.patch(`/v1/infirmary/${id}`, data);
}

export function logoutDoctor(id) {
    return axios.patch(`/v1/infirmary/${id}/logout`);
}

export function deleteInfirmary(id) {
    return axios.delete(`/v1/infirmary/${id}`);
}

export function occupyInfirmary(id, data) {
    return axios.post(`/v1/infirmary/${id}/occupy`, data);
}

const actions = {
    getInfirmaries,
    getInfirmaryList,
    createInfirmary,
    deleteInfirmary,
    getInfirmaryById,
    updateInfirmary,
    occupyInfirmary,
    logoutDoctor
}

export default actions;
