import types from './types';
import api from '../../api/calendarApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';

export const getCalendarEvents = (config = {}) => async (dispatch) => {
    await api.getCalendarEvents(config).then(response => {
        dispatch({
            type: types.SET_CALENDAR_EVENTS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_CALENDAR_EVENTS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_calendar_events_error")));
    });
    return Promise.resolve();
}

export const getCalendarEntryStatuses = () => async (dispatch) => {
    await api.getCalendarEntryStatuses().then(response => {
        dispatch({
            type: types.SET_CALENDAR_ENTRY_STATUSES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_CALENDAR_ENTRY_STATUSES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_calendar_entry_statuses_error")));
    });
    return Promise.resolve();
}

export const getCalendarEntryById = (id, config = {}) => async (dispatch) => {
    return await api.getCalendarEntryById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_calendar_entry_by_id_error")));
    });
}

export const createCalendarEvent = (data) => async (dispatch) => {
    return await api.createCalendarEvent(data).then((response) => {
        dispatch(snackActions.success(i18next.t("calendar_entry_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const updateCalendarEvent = (id, data) => async (dispatch) => {
    return await api.updateCalendarEvent(id, data).then((response) => {
        dispatch(snackActions.success(i18next.t("calendar_entry_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const updateCalendarEventStatus = (id, config = {}) => async (dispatch) => {
    await api.updateCalendarEventStatus(id, config).then(() => {
        dispatch(snackActions.success(i18next.t("calendar_entry_status_updated_successfully")));
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("update_calendar_entry_status_error")));
    });
    return Promise.resolve();
}

export const deleteCalendarEntry = (id) => async (dispatch) => {
    return await api.deleteCalendarEntry(id).then((response) => {
        dispatch(snackActions.success(i18next.t("calendar_entry_deleted_successfully")));
        return response
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const getTodayEntries = (config = {}) => async (dispatch) => {
    await api.getTodayEntries(config).then(response => {
        dispatch({
            type: types.SET_TODAY_ENTRIES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_TODAY_ENTRIES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_today_entries_error")));
    });
    return Promise.resolve();
}

export const changePatientInfirmary = (calendarEntryId, config = {}) => async (dispatch) => {
    return await api.changePatientInfirmary(calendarEntryId, config).then((response) => {
        dispatch(snackActions.success(i18next.t("success_change_patient_to_another_infirmary")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const getPatientCalendarEntries = (id, config = {}) => async (dispatch) => {
    await api.getPatientCalendarEntries(id, { params: store.getState().calendarReducer.patientHistoryDataParams }).then(response => {
        dispatch({
            type: types.SET_PATIENT_CALENDAR_ENTRIES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_PATIENT_CALENDAR_ENTRIES,
            payload: []
        });
        /*  dispatch(snackActions.error(i18next.t("get_today_entries_error"))); */
    });
    return Promise.resolve();
}

export const setPatientCalendarEntriesPage = (id, value) => (dispatch) => {
    dispatch({
        type: types.SET_PATIENT_CALENDAR_ENTRIES_PAGE,
        payload: Number(value)
    });
    dispatch(getPatientCalendarEntries(id));
    return Promise.resolve();
};

export const setPatientCalendarEntriesSize = (id, value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_PATIENT_CALENDAR_ENTRIES_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getPatientCalendarEntries(id));
    }
    return Promise.resolve();
};

export const setPatientCalendarEntriesParams = (id, params) => (dispatch) => {
    dispatch({
        type: types.SET_PATIENT_CALENDAR_ENTRIES_PARAMS,
        payload: Object.assign(store.getState().calendarReducer.patientHistoryDataParams, params)
    });
    dispatch(getPatientCalendarEntries(id));
    return Promise.resolve();
};

export const getCalendarEntriesRecords = (config = {}) => async (dispatch) => {
    await api.getCalendarEntriesRecords(config).then(response => {
        dispatch({
            type: types.SET_CALENDAR_RECORDS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_CALENDAR_RECORDS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_calendar_records_error")));
    });
    return Promise.resolve();
}

export const syncCalendarEntry = (params) => async (dispatch) => {
    await api.syncCalendarEntry({ params: params }).then(response => {
        dispatch(snackActions.success(i18next.t("success_sync_data")));
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("error_sync_data")));
    });
    return Promise.resolve();
};

export const getPriceEstimateStatistics = (config = {}) => async (dispatch) => {
    await api.getPriceEstimateStatistics(config).then((response) => {
        dispatch({
            type: types.SET_PRICE_ESTIMATE_STATISTICS,
            payload: response.data
        })
    }).catch(() => {
        dispatch({
            type: types.SET_PRICE_ESTIMATE_STATISTICS,
            payload: null
        })
    });
    return Promise.resolve();
}