import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    Tab,
    Tabs
} from '@mui/material';
import { getServicesByDoctorId } from '../../redux/service/actions'
import DashboardStatisticsDoctor from './DashboardStatisticsDoctor';
import DashboardStatisticsInvoice from './DashboardStatisticsInvoice';
import DashboardStatisticsForm from './DashboardStatisticsForm';
import { CustomTabPanel, a11yProps } from '../../components/common/tabs';
import DashboardStatisticsProfit from './DashboardStatisticsProfit';
import DashboardStatisticsProfitPerServices from './DashboardStatisticsProfitPerServices';

class DashboardStatistic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }

    handleChangeTab = (event, value) => {
        this.setState({ selectedTab: value });
    }

    render() {
        const { selectedTab } = this.state

        return (
            <Grid item container>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box>
                        <Tabs value={selectedTab} onChange={this.handleChangeTab} TabIndicatorProps={{
                            style: { display: 'none' }
                        }}>
                            <Tab sx={{ borderRadius: '16px' }} label={this.props.t("doctors")} {...a11yProps(0)} wrapped />
                            <Tab sx={{ borderRadius: '16px' }} label={this.props.t("offers")}  {...a11yProps(1)} wrapped />
                            <Tab sx={{ borderRadius: '16px' }} label={this.props.t("services")}  {...a11yProps(2)} wrapped />
                            <Tab sx={{ borderRadius: '16px' }} label={this.props.t("patient_satisfaction_questionnaire")}  {...a11yProps(3)} wrapped />
                            <Tab sx={{ borderRadius: '16px' }} label={this.props.t("profit")}  {...a11yProps(4)} wrapped />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={selectedTab} index={0}>
                        <DashboardStatisticsDoctor />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={1}>
                        <DashboardStatisticsInvoice />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={2}>
                        <DashboardStatisticsProfitPerServices />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={3}>
                        <DashboardStatisticsForm />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={4}>
                        <DashboardStatisticsProfit />
                    </CustomTabPanel>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { getServicesByDoctorId })(withTranslation()(DashboardStatistic))