import types from './types';
import api from '../../api/auditLogApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';

export const getAuditLogs = () => async (dispatch) => {
    await api.getAuditLogs({ params: store.getState().auditReducer.auditLogsParams }).then(response => {
        dispatch({
            type: types.SET_AUDIT_LOGS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_AUDIT_LOGS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_audit_logs_error")));
    });
    return Promise.resolve();
}

export const setAuditLogsPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_ADUIT_LOGS_PAGE,
        payload: Number(value)
    });
    dispatch(getAuditLogs());
    return Promise.resolve();
};

export const setAuditLogsSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_ADUIT_LOGS_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getAuditLogs());
    }
    return Promise.resolve();
};

export const setAuditLogsParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_ADUIT_LOGS_PARAMS,
        payload: Object.assign(store.getState().auditReducer.auditLogsParams, params)
    });
    dispatch(setAuditLogsPage(0));
    return Promise.resolve();
};

export const getAuditLogsActions = () => async (dispatch) => {
    await api.getAuditLogsActions().then(response => {
        dispatch({
            type: types.SET_AUDIT_LOGS_ACTIONS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_AUDIT_LOGS_ACTIONS,
            payload: []
        });
        dispatch(snackActions.error(error.response.data && error.response.data.message));
    });
    return Promise.resolve();
}

export const deleteAuditLogs = (config = {}) => async (dispatch) => {
    await api.deleteAuditLogs(config).then((response) => {
        dispatch(snackActions.success(i18next.t("audit_logs_deleted_successfully")));
    }).catch((error) => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
    return Promise.resolve();
}

