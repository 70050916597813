import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Delete, Check, } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { createSchedule, updateSchedule, getScheduleEventById, deleteScheduleEvent } from '../../redux/schedule/actions'
import WrappedDateTimePicker from '../../components/common/WrappedDateTimePicker';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
class CreateOrUpdateScheduleEvent extends Component {

    emptyCalendarEntry = {
        startTime: null,
        endTime: null,
        userId: null,
        infirmaryId: null
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            startTime: null,
            endTime: null,
            calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
            initialCalendarEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
            selectedStartTime: null,
            selectedEndTime: null,
            selectedDoctor: null,
            selectedInfirmary: null
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.calendarEventToEdit && prevProps.calendarEventToEdit !== this.props.calendarEventToEdit) {
            this.props.getScheduleEventById(this.props.calendarEventToEdit.id.toString()).then(response => {
                this.setState({
                    calendaryEntry: JSON.parse(JSON.stringify(response.data)),
                    initialCalendarEntry: JSON.parse(JSON.stringify(response.data)),
                    selectedDoctor: this.props.userReducer.doctors.find(d => d.id === response.data.user.id),
                    selectedInfirmary: this.props.infirmaryReducer.infirmaryList.find(i => i.id === response.data.infirmary.id),
                    selectedStartTime: moment(response.data.startTime),
                    selectedEndTime: moment(response.data.endTime)
                })
            })
        }
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({ selectedDoctor: null, selectedInfirmary: null, selectedStartTime: null, selectedEndTime: null, calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)) })
    }

    handleChangeDoctor = (e, doctor) => {
        let { calendaryEntry } = this.state
        if (doctor !== null) {
            calendaryEntry.userId = doctor.id
            this.setState({ selectedDoctor: doctor, calendaryEntry: calendaryEntry })
        } else {
            calendaryEntry.userId = null
            this.setState({ selectedDoctor: null, calendaryEntry: calendaryEntry })
        }
    }

    handleChangeInfirmary = (e, infirmary) => {
        let { calendaryEntry } = this.state
        if (infirmary !== null) {
            calendaryEntry.infirmaryId = infirmary.id
            this.setState({ selectedInfirmary: infirmary, calendaryEntry: calendaryEntry })
        } else {
            calendaryEntry.infirmaryId = null
            this.setState({ selectedInfirmary: null, calendaryEntry: calendaryEntry })
        }
    }

    handleChangeStartTime = (date) => {
        let { calendaryEntry } = this.state
        if (date !== null && date._isValid) {
            if (date === null && this.state.selectedStartTime !== null) {
                return
            }
            if (moment(date).isBefore(new Date())) {
                calendaryEntry.startTime = new Date()
                this.setState({ calendaryEntry: calendaryEntry, selectedStartTime: new Date() })
            } else {
                calendaryEntry.startTime = moment(date)
                this.setState({ calendaryEntry: calendaryEntry, selectedStartTime: date });
            }
        }
    }

    handleChangeEndTime = (date) => {
        let { calendaryEntry } = this.state
        if (date !== null && date._isValid) {
            if (date === null && this.state.selectedEndTime !== null) {
                return
            }
            if (moment(date).isBefore(new Date())) {
                calendaryEntry.endTime = new Date()
                this.setState({ calendaryEntry: calendaryEntry, selectedEndTime: new Date() })
            } else {
                calendaryEntry.endTime = moment(date)
                this.setState({ calendaryEntry: calendaryEntry, selectedEndTime: date });
            }
        }
    }

    createOrUpdateScheduleEvent = (e) => {
        e.preventDefault()
        let { calendaryEntry, selectedDoctor, selectedInfirmary } = this.state
        let { selectedSlot, calendarEventToEdit } = this.props
        let body = {
            userId: selectedDoctor.id,
            infirmaryId: selectedInfirmary.id,
            startTime: calendarEventToEdit ? moment(calendaryEntry.startTime) : selectedSlot.start,
            endTime: calendarEventToEdit ? moment(calendaryEntry.endTime) : selectedSlot.end
        }
        const action = this.props.calendarEventToEdit ?
            this.props.updateSchedule(this.props.calendarEventToEdit.id, body) :
            this.props.createSchedule(body);
        action.then((response) => {
            if (response.status !== 400) {
                this.props.onSave()
                this.setState({ calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)), selectedDoctor: null, selectedInfirmary: null });
            }
        })
    }

    deleteScheduleEvent = () => {
        this.props.deleteScheduleEvent(this.props.calendarEventToEdit.id).then((response) => {
            if (response.status !== 400) {
                this.setState({ calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)), selectedDoctor: null, selectedInfirmary: null });
                this.props.onSave();
            }
        })
    }

    validation = () => {
        let { calendaryEntry, initialCalendarEntry, selectedDoctor, selectedInfirmary } = this.state;
        const { calendarEventToEdit } = this.props
        let valid = true;
        if (JSON.stringify(calendaryEntry) === JSON.stringify(initialCalendarEntry)) {
            valid = false
        }
        if (selectedDoctor === null) {
            valid = false
        }
        if (selectedInfirmary === null) {
            valid = false
        }
        if (calendarEventToEdit) {
            if (!calendaryEntry.startTime || !calendaryEntry.endTime || calendaryEntry.startTime === "Invalid date" || calendaryEntry.endTime === "Invalid date") {
                valid = false
            }
        }
        return valid;
    }
    render() {
        const { selectedDoctor, selectedInfirmary, calendaryEntry, selectedStartTime, selectedEndTime } = this.state
        const { calendarEventToEdit, selectedSlot } = this.props
        const { doctors } = this.props.userReducer
        const { infirmaryList } = this.props.infirmaryReducer

        return (
            <>
                <BootstrapDialog
                    onClose={this.closeDialog}
                    open={this.props.isOpen}
                    fullWidth
                    maxWidth={"md"}
                    TransitionComponent={Transition}>
                    <BootstrapDialogTitle onClose={this.closeDialog}>
                        <span>
                            {calendarEventToEdit ? this.props.t("edit_event") : this.props.t("new_event")}
                        </span>
                    </BootstrapDialogTitle>
                    <form onSubmit={this.createOrUpdateScheduleEvent} autoComplete="off" spellCheck="false">
                        <DialogContent>
                            <Grid container direction="row" spacing={2}>
                                {selectedSlot && <>
                                    <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item container>
                                                <WrappedDateTimePicker
                                                    label="start_time"
                                                    name="startTime"
                                                    disabled={true}
                                                    value={selectedSlot !== null ? Date.parse(selectedSlot.start) : null}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item container>
                                                <WrappedDateTimePicker
                                                    label="end_time"
                                                    name="endTime"
                                                    disabled={true}
                                                    value={selectedSlot !== null ? Date.parse(selectedSlot.end) : null}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>}
                                {calendarEventToEdit && <><Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item container>
                                            <WrappedDateTimePicker
                                                label="start_time"
                                                name="startTime"
                                                disablePast
                                                minutesStep={15}
                                                onChange={this.handleChangeStartTime}
                                                value={selectedStartTime ? Date.parse(selectedStartTime) : null}
                                                error={calendaryEntry.startTime === "Invalid date" || calendaryEntry.startTime === null}
                                                helperText={(calendaryEntry.startTime === "Invalid date" || calendaryEntry.startTime === null) && this.props.t("required_field_message")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item container>
                                                <WrappedDateTimePicker
                                                    label="end_time"
                                                    name="endTime"
                                                    disablePast
                                                    minutesStep={15}
                                                    onChange={this.handleChangeEndTime}
                                                    value={selectedEndTime ? Date.parse(selectedEndTime) : null}
                                                    error={calendaryEntry.startTime === "Invalid date" || calendaryEntry.startTime === null}
                                                    helperText={(calendaryEntry.startTime === "Invalid date" || calendaryEntry.startTime === null) && this.props.t("required_field_message")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>}
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl>
                                        <WrappedAutocomplete
                                            label={this.props.t("doctor")}
                                            placeholder={this.props.t("select_doctor")}
                                            name="selectedDoctor"
                                            value={selectedDoctor}
                                            getOptionLabel={(option) => option.firstName + " " + option.lastName + " (" + option.phone + ")"}
                                            options={doctors}
                                            onChange={(event, doctor) => {
                                                this.handleChangeDoctor(event, doctor)
                                            }}
                                            error={!selectedDoctor}
                                            helperText={!selectedDoctor && this.props.t("required_field_message")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <WrappedAutocomplete
                                            label={this.props.t("infirmary")}
                                            placeholder={this.props.t("select_infirmary")}
                                            name="selectedInfirmary"
                                            value={selectedInfirmary}
                                            getOptionLabel={(option) => option.name}
                                            options={infirmaryList}
                                            onChange={(event, infirmary) => {
                                                this.handleChangeInfirmary(event, infirmary)
                                            }}
                                            required
                                            error={!selectedInfirmary}
                                            helperText={!selectedInfirmary && this.props.t("required_field_message")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {calendarEventToEdit && <Button className="default-button" style={{ marginRight: 'auto' }} color="primary" onClick={() => this.deleteScheduleEvent()}>
                                <Delete /> {this.props.t("delete")}
                            </Button>}
                            <Button color="secondary" className="default-button" onClick={this.closeDialog}>
                                <Close />{this.props.t("close")}
                            </Button>
                            <Button type="submit" className="default-button" color="primary" disabled={!this.validation()}>
                                <Check /> {calendarEventToEdit ? this.props.t("update") : this.props.t("create")}
                            </Button>
                        </DialogActions>
                    </form>
                </BootstrapDialog>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer,
    infirmaryReducer: state.infirmaryReducer
})

const mapActionsToProps = { createSchedule, updateSchedule, getScheduleEventById, deleteScheduleEvent }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateOrUpdateScheduleEvent)))
