import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    FormControl,
    Grid,
    Typography,
    Button,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Table
} from '@mui/material';
import moment from 'moment';
import { getServicesByDoctorId } from '../../redux/service/actions'
import { getExaminationStatistics, exportStatisticsToExcel, exportStatisticsToPdf } from '../../redux/examination/actions'
import {
    Chart as ChartJS,
    PieController,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { RiFileExcel2Fill } from 'react-icons/ri'
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NumberFormat from 'react-number-format';
import PerfectScrollbar from 'react-perfect-scrollbar'
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { PictureAsPdf } from '@mui/icons-material';
import './Statistics.css'

ChartJS.register(
    CategoryScale,
    PieController,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
);

class DashboardStatisticsDoctor extends Component {

    emptyData = {
        userId: null,
        serviceIds: null,
        serviceGroupIds: null,
        startDate: null,
        endDate: null
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedDoctor: null,
            selectedServices: [],
            selectedServiceGroups: [],
            selectedStartDate: null,
            selectedEndDate: null,
            data: JSON.parse(JSON.stringify(this.emptyData))
        };
    }

    componentWillUnmount() {
        this.props.examinationReducer.examinationStats = null
    }

    handleChangeStartDate = (date) => {
        let { data } = this.state
        data.startDate = moment(date)
        this.props.examinationReducer.examinationStats = null
        this.setState({ data: data, selectedStartDate: date });
    }

    handleChangeEndDate = (date) => {
        let { data } = this.state
        data.endDate = moment(date).add(24, 'hours')
        this.props.examinationReducer.examinationStats = null
        this.setState({ data: data, selectedEndDate: date });
    }

    handleChangeDoctor = (e, doctor) => {
        let { selectedDoctor, data } = this.state;
        if (doctor !== null) {
            data.userId = doctor.id
            selectedDoctor = doctor
            this.props.examinationReducer.examinationStats = null
        } else {
            data.userId = null
            selectedDoctor = null
            this.props.examinationReducer.examinationStats = null
        }
        this.setState({ selectedDoctor: selectedDoctor, data: data, selectedServiceGroups: [], selectedServices: [] })
    }

    handleChangeServiceGroups = (e, serviceGroup) => {
        let { data, selectedServiceGroups, selectedServices } = this.state
        if (serviceGroup.length === 0) {
            data.serviceGroupIds = null
            this.props.examinationReducer.examinationStats = null
            this.setState({ data: data, selectedServiceGroups: [], selectedServices: [] });
        } else {
            selectedServices = []
            data.serviceIds = null
            selectedServiceGroups = serviceGroup;
            data.serviceGroupIds = []
            selectedServiceGroups.forEach(d => {
                data.serviceGroupIds.push(d.id)
            })
            this.props.examinationReducer.examinationStats = null
            this.setState({ selectedServiceGroups: selectedServiceGroups, data: data, selectedServices: selectedServices });
        }
    }

    handleChangeServices = (e, service) => {
        let { data } = this.state
        let selectedServices = { ...this.state.selectedServices }
        if (service.length === 0) {
            data.serviceIds = null
            this.props.examinationReducer.examinationStats = null
            this.setState({ data: data, selectedServices: [] });
        } else {
            selectedServices = service;
            data.serviceIds = []
            selectedServices.forEach(d => {
                data.serviceIds.push(d.id)
            })
            this.props.examinationReducer.examinationStats = null
            this.setState({ selectedServices: selectedServices, data: data });
        }
    }

    getStatistics = () => {
        this.props.getExaminationStatistics(this.state.data)
    }

    randomColor = () => {
        const red = Math.floor((1 + Math.random()) * 256 / 2);
        const green = Math.floor((1 + Math.random()) * 256 / 2);
        const blue = Math.floor((1 + Math.random()) * 256 / 2);
        return "rgb(" + red + ", " + green + ", " + blue + ",0.4)";
    }

    render() {
        const { selectedDoctor, selectedServices, selectedServiceGroups, selectedStartDate, selectedEndDate } = this.state
        const { doctors } = this.props.userReducer
        const { serviceList } = this.props.serviceReducer
        const { examinationStats } = this.props.examinationReducer
        const { serviceGroupsList } = this.props.serviceGroupReducer

        let pieChartData = []
        examinationStats && examinationStats.groupItemList.map(d => {
            return d.services.map(d1 => {
                return pieChartData.push(d1)
            })
        })

        const optionsPieChart = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.label || '';
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed !== null) {
                                label += new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(context.parsed);
                            }
                            return label;
                        }
                    }
                },
                legend: {
                    display: true,
                    position: 'right',
                    labels: {
                        boxHeight: 8,
                        boxWidth: 20,
                        font: {
                            size: 10
                        }
                    }
                },
                datalabels: {
                    color: "grey",
                    font: {
                        weight: 500,
                        family: 'Roboto'
                    },
                    formatter(value, categories) {
                        let sum = 0;
                        let dataArr = categories.chart.data.datasets[0].data;
                        dataArr.map(data => {
                            return sum += Number(data)
                        });
                        let percentage = (value * 100 / sum).toFixed(2) + "%";
                        return percentage;
                    },
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
            }
        }

        return (
            <>

                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6">{this.props.t("statistics_for_doctors")}</Typography>
                        <Grid container direction="row" spacing={2} >
                            <Grid item container direction="column" xs={12} sm={12} md={6} lg={4} xl={4}>
                                <FormControl>
                                    <WrappedAutocomplete
                                        label={this.props.t("select_doctor")}
                                        placeholder={this.props.t("select_doctor")}
                                        name="selectedDoctor"
                                        value={selectedDoctor}
                                        getOptionLabel={(option) => option.firstName + " " + option.lastName + " (" + option.phone + ")"}
                                        options={doctors}
                                        onChange={(event, value) => {
                                            this.handleChangeDoctor(event, value)
                                        }}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            {selectedDoctor && <Grid item container direction="column" xs={12} sm={12} md={6} lg={4} xl={4}>
                                <FormControl>
                                    <WrappedAutocomplete
                                        label={this.props.t("select_service_group")}
                                        placeholder={this.props.t("select_service_group")}
                                        name="selectedServiceGroups"
                                        multiple={true}
                                        value={selectedServiceGroups}
                                        getOptionLabel={(option) => option ? option.name : ""}
                                        options={serviceGroupsList.filter(sgl => sgl.doctors.some(d => d.id === selectedDoctor.id))}
                                        onChange={(event, value) => {
                                            this.handleChangeServiceGroups(event, value)
                                        }}
                                    />
                                </FormControl>
                            </Grid>}
                            {selectedServiceGroups.length > 0 && <Grid item container direction="column" xs={12} sm={12} md={6} lg={4} xl={4}>
                                <FormControl>
                                    <WrappedAutocomplete
                                        label={this.props.t("select_service")}
                                        placeholder={this.props.t("select_service")}
                                        name="selectedServices"
                                        multiple={true}
                                        value={selectedServices}
                                        getOptionLabel={(option) => option ? option.code + " - " + option.name : ""}
                                        options={serviceList.filter(sl => selectedServiceGroups.some(ssg => ssg.name === sl.serviceGroup))}
                                        onChange={(event, value) => {
                                            this.handleChangeServices(event, value)
                                        }}
                                    />
                                </FormControl>
                            </Grid>}
                        </Grid>
                        <Grid container direction="row" spacing={2} >
                            <Grid item container direction="column" xs={6} sm={6} md={3} lg={2} xl={2}>
                                <Grid item container>
                                    <FormControl>
                                        <WrappedDatePicker
                                            label="start_date"
                                            name="startDate"
                                            maxDate={moment(selectedEndDate).add(-1, 'day')}
                                            onChange={this.handleChangeStartDate}
                                            value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" xs={6} sm={6} md={4} lg={2} xl={2}>
                                <Grid item container>
                                    <FormControl>
                                        <WrappedDatePicker
                                            label="end_date"
                                            name="endDate"
                                            minDate={moment(selectedStartDate).add(1, 'day')}
                                            maxDate={moment(new Date()).add(1, 'year')}
                                            onChange={this.handleChangeEndDate}
                                            value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" xs={4} sm={4} md={4} lg={1} xl={1}>
                                <Grid item container>
                                    <FormControl>
                                        <Button
                                            style={{ marginTop: '8px', height: '40px', width: '70px' }}
                                            title={this.props.t("get_statistics")}
                                            color="primary"
                                            onClick={() => this.getStatistics()}
                                            disabled={!selectedEndDate || !selectedDoctor}>
                                            <QueryStatsIcon />
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        {examinationStats && <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={6} mt={2}>
                                <PerfectScrollbar style={{ maxHeight: '65vh' }}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("group")}</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("service")}</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align="center">{this.props.t("date")}</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align="right">{this.props.t("total")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {examinationStats.groupItemList.map((data, index) => {
                                                    return <React.Fragment key={index}>
                                                        <TableRow>
                                                            <TableCell className="table-cell" style={{ verticalAlign: 'top' }}>
                                                                {data.name}
                                                            </TableCell>
                                                            <TableCell style={{ padding: '0px' }}>
                                                                {data.services.map((item, index) => {
                                                                    return <Fragment key={index}>
                                                                        <p style={{ height: item.valueItems.length > 1 ? 20 * item.valueItems.length : 20 }}>
                                                                            {item.name}
                                                                        </p>
                                                                        <p className="border-top-total">
                                                                            &nbsp;
                                                                        </p>
                                                                    </Fragment>
                                                                })}
                                                            </TableCell>
                                                            <TableCell align='center' style={{ padding: '0px' }}>
                                                                {data.services.map(item => {
                                                                    return item.valueItems.map((item1, index1) => {
                                                                        return <Fragment key={index1}>
                                                                            <p style={{ height: '20px' }}>
                                                                                {moment.unix(item1.date).format("MM.YYYY.")}
                                                                            </p>
                                                                            {index1 + 1 === item.valueItems.length && <p className="border-top-total" style={{ textAlign: 'left', backgroundColor: '#E7E8E7' }}>
                                                                                {this.props.t("Total")}
                                                                            </p>}
                                                                        </Fragment>
                                                                    })
                                                                })}
                                                            </TableCell>
                                                            <TableCell align='right' style={{ padding: '0px' }}>
                                                                {data.services.map(item => {
                                                                    return item.valueItems.map((item1, index1) => {
                                                                        return <Fragment key={index1}>
                                                                            <p style={{ height: '20px', paddingRight: '10px' }}>
                                                                                <NumberFormat value={item1.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                                            </p>
                                                                            {index1 + 1 === item.valueItems.length && <p className="border-top-total" style={{ paddingRight: '10px', backgroundColor: '#E7E8E7' }}>
                                                                                <NumberFormat value={item.total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                                            </p>}
                                                                        </Fragment>
                                                                    })
                                                                })}
                                                            </TableCell>
                                                        </TableRow>
                                                        {selectedServiceGroups.length !== 1 && <TableRow>
                                                            <TableCell colSpan={2} />
                                                            <TableCell align="left" style={{ fontWeight: 'bold', padding: '0px', backgroundColor: 'lightgrey' }}>{this.props.t("Total") + " - " + data.name}</TableCell>
                                                            <TableCell className="total-cell" style={{ backgroundColor: 'lightgrey' }} align="right"><NumberFormat value={data.total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                        </TableRow>}
                                                    </React.Fragment>
                                                })}
                                                <TableRow style={{ borderTop: '1px solid black', backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("Total")}</TableCell>
                                                    <TableCell colSpan={2} />
                                                    <TableCell className="total-cell" align="right"><NumberFormat value={examinationStats.total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Button color="primary" className="default-button" style={{ marginTop: 10 }} onClick={() => this.props.exportStatisticsToExcel(this.state.data)} >
                                        <RiFileExcel2Fill style={{ fontSize: '18px' }} /> {this.props.t("excel")}
                                    </Button>
                                    <Button color="error" className="default-button" style={{ marginTop: 10, marginLeft: 10 }} onClick={() => this.props.exportStatisticsToPdf(this.state.data)} >
                                        <PictureAsPdf style={{ fontSize: '18px' }} /> {this.props.t("pdf")}
                                    </Button>
                                </PerfectScrollbar>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={6}>
                                {selectedServiceGroups.length !== 1 && examinationStats.groupItemList.length > 1 && <div style={{ height: '450px', width: '450px', marginTop: '-60px' }}>
                                    <Pie
                                        data={{
                                            labels: examinationStats.groupItemList.map(data => { return data.name }),
                                            datasets: [{
                                                data: examinationStats.groupItemList.map(data => { return data.total }),
                                                backgroundColor: examinationStats.groupItemList.map(data => { return this.randomColor() }),
                                                hoverOffset: 4
                                            }]
                                        }}
                                        options={optionsPieChart}
                                        plugins={[ChartDataLabels]}
                                    />
                                </div>}
                                {selectedServices.length !== 1 && <div
                                    style={{
                                        height: '700px', width: '700px',
                                        marginTop: selectedServiceGroups.length !== 1 && selectedServices.length !== 1 ? '-155px' : selectedServiceGroups.length !== 1 ? '-250px' : null
                                    }}>
                                    <Pie
                                        data={{
                                            labels: pieChartData.map(d => { return d.name }),
                                            datasets: [{
                                                data: pieChartData.map(data => { return data.total }),
                                                backgroundColor: pieChartData.map(data => { return this.randomColor() }),
                                                hoverOffset: 4
                                            }]
                                        }}
                                        options={optionsPieChart}
                                        plugins={[ChartDataLabels]}
                                    />
                                </div>}
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer,
    serviceReducer: state.serviceReducer,
    examinationReducer: state.examinationReducer,
    serviceGroupReducer: state.serviceGroupReducer
})

export default connect(mapStateToProps, { getServicesByDoctorId, getExaminationStatistics, exportStatisticsToExcel, exportStatisticsToPdf })(withTranslation()(DashboardStatisticsDoctor))
