import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    FormControl,
    InputLabel,
    OutlinedInput,
    CircularProgress,
    InputAdornment,
    Alert
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Close, InsertDriveFile } from '@mui/icons-material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ImportServicesModal extends Component {

    render() {
        return (

            <BootstrapDialog
                fullWidth
                onClose={() => this.props.onClose()}
                aria-labelledby="customized-dialog-title"
                open={this.props.isOpen}
                TransitionComponent={Transition}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => this.props.onClose()}>
                    {this.props.t("import_services")}
                </BootstrapDialogTitle>
                <DialogContent>

                    <Grid item>
                        <Alert style={{ marginBottom: '5px' }} severity="warning">{this.props.t("import_services_info")}</Alert>
                    </Grid>
                    <Grid item container direction="column" xs={6} sm={6} md={5} lg={4} xl={10}>
                        <input
                            style={{ display: 'none' }}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            id="servicesFile"
                            name="importServices"
                            type="file"
                            onChange={(e) => { this.props.handleChangeFile(e); this.setState(() => e.target.value = null) }}
                        />
                        <FormControl fullWidth>
                            <InputLabel>{this.props.fileName ? this.props.t("selected_file") : this.props.t("select_file")}</InputLabel>
                            <OutlinedInput
                                labelid="fileName"
                                label={this.props.fileName ? this.props.t("selected_file") : this.props.t("select_file")}
                                id="fileName"
                                name="fileName"
                                disabled={true}
                                value={this.props.fileName || ''}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <label htmlFor="servicesFile">
                                            <IconButton
                                                component="span"
                                                edge="end"
                                            >
                                                <InsertDriveFile />
                                            </IconButton>
                                        </label>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <br />
                        {this.props.fileName && <Grid item container direction="column" xs={6} sm={6} md={5} lg={4} xl={3}>
                            <Button style={{ width: '131px', height: '40px' }} color="primary" className="default-button" disabled={this.props.serviceReducer.isLoadingImport} onClick={() => this.props.importFile()}>
                                {this.props.serviceReducer.isLoadingImport ? <CircularProgress
                                    size={20}
                                    color="secondary"
                                /> : this.props.t("import")}
                            </Button>
                        </Grid>}
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    serviceReducer: state.serviceReducer
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(ImportServicesModal))
