import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    IconButton,
    Box,
    InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Check } from '@mui/icons-material'
import PropTypes from 'prop-types';
import {
    createUser,
    updateUser,
    getUserById,
    getRoles
} from '../../redux/user/actions';
import { withTranslation } from 'react-i18next';
import { EMAIL_REGEX, FIRST_NAME_REGEX, LAST_NAME_REGEX } from '../../components/common/regex';
import NumberFormat from 'react-number-format';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class CreateOrUpdateUser extends Component {

    emptyUser = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        role: '',
        primaryTitle: '',
        secondaryTitle: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            initialUser: JSON.parse(JSON.stringify(this.emptyUser)),
            user: JSON.parse(JSON.stringify(this.emptyUser))
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.userToEdit && prevProps.userToEdit !== this.props.userToEdit) {
            this.props.getUserById(this.props.userToEdit.toString()).then(response => {
                let userObject = {
                    ...response.data,
                    phone: response.data.phone.substring(4)
                }
                this.setState({
                    user: JSON.parse(JSON.stringify(userObject)),
                    initialUser: JSON.parse(JSON.stringify(userObject)),
                });
            })
        }
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            user: JSON.parse(JSON.stringify(this.emptyUser))
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let { user } = this.state;
        user[target.name] = target.value.trimStart();
        this.setState({ user: user })
    };

    handleChangePhone = (values) => {
        const { value } = values;
        let { user } = this.state;
        user.phone = value
        this.setState({ user: user })
    }

    createOrUpdateUser = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { user } = this.state;
        let dataUser = {
            ...user,
            phone: "+385" + user.phone,
            firstName: user.firstName && user.firstName.trim(),
            lastName: user.lastName && user.lastName.trim(),
            primaryTitle: user.role === "DOCTOR" ? user.primaryTitle : null,
            secondaryTitle: user.role === "DOCTOR" ? user.secondaryTitle : null
        }

        const action = this.props.userToEdit ?
            this.props.updateUser(this.props.userToEdit, dataUser) :
            this.props.createUser(dataUser);
        action.then(response => {
            if (response.status !== 400) {
                this.setState({ user: JSON.parse(JSON.stringify(this.emptyUser)) });
                this.props.onSave();
            }
        })
    };

    validateForm() {
        let { user, initialUser } = this.state;
        let valid = true;
        if (!FIRST_NAME_REGEX.test(user.firstName)) {
            valid = false;
        }
        if (!LAST_NAME_REGEX.test(user.lastName)) {
            valid = false;
        }
        if (user.phone.length <= 7) {
            valid = false;
        }
        if (user.email && !EMAIL_REGEX.test(user.email)) {
            valid = false;
        }
        if (JSON.stringify(user) === JSON.stringify(initialUser)) {
            valid = false
        }
        if (!user.firstName || !user.lastName || !user.phone || !user.email || !user.role) {
            valid = false;
        }
        return valid;
    }

    getNameHelperTextFirstName() {
        let { user } = this.state;
        if (!user.firstName.trim()) {
            return this.props.t("required_field_message");
        }
        if (user.firstName.length > 0 && !FIRST_NAME_REGEX.test(user.firstName)) {
            return this.props.t("name_regex_message", { min: 1, max: 50 });
        }
    }

    getNameHelperTextLastName() {
        let { user } = this.state;
        if (!user.lastName.trim()) {
            return this.props.t("required_field_message");
        }
        if (user.lastName.length > 0 && !LAST_NAME_REGEX.test(user.lastName)) {
            return this.props.t("name_regex_message", { min: 1, max: 50 });
        }
    }

    getEmailHelperText() {
        let { user } = this.state;
        if (!user.email.trim()) {
            return this.props.t("required_field_message");
        }
        if (user.email.length > 0 && !EMAIL_REGEX.test(user.email)) {
            return this.props.t("email_regex_message");
        }
    }

    render() {
        let { user } = this.state
        let { roles } = this.props.userReducer

        return (
            <BootstrapDialog
                onClose={this.closeDialog}
                open={this.props.isOpen}
                TransitionComponent={Transition}>
                <BootstrapDialogTitle onClose={this.closeDialog}>
                    {this.props.userToEdit ? this.props.t("edit_user") : this.props.t("new_user")}
                </BootstrapDialogTitle>
                <form onSubmit={this.createOrUpdateUser} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("first_name")}
                                            name="firstName"
                                            value={(user.firstName && user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1))}
                                            onChange={this.handleChange}
                                            required
                                            autoFocus
                                            error={!FIRST_NAME_REGEX.test(user.firstName) || !user.firstName.trim()}
                                            helperText={this.getNameHelperTextFirstName()} />
                                    </Grid>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("last_name")}
                                            name="lastName"
                                            value={(user.lastName && user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1))}
                                            onChange={this.handleChange}
                                            required
                                            error={!LAST_NAME_REGEX.test(user.lastName) || !user.lastName.trim()}
                                            helperText={this.getNameHelperTextLastName()} />
                                    </Grid>
                                    <Grid item container>
                                        <Grid item container>
                                            <NumberFormat
                                                required
                                                id="phone"
                                                name="phone"
                                                label={this.props.t("phone")}
                                                decimalScale={0}
                                                onValueChange={this.handleChangePhone}
                                                value={user.phone || ''}
                                                customInput={TextField}
                                                format="## ### ### #"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">
                                                        <Box sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/hr.png`}
                                                                srcSet={`https://flagcdn.com/w40/hr.png 2x`}
                                                                alt=""
                                                            />
                                                            +385
                                                        </Box>
                                                    </InputAdornment>
                                                }}
                                                error={user.phone.length > 7 ? false : true}
                                                helperText={!user.phone && this.props.t("required_field_message")}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("email")}
                                            name="email"
                                            value={user.email}
                                            onChange={this.handleChange}
                                            error={(user.email.length > 0 && !EMAIL_REGEX.test(user.email)) || !user.email.trim()}
                                            helperText={this.getEmailHelperText()} />
                                    </Grid>
                                    <Grid item container>
                                        <FormControl required error={!user.role}>
                                            <InputLabel id="role">{this.props.t("role")}</InputLabel>
                                            <Select
                                                labelId="role"
                                                id="role"
                                                name="role"
                                                value={user.role}
                                                onChange={this.handleChange}
                                                label={this.props.t("role")}>
                                                {roles && roles.map(role => {
                                                    return <MenuItem key={role} value={role}>{this.props.t(role)}</MenuItem>
                                                })}
                                            </Select>
                                            <FormHelperText>{!user.role && this.props.t("required_field_message")}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {user.role === "DOCTOR" && <>
                                        <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Grid item container>
                                                <TextField
                                                    label={this.props.t("primary_title")}
                                                    name="primaryTitle"
                                                    value={user.primaryTitle || ''}
                                                    onChange={this.handleChange} />
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Grid item container>
                                                <TextField
                                                    label={this.props.t("secondary_title")}
                                                    name="secondaryTitle"
                                                    value={user.secondaryTitle || ''}
                                                    onChange={this.handleChange} />
                                            </Grid>
                                        </Grid></>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={this.closeDialog} className="default-button">
                            <Close /> {this.props.t("close")}
                        </Button>
                        <Button type="submit" color="primary" className="default-button" disabled={!this.validateForm()} >
                            <Check />{this.props.userToEdit ? this.props.t("update") : this.props.t("create")}
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer
})

const mapActionsToProps = { createUser, updateUser, getUserById, getRoles }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateOrUpdateUser)))
