import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import '../../components/sidebar/Sidebar.css';
import { hasAnyRole, hasRole } from '../../utils/Security';
import Calendar from '../../views/calendar/Calendar';
import Dashboard from '../../views/dashboard/Dashboard';
import Users from '../../views/user/Users';
import Patients from '../../views/patient/Patients';
import Services from '../../views/service/Services';
import ServiceGroups from '../../views/serviceGroup/ServiceGroups';
import Diagnoses from '../../views/diagnosis/Diagnoses';
import Infirmaries from '../../views/infirmary/Infirmaries';
import InfirmaryForDoctors from '../../views/infirmary/InfirmaryForDoctors';
import Examination from '../../views/examination/Examination';
import Invoices from '../../views/invoice/Invoices';
import Settings from '../../views/setting/Settings';
import Schedule from '../../views/schedule/Schedule';
import AuditLogs from '../../views/auditLog/AuditLogs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { establishSseConnection } from '../../redux/auth/actions'

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.props.establishSseConnection();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return (
            <PerfectScrollbar style={{ height: 'calc(100vh - 10px)', width: '100%' }}>
                <main className="content">
                    <Switch>
                        {hasRole("DOCTOR") && this.props.user.currentInfirmary === null && <Route exact path='/choose-infirmary' render={(props) => <InfirmaryForDoctors {...props} />} />}
                        {hasRole("DOCTOR") && this.props.user.currentInfirmary === null && <Redirect to="/choose-infirmary" />}

                        {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "DOCTOR"]) && <Route exact path='/examination/patients/:id' render={(props) => <Examination {...props} />} />}

                        {hasAnyRole(["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM", "RECEPTIONIST"]) && <Route exact path='/dashboard' render={(props) => <Dashboard {...props} />} />}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM", "RECEPTIONIST", "SIGNER"]) && <Route exact path='/patients' render={(props) => <Patients {...props} />} />}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <Route exact path='/infirmaries' render={(props) => <Infirmaries {...props} />} />}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM"]) && <Route exact path='/users' render={(props) => <Users {...props} />} />}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM", "RECEPTIONIST"]) && <Route exact path='/service-groups' render={(props) => <ServiceGroups {...props} />} />}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM", "RECEPTIONIST"]) && <Route exact path='/services' render={(props) => <Services {...props} />} />}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM", "RECEPTIONIST"]) && <Route exact path='/calendar' render={(props) => <Calendar {...props} />} />}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM"]) && <Route exact path='/diagnoses' render={(props) => <Diagnoses {...props} />} />}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "RECEPTIONIST", "SYSTEM"]) && <Route exact path='/offers' render={(props) => <Invoices {...props} />} />}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <Route exact path='/schedule' render={(props) => <Schedule {...props} />} />}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM"]) && <Route exact path='/settings' render={(props) => <Settings {...props} />} />}
                        {hasRole("SUPERADMIN") && <Route exact path='/change-logs' render={(props) => <AuditLogs {...props} />} />}

                        {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "DOCTOR", "RECEPTIONIST"]) && <Redirect to="/dashboard" />}
                        {hasRole("SIGNER") && <Redirect to="/patients" />}
                    </Switch>
                </main>
            </PerfectScrollbar>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user
});

const mapActionsToProps = { establishSseConnection }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(Content)));
