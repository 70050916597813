import React from "react";
import {
    Button,
    Container,
    Grid,
    Typography
} from "@mui/material";
import { unsubscribe } from '../../redux/auth/actions'
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import '../login/Login.css';

class Unsubscribe extends React.Component {

    unsubscribe = () => {
        this.props.unsubscribe(this.props.match.params.formId)
    }

    render() {
        return (
            <Container maxWidth={false} className="loading-position-login" >
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                    {
                        this.props.authReducer.unsubscribeMessage === '' ? <>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }} >
                                <Typography className="login-title" variant="h2" sx={{ fontSize: "26px", fontWeight: "500", display: "block", color: '#6F8684' }}> <b>{this.props.t("unsubscribe_title")}</b></Typography>
                                <Button style={{ fontSize: "26px", fontWeight: "500", borderRadius: '30px', width: '250px', marginLeft: 'auto', marginRight: 'auto' }} className="default-button" onClick={() => this.unsubscribe()}>
                                    {this.props.t("unsubscribe")}
                                </Button>
                            </Grid>
                        </> :
                            <Typography className="login-title" variant="h2" sx={{ fontSize: "26px", fontWeight: "500", display: "block", color: '#6F8684' }}> <b>{this.props.authReducer.unsubscribeMessage}</b></Typography>
                    }
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer
})

const mapActionsToProps = { unsubscribe }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Unsubscribe))
