import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Button,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { getEmailConfigurations, updateGoogleForm } from '../../../redux/setting/actions'

class EmailConfigurations extends React.Component {

    emptyGoogleForm = {
        emailSubject: '',
        emailBody: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            googleForm: JSON.parse(JSON.stringify(this.emptyGoogleForm)),
            initialGoogleForm: JSON.parse(JSON.stringify(this.emptyGoogleForm)),
            disabledFields: true
        };
    }


    componentDidMount() {
        this.props.getEmailConfigurations().then((response) => {
            if (response.status === 200) {
                let body = {
                    ...response.data,
                    emailSubject: response.data && response.data.emailSubject,
                    emailBody: response.data && response.data.emailBody.replaceAll("<br>", "\n")
                }
                this.setState({
                    googleForm: JSON.parse(JSON.stringify(body)),
                    initialGoogleForm: JSON.parse(JSON.stringify(body)),
                })
            }
        })
    }

    handleChange = (event) => {
        const target = event.target;
        let { googleForm } = this.state;
        googleForm[target.name] = target.value
        this.setState({ googleForm: googleForm })
    };


    disabledButton = () => {
        let { googleForm, initialGoogleForm } = this.state;
        if (JSON.stringify(googleForm) === JSON.stringify(initialGoogleForm)) {
            return true;
        }
        return false;
    }

    updateForm = () => {
        let requestBody = {
            googleFormType: 'QUESTIONNAIRE',
            emailSubject: this.state.googleForm.emailSubject,
            emailBody: this.state.googleForm.emailBody && this.state.googleForm.emailBody.replaceAll("\n", "<br>")
        }

        this.props.updateGoogleForm(requestBody).then((response) => {
            if (response.status === 200) {
                this.setState({
                    disabledFields: true,
                    initialGoogleForm: JSON.parse(JSON.stringify(this.state.googleForm)),
                })
            }
        })
    }

    render() {
        const { googleForm, disabledFields } = this.state
        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Grid item container>
                        <TextField
                            disabled={disabledFields}
                            label={this.props.t("subject")}
                            name="emailSubject"
                            value={googleForm && googleForm.emailSubject}
                            onChange={this.handleChange} />
                    </Grid>
                    <Grid item container>
                        <TextField
                            disabled={disabledFields}
                            label={this.props.t("body")}
                            name="emailBody"
                            value={googleForm && googleForm.emailBody}
                            multiline
                            rows={15}
                            onChange={this.handleChange}
                            inputProps={{
                                autoComplete: 'off',
                                spellCheck: false
                            }} />
                    </Grid>
                    {!disabledFields && <Typography variant="body2" gutterBottom>
                        <strong>{this.props.t("info_message_email")}</strong>
                    </Typography>}
                    {googleForm.emailBody && <Grid item container>
                        {disabledFields ? <Button
                            className="default-button"
                            title={this.props.t("edit")}
                            color="primary"
                            onClick={() => this.setState({ disabledFields: false })}>
                            <span>{this.props.t("edit")}</span>
                        </Button> :
                            <Button
                                className="default-button"
                                title={this.props.t("save")}
                                color="primary"
                                disabled={this.disabledButton()}
                                onClick={() => this.updateForm()}>
                                <span>{this.props.t("save")}</span>
                            </Button>}

                    </Grid>}
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Typography variant="body1" gutterBottom component={'div'}>
                        <pre>
                            <code>
                                {JSON.stringify(googleForm && googleForm.googleFormConfigRawDto, null, 2)}
                            </code>
                        </pre>
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

const mapActionsToProps = {
    getEmailConfigurations,
    updateGoogleForm
}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(EmailConfigurations))
