import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    Select,
    FormControl,
    MenuItem,
    CircularProgress,
    InputLabel,
    Button,
    Card,
    CardHeader,
    Fab,
    TextField,
    CardActions,
    Typography,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Slide,
    Dialog,
    IconButton,
    DialogTitle,
    DialogContent,
    Link,
    CardContent
} from '@mui/material'
import { Add, Close, Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ListIcon from '@mui/icons-material/List';
import { getTestGoogleForm, createFormConfiguration } from '../../../redux/setting/actions'
import { Prompt } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


class CreateConfiguration extends React.Component {

    emptyForm = {
        googleFormType: '',
        googleFormQuestions: []
    }

    emptyGoogleFormQuestions = {
        question: '',
        questionType: '',
        choiceQuestionType: '',
        questionAnswers: '',
        position: null,
        questionRequired: false
    }

    emptyConfiguration = {
        emailSubject: '',
        emailBody: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            questionnareForm: JSON.parse(JSON.stringify(this.emptyForm)),
            googleFormQuestion: JSON.parse(JSON.stringify(this.emptyGoogleFormQuestions)),
            configuration: JSON.parse(JSON.stringify(this.emptyConfiguration)),
            openModal: false
        }
    }

    addNewQuestion = () => {
        let { questionnareForm } = this.state;
        questionnareForm.googleFormQuestions.push(JSON.parse(JSON.stringify(this.emptyGoogleFormQuestions)));
        this.setState({ questionnareForm: questionnareForm });
    }

    removeQuestion = (index) => {
        let { questionnareForm } = this.state;
        questionnareForm.googleFormQuestions.splice(index, 1);
        for (let i = 0; i < questionnareForm.googleFormQuestions.length; i++) {
            questionnareForm.googleFormQuestions[i].position = i
        }
        this.setState({ questionnareForm: questionnareForm });
    }

    handleChangeConfiguration = (event) => {
        const target = event.target;
        let { configuration } = this.state;
        configuration[target.name] = target.value.trimStart()
        this.setState({ configuration: configuration })
    }

    handleChange = (e, name, index) => {
        let { questionnareForm } = this.state;
        if (e.target.type === 'checkbox') {
            questionnareForm.googleFormQuestions[index][name] = e.target.checked
        }
        else if (e.target.name === "questionAnswers") {
            if (e.target.value === "") {
                questionnareForm.googleFormQuestions[index].questionAnswers = ''
            } else {
                questionnareForm.googleFormQuestions[index].questionAnswers = e.target.value.split(/\s*,\s*/)
            }
        }
        else {
            if (questionnareForm.googleFormQuestions[index].questionType === "TEXT_QUESTION") {
                questionnareForm.googleFormQuestions[index].choiceQuestionType = null
            } else {
                questionnareForm.googleFormQuestions[index].questionAnswers = null
                questionnareForm.googleFormQuestions[index].choiceQuestionType = null;

            }
            questionnareForm.googleFormQuestions[index][name] = e.target.value.trimStart();
        }
        questionnareForm.googleFormQuestions[index].position = index
        this.setState({ questionnareForm: questionnareForm })
    }

    disabledButton = () => {
        let { questionnareForm, configuration } = this.state
        if (!configuration.emailSubject || !configuration.emailBody || questionnareForm.googleFormQuestions.length === 0) {
            return true
        }
        for (let i = 0; i < questionnareForm.googleFormQuestions.length; i++) {
            if (questionnareForm.googleFormQuestions[i].question === '' || questionnareForm.googleFormQuestions[i].questionType === '') {
                return true;
            }
            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" && questionnareForm.googleFormQuestions[i].choiceQuestionType === null) {
                return true
            }
            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" &&
                questionnareForm.googleFormQuestions[i].choiceQuestionType === '' &&
                questionnareForm.googleFormQuestions[i].questionAnswers === null &&
                questionnareForm.googleFormQuestions[i].questionAnswers === "" &&
                questionnareForm.googleFormQuestions[i].questionAnswers === null) {
                return true
            }

            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" &&
                (questionnareForm.googleFormQuestions[i].choiceQuestionType === 'RADIO' ||
                    questionnareForm.googleFormQuestions[i].choiceQuestionType === 'CHECKBOX') &&
                (questionnareForm.googleFormQuestions[i].questionAnswers === null ||
                    questionnareForm.googleFormQuestions[i].questionAnswers === "")) {
                return true
            }
        }
        return false;
    }

    disabledButtonForTest = () => {
        let { questionnareForm } = this.state
        for (let i = 0; i < questionnareForm.googleFormQuestions.length; i++) {
            if (questionnareForm.googleFormQuestions[i].question === '' || questionnareForm.googleFormQuestions[i].questionType === '') {
                return true;
            }
            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" && questionnareForm.googleFormQuestions[i].choiceQuestionType === null) {
                return true
            }
            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" &&
                questionnareForm.googleFormQuestions[i].choiceQuestionType === '' &&
                questionnareForm.googleFormQuestions[i].questionAnswers === null &&
                questionnareForm.googleFormQuestions[i].questionAnswers === "" &&
                questionnareForm.googleFormQuestions[i].questionAnswers === null) {
                return true
            }

            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" &&
                (questionnareForm.googleFormQuestions[i].choiceQuestionType === 'RADIO' ||
                    questionnareForm.googleFormQuestions[i].choiceQuestionType === 'CHECKBOX') &&
                (questionnareForm.googleFormQuestions[i].questionAnswers === null ||
                    questionnareForm.googleFormQuestions[i].questionAnswers === "")) {
                return true
            }
        }
        return false;
    }

    createConfiguration = () => {
        let { questionnareForm, configuration } = this.state
        let requestData = {
            ...questionnareForm,
            googleFormType: 'QUESTIONNAIRE',
            emailSubject: configuration.emailSubject,
            emailBody: configuration.emailBody.replaceAll("\n", "<br>"),
        }
        this.props.createFormConfiguration(requestData).then(response => {
            if (response.status === 200) {
                this.setState({
                    questionnareForm: JSON.parse(JSON.stringify(this.emptyForm)),
                    configuration: JSON.parse(JSON.stringify(this.emptyConfiguration))
                })
            }
        })
    }

    checkForm = () => {
        let { questionnareForm } = this.state
        questionnareForm.googleFormType = 'TEST'
        this.props.getTestGoogleForm(this.state.questionnareForm).then((response) => {
            if (response.status === 200) {
                this.openModal()
            }
        })
    }

    openModal = () => {
        this.setState({ openModal: true })
    }

    closeModal = () => {
        this.setState({ openModal: false })
    }

    render() {
        const { mainLoading, questionnareForm, configuration } = this.state;
        const { googleFromUrl, googleFormLoading } = this.props.settingReducer

        let modal = <BootstrapDialog
            onClose={this.closeModal}
            open={this.state.openModal}
            TransitionComponent={Transition}
            fullWidth>
            <BootstrapDialogTitle onClose={this.closeModal}>
                {this.props.t("check_form")}
            </BootstrapDialogTitle>
            <DialogContent>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6">
                            {this.props.t("google_form_created")}
                        </Typography>
                        <Typography variant="body2">
                            <Link href={googleFromUrl && googleFromUrl} target="_blank">{googleFromUrl && googleFromUrl}</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </BootstrapDialog>

        let questions = questionnareForm.googleFormQuestions.map((qF, index) => {
            return <Card key={index} sx={{ marginTop: '10px' }}>
                <CardActions>
                    <Grid container direction="row" spacing={2} >
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }} >
                                <span style={{ width: '230px' }}>{this.props.t("question") + ": " + (index + 1)}  </span>
                                <FormControl>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            style={{ marginLeft: '20px' }}
                                            name="questionRequired"
                                            control={<Checkbox
                                                onChange={(e) => this.handleChange(e, "questionRequired", index)}
                                                checked={questionnareForm.googleFormQuestions[index].questionRequired} />
                                            }
                                            label={this.props.t("required")}
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <Button color="primary" className="default-button" style={{ marginLeft: 'auto' }} onClick={() => this.removeQuestion(index)} >
                                    <Delete />
                                </Button>
                            </Typography>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                label={this.props.t("question")}
                                name="question"
                                multiline
                                rows={3}
                                value={questionnareForm.googleFormQuestions[index].question}
                                onChange={(e) => this.handleChange(e, "question", index)}
                                inputProps={{
                                    autoComplete: 'off',
                                    spellCheck: false
                                }} />
                        </Grid>
                        <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                            <FormControl>
                                <InputLabel>{this.props.t("question_type")}</InputLabel>
                                <Select
                                    value={questionnareForm.googleFormQuestions[index].questionType || ''}
                                    label={this.props.t("question_type")}
                                    name="questionType"
                                    onChange={(e) => this.handleChange(e, "questionType", index)}>
                                    <MenuItem value={"CHOICE_QUESTION"}>{this.props.t("CHOICE_QUESTION")}</MenuItem>
                                    <MenuItem value={"TEXT_QUESTION"}>{this.props.t("TEXT_QUESTION")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {questionnareForm.googleFormQuestions[index].questionType !== "TEXT_QUESTION" && <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                            <FormControl>
                                <InputLabel>{this.props.t("choice_question_type")}</InputLabel>
                                <Select
                                    value={questionnareForm.googleFormQuestions[index].choiceQuestionType || ''}
                                    label={this.props.t("choice_question_type")}
                                    name="choiceQuestionType"
                                    onChange={(e) => this.handleChange(e, "choiceQuestionType", index)}>
                                    <MenuItem value={"RADIO"}>{this.props.t("RADIO")}</MenuItem>
                                    <MenuItem value={"CHECKBOX"}>{this.props.t("CHECKBOX")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>}
                        {questionnareForm.googleFormQuestions[index].questionType !== "TEXT_QUESTION" && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                label={this.props.t("question_answers")}
                                name="questionAnswers"
                                multiline
                                rows={3}
                                value={questionnareForm.googleFormQuestions[index].questionAnswers || ''}
                                onChange={(e) => this.handleChange(e, "questionAnswers", index)} />
                            <Typography variant="caption" gutterBottom>
                                <strong>{this.props.t("info_message_google_answer")}</strong>
                            </Typography>
                        </Grid>}
                    </Grid>
                </CardActions>
            </Card>
        })

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (<>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={8} sm={8} md={8} lg={6} xl={6}>
                    <Prompt when={this.disabledButtonForTest()} message={this.props.t("warning_message")} />
                    <Card>
                        <CardHeader subheader={<strong>{this.props.t("create_configuration")}</strong>}></CardHeader>
                        <CardContent>
                            <Grid item container>
                                <TextField
                                    label={this.props.t("subject")}
                                    name="emailSubject"
                                    value={configuration.emailSubject}
                                    onChange={this.handleChangeConfiguration} />
                            </Grid>
                            <Grid item container>
                                <TextField
                                    label={this.props.t("body")}
                                    name="emailBody"
                                    value={configuration.emailBody}
                                    multiline
                                    rows={15}
                                    onChange={this.handleChangeConfiguration}
                                    inputProps={{
                                        autoComplete: 'off',
                                        spellCheck: false
                                    }} />
                            </Grid>
                            <Typography variant="body2" gutterBottom>
                                <strong>{this.props.t("info_message_email")}</strong>
                            </Typography>
                            <Button
                                className="default-button"
                                title={this.props.t("create_configuration")}
                                color="primary"
                                disabled={this.disabledButton()}
                                onClick={() => this.createConfiguration()}>
                                <span>{this.props.t("create_configuration")}</span>
                            </Button>
                            {questionnareForm.googleFormQuestions.length > 0 && <Button
                                className="default-button"
                                color="primary"
                                style={{ marginLeft: '10px' }}
                                disabled={this.disabledButtonForTest()}
                                onClick={() => this.checkForm()}>
                                {googleFormLoading ? <CircularProgress
                                    size={20}
                                    color="secondary"
                                /> : <>
                                    <ListIcon className="table-fab-icon" style={{ marginRight: 5 }} />
                                    {this.props.t("check_form")}</>}
                            </Button>}
                            {modal}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={5} xl={5}>
                    <Card>
                        <CardHeader
                            subheader={this.props.t("add_new_question")}
                            action={<>
                                <Fab
                                    style={{ marginRight: '10px' }}
                                    title={this.props.t("files")}
                                    color="primary"
                                    onClick={() => this.addNewQuestion()}>
                                    <Add className="table-fab-icon" />
                                </Fab>
                            </>
                            }
                        >
                        </CardHeader>
                    </Card>
                    {questions}
                </Grid>
            </Grid>
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

const mapActionsToProps = {
    getTestGoogleForm,
    createFormConfiguration
}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(CreateConfiguration))
