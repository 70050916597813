export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS';
export const SET_TODAY_ENTRIES = 'SET_TODAY_ENTRIES'
export const SET_CALENDAR_ENTRY_STATUSES = 'SET_CALENDAR_ENTRY_STATUSES'
export const SET_PATIENT_CALENDAR_ENTRIES = 'SET_PATIENT_CALENDAR_ENTRIES'
export const SET_PATIENT_CALENDAR_ENTRIES_PARAMS = 'SET_PATIENT_CALENDAR_ENTRIES_PARAMS'
export const SET_PATIENT_CALENDAR_ENTRIES_SIZE = 'SET_PATIENT_CALENDAR_ENTRIES_SIZE'
export const SET_PATIENT_CALENDAR_ENTRIES_PAGE = 'SET_PATIENT_CALENDAR_ENTRIES_PAGE'
export const SET_CALENDAR_RECORDS = 'SET_CALENDAR_RECORDS'
export const SET_PRICE_ESTIMATE_STATISTICS = 'SET_PRICE_ESTIMATE_STATISTICS'

const types = {
    SET_CALENDAR_EVENTS,
    SET_TODAY_ENTRIES,
    SET_CALENDAR_ENTRY_STATUSES,
    SET_PATIENT_CALENDAR_ENTRIES,
    SET_PATIENT_CALENDAR_ENTRIES_PARAMS,
    SET_PATIENT_CALENDAR_ENTRIES_SIZE,
    SET_PATIENT_CALENDAR_ENTRIES_PAGE,
    SET_CALENDAR_RECORDS,
    SET_PRICE_ESTIMATE_STATISTICS
}

export default types;