import types from './types';

const initialState = {
    calendarEvents: [],
    calendarEntryStatuses: [],
    todayEvents: [],
    isLoading: true,
    patientHistoryDataLoading: true,
    patientHistoryData: [],
    patientHistoryDataTotalElements: 0,
    patientHistoryDataParams: {
        page: 0,
        size: 10
    },
    calendarRecords: [],
    calendarRecordsLoading: true,
    priceEstimateStatistics: null
}

export default function calendar(state = initialState, action) {
    switch (action.type) {
        case types.SET_CALENDAR_EVENTS:
            return {
                ...state,
                calendarEvents: action.payload,
                isLoading: false
            }
        case types.SET_TODAY_ENTRIES:
            return {
                ...state,
                todayEvents: action.payload
            }
        case types.SET_CALENDAR_ENTRY_STATUSES:
            return {
                ...state,
                calendarEntryStatuses: action.payload
            }
        case types.SET_PATIENT_CALENDAR_ENTRIES:
            return {
                ...state,
                patientHistoryData: action.payload.content,
                patientHistoryDataTotalElements: action.payload ? action.payload.totalElements : 0,
                patientHistoryDataLoading: false
            }
        case types.SET_PATIENT_CALENDAR_ENTRIES_PAGE:
            return {
                ...state, patientHistoryDataParams: { ...state.patientHistoryDataParams, page: action.payload }
            }
        case types.SET_PATIENT_CALENDAR_ENTRIES_SIZE:
            return {
                ...state, patientHistoryDataParams: { ...state.patientHistoryDataParams, size: action.payload, page: 0 }
            }
        case types.SET_PATIENT_CALENDAR_ENTRIES_PARAMS:
            return {
                ...state,
                patientHistoryDataParams: action.payload
            }
        case types.SET_CALENDAR_RECORDS: {
            return {
                ...state,
                calendarRecords: action.payload,
                calendarRecordsLoading: false
            }
        }
        case types.SET_PRICE_ESTIMATE_STATISTICS: {
            return {
                ...state,
                priceEstimateStatistics: action.payload
            }
        }
        default:
            return state;
    }
}