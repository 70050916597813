import types from './types';
import api from '../../api/patientApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';

export const getPatients = () => async (dispatch) => {
    dispatch({
        type: types.SET_PATIENTS_LOADING
    });
    await api.getPatients({ params: store.getState().patientReducer.patientsParams }).then(response => {
        if (store.getState().patientReducer.patientsParams.page > 0 && response.data.content.length === 0) {
            dispatch(setPatientsPage(0));
        }
        dispatch({
            type: types.SET_PATIENTS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_PATIENTS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_patients_error")));
    });
    return Promise.resolve();
}

export const setPatientsPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_PATIENTS_PAGE,
        payload: Number(value)
    });
    dispatch(getPatients());
    return Promise.resolve();
};

export const setPatientsSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_PATIENTS_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getPatients());
    }
    return Promise.resolve();
};

export const setPatientsParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_PATIENTS_PARAMS,
        payload: Object.assign(store.getState().patientReducer.patientsParams, params)
    });
    dispatch(getPatients());
    return Promise.resolve();
};

export const getGenders = () => async (dispatch) => {
    await api.getGenders().then(response => {
        dispatch({
            type: types.SET_GENDERS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_GENDERS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_genders_error")));
    });
    return Promise.resolve();
}

export const getPatientList = () => async (dispatch) => {
    await api.getPatientList().then(response => {
        dispatch({
            type: types.SET_PATIENT_LIST,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_PATIENT_LIST,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_patients_error")));
    });
    return Promise.resolve();
}

export const getSources = () => async (dispatch) => {
    await api.getSources().then(response => {
        dispatch({
            type: types.SET_SOURCES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_SOURCES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_sources_error")));
    });
    return Promise.resolve();
}

export const getCounties = () => async (dispatch) => {
    await api.getCounties().then(response => {
        dispatch({
            type: types.SET_COUNTIES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_COUNTIES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_counties_error")));
    });
    return Promise.resolve();
}


export const createPatient = (data) => async (dispatch) => {
    return await api.createPatient(data).then((response) => {
        dispatch(snackActions.success(i18next.t("patient_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const updatePatient = (id, data) => async (dispatch) => {
    return await api.updatePatient(id, data).then((response) => {
        dispatch(snackActions.success(i18next.t("patient_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const getPatientById = (id, config = {}) => async (dispatch) => {
    return await api.getPatientById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_patient_by_id_error")));
    });
}

export const deletePatient = (id) => async (dispatch) => {
    await api.deletePatient(id).then(() => {
        dispatch(snackActions.success(i18next.t("patient_deleted_successfully")));
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
    })
    return Promise.resolve();
}

export const sendSignerEvent = (config = {}) => (dispatch) => {
    api.sendSignerEvent(config).then(response => {
    }).catch(error => {
    });
    return Promise.resolve();
}

export const sendSignature = (id, data, config = {}) => async (dispatch) => {
    await api.sendSignature(id, data, config).then(response => {
        dispatch({
            type: types.SET_SIGNER_EVENT,
            payload: null
        })
    }).catch(error => {

    });
    return Promise.resolve();
}

export const downloadGDPRDocument = (patientId, config) => async (dispatch) => {
    return await api.downloadGDPRDocument(patientId, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        },
        responseType: 'blob',
    }).then(response => {
        handleContentDisposition(response)
        dispatch(snackActions.success(i18next.t("export_successfully")));
        return response;
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}

export const handleContentDisposition = (response) => {
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers["content-disposition"];
    let fileName = contentDisposition.split("filename=")[1];
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

export const getPatientFiles = (id) => async (dispatch) => {
    await api.getPatientFiles(id).then(response => {
        dispatch({
            type: types.SET_PATIENT_FILES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_PATIENT_FILES,
            payload: []
        });
    });
    return Promise.resolve();
}

export const filterPatientFiles = (id, config = {}) => async (dispatch) => {
    await api.filterPatientFiles(id, config).then(response => {
        dispatch({
            type: types.SET_PATIENT_FILES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_PATIENT_FILES,
            payload: []
        });
    });
    return Promise.resolve();
}

export const importFiles = (id, data) => async (dispatch) => {
    dispatch({
        type: types.SET_LOADING_IMPORT_FILES,
        payload: true
    });
    return await api.importFiles(id, data).then((response) => {
        dispatch({
            type: types.SET_LOADING_IMPORT_FILES,
            payload: false
        });
        dispatch(snackActions.success(i18next.t("import_successfully")));
        return response
    }).catch((error) => {
        dispatch({
            type: types.SET_LOADING_IMPORT_FILES,
            payload: false
        });
        dispatch(snackActions.error(
            error.response && error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const deleteFile = (id) => async (dispatch) => {
    return await api.deleteFile(id).then(response => {
        dispatch(snackActions.success(i18next.t("delete_file_successfully")));
        return response
    }).catch(error => {
        dispatch(snackActions.success(i18next.t("delete_file_error")));
    });
}

export const handleContentDispositionFile = (response, pathName) => {
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', pathName);
    document.body.appendChild(link);
    link.click();
}

export const downloadPatientFile = (id, pathName, onfig) => async (dispatch) => {
    return await api.downloadPatientFile(id, {
        headers: {
            'Content-Type': 'application/octet-stream',
            'Accept': 'application/octet-stream',
            'Access-Control-Expose-Headers': 'Content-Disposition'
        },
        responseType: 'blob',
    }).then(response => {
        handleContentDispositionFile(response, pathName)
        dispatch(snackActions.success(i18next.t("export_successfully")));
        return response;
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}


export const closeDocument = (config={}) => async (dispatch) => {
    await api.closeDocument(config).then(response => {
    }).catch(error => {
    });
    return Promise.resolve();
}

