export const SET_INFIRMARIES = 'SET_INFIRMARIES';
export const SET_INFIRMARY_LIST = 'SET_INFIRMARY_LIST'
export const SET_INFIRMARIES_PAGE = 'SET_INFIRMARIES_PAGE'
export const SET_INFIRMARIES_SIZE = 'SET_INFIRMARIES_SIZE'
export const SET_INFIRMARIES_PARAMS = 'SET_INFIRMARIES_PARAMS'
export const RESET_INFIRMARY_DATA = 'RESET_INFIRMARY_DATA'

const types = {
    SET_INFIRMARIES,
    SET_INFIRMARY_LIST,
    SET_INFIRMARIES_PAGE,
    SET_INFIRMARIES_SIZE,
    SET_INFIRMARIES_PARAMS,
    RESET_INFIRMARY_DATA
};

export default types;