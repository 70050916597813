export const SET_LOGGED_USER = 'SET_LOGGED_USER';
export const SET_LOADING_USER = 'SET_LOADING_USER'
export const SET_UNSUBSCRIBE_MESSAGE = 'SET_UNSUBSCRIBE_MESSAGE'

const types = {
    SET_LOGGED_USER,
    SET_LOADING_USER,
    SET_UNSUBSCRIBE_MESSAGE
};

export default types;