import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import store from '../../redux/store'
import types, { POPOVER_OPEN, SET_SIGNER_EVENT } from '../../redux/patient/types';
import {
    Grid,
    TableCell,
    TableRow,
    Button,
    Typography,
    Zoom,
    Tooltip,
    Fab,
    Table,
    Collapse,
    Box,
    TableContainer,
    Paper,
    TableHead,
    TableBody,
    IconButton,
    Popover,
    FormControl,
    Select,
    MenuItem,
    Divider,
    InputLabel
} from '@mui/material';
import { Add, DriveFileRenameOutline, FileDownload, ArrowDownward, ArrowUpward, Edit, Delete, Article, Check, Clear, Close } from '@mui/icons-material'
import { getPatients, deletePatient, getGenders, getSources, setPatientsPage, setPatientsSize, setPatientsParams, sendSignerEvent, downloadGDPRDocument, getCounties, closeDocument } from '../../redux/patient/actions';
import CreateOrUpdatePatient from './CreateOrUpdatePatient';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import Base58Table from "../../components/common/Base58Table";
import { hasAnyRole, hasRole } from '../../utils/Security';
import GDPRmodal from './GDPRmodal';
import './rotate.css'
import { Zoom as ZoomSlide } from 'react-slideshow-image';
import image1 from '../../assets/images/image1.jpg'
import image2 from '../../assets/images/image2.jpg'
import image3 from '../../assets/images/image3.jpg'
import image4 from '../../assets/images/image4.jpg'
import image5 from '../../assets/images/image5.jpg'
import image6 from '../../assets/images/image6.jpg'
import image7 from '../../assets/images/image7.jpg'
import image8 from '../../assets/images/image8.jpg'
import image9 from '../../assets/images/image9.jpg'
import image10 from '../../assets/images/image10.jpg'
import image11 from '../../assets/images/image11.jpg'
import image12 from '../../assets/images/image12.jpg'
import image13 from '../../assets/images/image13.jpg'
import image14 from '../../assets/images/image14.jpg'
import image15 from '../../assets/images/image15.jpg'
import image16 from '../../assets/images/image16.jpg'
import image17 from '../../assets/images/image17.jpg'
import logo from '../../assets/images/logo2.png'
import moment from 'moment';

class Patients extends React.Component {

    emptyPatient = {
        id: null,
        firstName: '',
        lastName: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            patientToDelete: this.emptyPatient,
            patientToEdit: null,
            openDeleteDialog: false,
            dialogOpen: false,
            collapseIndex: -1,
            anchorEl: null,
            selectedPatient: null,
            selectedGDPR: false
        }
    }

    componentDidMount() {
        if (hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"])) {
            this.props.getPatients()
            this.props.getGenders()
            this.props.getSources()
            this.props.getCounties()
        }
        if (hasRole("DOCTOR")) {
            this.props.getPatients()
        }
    }

    componentWillUnmount() {
        this.props.setPatientsSize(10, true)
        store.dispatch({
            type: types.RESET_PATIENT_DATA
        })
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false, patientToEdit: null });
    }

    openPatient = (data) => {
        store.dispatch({
            type: types.SET_CURRENT_PATIENT,
            payload: data
        })
    }

    sendSignerEvent = (patientId) => {
        let params = {}
        params.patientId = patientId
        params.promo = this.state.selectedGDPR
        store.dispatch({
            type: SET_SIGNER_EVENT,
            payload: patientId
        })
        this.props.sendSignerEvent({ params: params })
    }

    handleClick = (e, patient) => {
        store.dispatch({
            type: POPOVER_OPEN,
            payload: true
        })
        this.setState({ anchorEl: e.currentTarget, selectedPatient: patient })
    };

    handleClose = () => {
        store.dispatch({
            type: POPOVER_OPEN,
            payload: false
        })
        this.setState({ selectedGDPR: false })
    }

    handleChangeGDPR = (event) => {
        const target = event.target;
        let { selectedGDPR } = this.state;
        selectedGDPR = target.value;
        this.setState({ selectedGDPR: selectedGDPR })
    }

    closeDocument = (patientId) => {
        let params = {}
        params.patientId = patientId
        this.props.closeDocument({ params: params })
    }

    render() {
        const { t } = this.props;
        const { dialogOpen, collapseIndex, selectedPatient, selectedGDPR } = this.state;
        const { patientsLoading, patients, patientsParams, patientsTotalElements, popoverOpen } = this.props.patientReducer;

        let gdprModal = <>
            <Popover
                id={'simple-popover'}
                open={popoverOpen}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <Box sx={{ p: 2 }}>
                    {selectedPatient && selectedPatient.signedGDPR ? <>
                        <Typography variant="body2" gutterBottom>
                            <strong>{t("download_document")}</strong>:
                        </Typography>
                        <Fab className="table-fab" onClick={() => this.props.downloadGDPRDocument(selectedPatient && selectedPatient.id)} color="success"><FileDownload className="table-fab-icon" /> </Fab>
                    </> :
                        <>
                            <Typography variant="body2" gutterBottom>
                                <strong>{t("signing_document")}</strong>:
                            </Typography>
                            <FormControl>
                                <InputLabel>{t("GDPR")}</InputLabel>
                                <Select
                                    value={selectedGDPR}
                                    label={t("GDPR")}
                                    name="GDPR"
                                    onChange={this.handleChangeGDPR}>
                                    <MenuItem value={false}>{t("gdpr_without_promo")}</MenuItem>
                                    <MenuItem value={true}>{t("gdpr_with_promo")}</MenuItem>
                                </Select>
                            </FormControl>
                            <Button title={t("gdpr_modal_message")} className="table-fab" onClick={() => this.sendSignerEvent(selectedPatient && selectedPatient.id)} color="error"><DriveFileRenameOutline className="table-fab-icon" /> </Button>
                        </>}
                    <Divider sx={{ marginTop: '5px' }} />
                    <Typography variant="body2" gutterBottom>
                        <strong>{t("Zatvaranje dokumenta")}</strong>:
                    </Typography>
                    <Fab className="table-fab" disabled={this.props.patientReducer.patientId === null} onClick={() => this.closeDocument(selectedPatient && selectedPatient.id)} color="primary"><Close className="table-fab-icon" /> </Fab>
                </Box>
            </Popover>
        </>

        let patientsList = patients && patients.map((patient, index) => {
            return <React.Fragment key={index}>
                <TableRow className={index === this.state.collapseIndex ? 'table-custom-body-background' : null}>
                    <TableCell>
                        <IconButton size="small" onClick={() => this.setState({ collapseIndex: collapseIndex !== index ? index : -1 })}>
                            {index === collapseIndex ? <ArrowUpward /> : <ArrowDownward />}
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        {patient.firstName && patient.firstName.length > 20 ? <Tooltip
                            disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.firstName} style={{ cursor: 'pointer' }}>
                            <span>{patient.firstName && patient.firstName.length > 20 ? patient.firstName.substring(0, 20) + "..." : patient.firstName}</span>
                        </Tooltip> :
                            patient.firstName}
                    </TableCell>
                    <TableCell>{patient.lastName && patient.lastName.length > 20 ? <Tooltip
                        disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.lastName} style={{ cursor: 'pointer' }}>
                        <span>{patient.lastName && patient.lastName.length > 20 ? patient.lastName.substring(0, 20) + "..." : patient.lastName}</span>
                    </Tooltip> :
                        patient.lastName}</TableCell>
                    <TableCell>{patient.dateOfBirth}</TableCell>
                    <TableCell>{patient.phone}</TableCell>
                    <TableCell>{patient.email}</TableCell>
                    <TableCell>{t(patient.gender)}</TableCell>
                    <TableCell>{moment(patient.createdDate).format("DD.MM.YYYY. HH:mm")}</TableCell>
                    {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <TableCell align='center'>
                        <Button aria-describedby={'simple-popover'} color={patient.signedGDPR ? 'success' : 'error'} variant="contained" onClick={(e) => this.handleClick(e, patient)}>
                            {t("GDPR")}
                        </Button>
                        {gdprModal}
                    </TableCell>}
                    <TableCell>
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <Button title={t("edit")} className="default-button" style={{ marginRight: 5 }} color="secondary" onClick={() => this.setState({ patientToEdit: patient.id, dialogOpen: true })}>
                            <Edit className="table-fab-icon" />
                            {t("edit")}
                        </Button>}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM"]) && <Button title={t("delete")} className="default-button" style={{ marginRight: 5 }} color="primary" onClick={() => this.setState({ patientToDelete: patient, openDeleteDialog: true })}>
                            <Delete className="table-fab-icon" />
                            {t("delete")}
                        </Button>}
                        {hasAnyRole(["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM"]) && <Button title={t("examinations")} className="default-button" style={{ backgroundColor: '#ED6C02' }} onClick={() => this.props.history.push("/examination/patients/" + patient.id)}>
                            <Article className="table-fab-icon" />
                            {t("examinations")}
                        </Button>}
                    </TableCell>
                </TableRow>
                <TableRow className={index === this.state.collapseIndex ? 'table-custom-body-background' : null}>
                    <TableCell style={{ border: "none" }} colSpan={11}>
                        <Collapse in={index === collapseIndex} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" scope="row" width="19%">{t("address")}</TableCell>
                                                <TableCell component="th" scope="row" width="19%">{t("city")}</TableCell>
                                                <TableCell component="th" scope="row" width="18%">{t("county")}</TableCell>
                                                <TableCell component="th" scope="row" width="19%">{t("country")}</TableCell>
                                                <TableCell component="th" scope="row" width="8%">{t("source")}</TableCell>
                                                <TableCell component="th" scope="row" width="9%">{t("send_sms")}</TableCell>
                                                <TableCell component="th" scope="row" width="8%">{t("send_email")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{patient.address && patient.address.length > 40 ? <Tooltip
                                                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.address} style={{ cursor: 'pointer' }}>
                                                    <span>{patient.address && patient.address.length > 40 ? patient.address.substring(0, 40) + "..." : patient.address}</span>
                                                </Tooltip> :
                                                    patient.address}</TableCell>
                                                <TableCell>{patient.city && patient.city.length > 40 ? <Tooltip
                                                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.city} style={{ cursor: 'pointer' }}>
                                                    <span>{patient.city && patient.city.length > 40 ? patient.city.substring(0, 40) + "..." : patient.city}</span>
                                                </Tooltip> :
                                                    patient.city}</TableCell>
                                                <TableCell>{patient.county && patient.county.length > 40 ? <Tooltip
                                                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.county} style={{ cursor: 'pointer' }}>
                                                    <span>{patient.county && patient.county.length > 40 ? patient.county.substring(0, 40) + "..." : patient.county}</span>
                                                </Tooltip> :
                                                    patient.county}</TableCell>
                                                <TableCell>{patient.country && patient.country.length > 40 ? <Tooltip
                                                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.country} style={{ cursor: 'pointer' }}>
                                                    <span>{patient.country && patient.country.length > 40 ? patient.country.substring(0, 40) + "..." : patient.country}</span>
                                                </Tooltip> :
                                                    patient.country}</TableCell>
                                                <TableCell>{patient.source === "IN_PERSON" ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={<p><b>{t("recommended_by")}:</b> {patient.recommendedBy}</p>} style={{ cursor: 'pointer' }}>
                                                    <span>{t(patient.source)}</span>
                                                </Tooltip> :
                                                    t(patient.source)}</TableCell>
                                                <TableCell align='center'>{patient.sendSms ? <Check style={{ color: '#22AE73' }} /> : <Clear style={{ color: '#F44812' }} />}</TableCell>
                                                <TableCell align='center'>{patient.sendEmail ? <Check style={{ color: '#22AE73' }} /> : <Clear style={{ color: '#F44812' }} />}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        })

        let columns = [
            {
                width: '1%',
            },
            {
                name: "first_name",
                dbName: 'first_name',
                width: '12%',
                sort: true,
                filter: true
            },
            {
                name: "last_name",
                dbName: 'last_name',
                width: '12%',
                sort: true,
                filter: true
            },
            {
                name: "date_of_birth_short",
                dbName: 'date_of_birth',
                width: '8%',
                sort: true
            },
            {
                name: "phone",
                width: '11%',
                dbName: 'phone',
                filter: true
            },
            {
                name: "email",
                width: '14%',
                dbName: 'email',
                filter: true
            },
            {
                name: "gender",
                width: '7%'
            },
            {
                name: "created_date",
                dbName: "created_date",
                width: '10%',
                sort: true
            }
        ]

        const gdpr = { name: "GDPR", width: '3%', dbName: 'signedGDPR', filter: true, type: 'boolean-select' };
        const actions = { name: "", width: hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM"]) ? '22%' : '12%' }
        if (hasRole("DOCTOR")) {
            columns.push(actions)
        } else {
            columns.push(gdpr);
            columns.push(actions)
        }

        const images = [
            image1,
            image2,
            image3,
            image4,
            image5,
            image6,
            image7,
            image8,
            image9,
            image10,
            image11,
            image12,
            image13,
            image14,
            image15,
            image16,
            image17
        ];

        const zoomOutProperties = {
            duration: 5000,
            transitionDuration: 500,
            infinite: true,
            indicators: false,
            scale: 0.4,
            arrows: false
        };

        return (<>
            {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST", "DOCTOR"]) &&
                <Grid container direction="row" spacing={2} >
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }} mt={1}>
                            <span>{t("patients")}</span>
                            {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <Button color="primary" className="default-button" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ dialogOpen: true })} >
                                <Add />{t("add_new_patient")}
                            </Button>}
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                        <Base58Table
                            isLoading={patientsLoading}
                            columns={columns}
                            data={patientsList}
                            count={patientsTotalElements}
                            rowsPerPage={patientsParams.size}
                            page={patientsParams.page}
                            onPageChange={(e, page) => { this.props.setPatientsPage(page); this.setState({ collapseIndex: -1 }); }}
                            onRowsPerPageChange={(e) => { this.props.setPatientsSize(e.target.value); this.setState({ collapseIndex: -1 }); }}
                            onFilterChange={(params) => { this.props.setPatientsParams(params); this.setState({ collapseIndex: -1 }); }}
                        />
                    </Grid>
                    <CreateOrUpdatePatient
                        isOpen={dialogOpen}
                        onClose={this.closeDialog}
                        patientToEdit={this.state.patientToEdit}
                        onSave={() => this.setState({ dialogOpen: false, patientToEdit: null }, () => this.props.getPatients())}
                    />
                    <ConfirmationDialog
                        isOpen={this.state.openDeleteDialog}
                        title={t("delete_patient")}
                        message={<span dangerouslySetInnerHTML={{ __html: t("delete_patient_message", { firstName: this.state.patientToDelete.firstName, lastName: this.state.patientToDelete.lastName }) }} />}
                        onClose={() => this.setState({ openDeleteDialog: false })}
                        onConfirm={() => this.props.deletePatient(this.state.patientToDelete.id).then(() => {
                            this.setState({ openDeleteDialog: false }, () => this.props.getPatients(), this.props.setPatientsPage(0));
                        })}
                        closeButtonTitle={t("close")}
                        confirmButtonTitle={t("delete")}
                    />
                </Grid>
            }
            {hasRole("SIGNER") && <>
                <section id="slideshow">
                    <div className="entire-content">
                        <div className="content-carrousel">
                            <img src={logo} alt="" width={700} height={450}></img>
                        </div>
                    </div>
                </section>
                <div>
                    <ZoomSlide {...zoomOutProperties} >
                        {
                            images.map((data, i) => {
                                return <img key={i} style={{ width: "110%" }} src={data} alt="" />
                            })
                        }
                    </ZoomSlide>
                </div>
                <GDPRmodal />
            </>
            }
        </>

        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer
})

const mapActionsToProps = { getPatients, deletePatient, getGenders, getSources, setPatientsPage, setPatientsSize, setPatientsParams, sendSignerEvent, downloadGDPRDocument, getCounties, closeDocument }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Patients))
