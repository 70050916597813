export const SET_AUDIT_LOGS = 'SET_AUDIT_LOGS';
export const SET_ADUIT_LOGS_PAGE = 'SET_ADUIT_LOGS_PAGE';
export const SET_ADUIT_LOGS_SIZE = 'SET_ADUIT_LOGS_SIZE';
export const SET_ADUIT_LOGS_PARAMS = 'SET_ADUIT_LOGS_PARAMS';
export const SET_AUDIT_LOGS_ACTIONS = 'SET_AUDIT_LOGS_ACTIONS'

const types = {
    SET_AUDIT_LOGS,
    SET_ADUIT_LOGS_PAGE,
    SET_ADUIT_LOGS_SIZE,
    SET_ADUIT_LOGS_PARAMS,
    SET_AUDIT_LOGS_ACTIONS
};

export default types;