import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    Fab
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Close, AddPhotoAlternate, Clear } from '@mui/icons-material'
import { importDoctorSignature, getDoctorSignature } from '../../redux/user/actions'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class UploadSignatureModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: null,
            imagePreviewUrl: '',
            file: null
        };
    }


    componentDidUpdate(prevProps) {
        if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
            if (this.props.userId && prevProps.userId !== this.props.userId) {
                this.props.getDoctorSignature(this.props.userId.toString()).then(response => {
                    if (response) {
                        this.setState({
                            imagePreviewUrl: "data:image/png;base64," + response.data
                        });
                    }
                })
            }
        }
    }

    handleImageChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (!file) {
            return;
        }
        reader.onloadend = () => {

            let image = { ...this.state.image };
            image = reader.result;

            this.setState({
                imagePreviewUrl: reader.result,
                image: image,
                file: file
            });
        }
        reader.readAsDataURL(file)
    }

    handleChangeFile = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        reader.onloadend = () => {
            let image = { ...this.state.image };
            image = reader.result;

            this.setState({
                imagePreviewUrl: reader.result,
                image: image
            });
        }
        reader.readAsDataURL(file)

        this.setState({ file: file })
    }

    clearLogo = () => {
        this.setState({
            file: null,
            imagePreviewUrl: "",
            image: null,
            initialFile: null
        });
    }

    uploadImage = () => {
        const formData = new FormData();
        formData.append("file", this.state.file)
        formData.append("description", "doctor_signature")
        this.props.importDoctorSignature(this.props.userId, formData).then((response) => {
            if (response && response.status === 200) {
                this.clearLogo();
                this.props.onClose();
            }
        })
    }

    render() {

        let { imagePreviewUrl, file } = this.state


        return (

            <BootstrapDialog
                fullWidth
                onClose={() => this.props.onClose()}
                aria-labelledby="customized-dialog-title"
                open={this.props.isOpen}
                TransitionComponent={Transition}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { this.props.onClose(); this.clearLogo(); }}>
                    {this.props.t("signature")}
                </BootstrapDialogTitle>
                <DialogContent>
                    <Grid item container direction="column" xs={4} sm={4} md={4} lg={4} xl={4} style={{ paddingTop: imagePreviewUrl ? '0px' : '16px' }}>
                        {imagePreviewUrl && <Clear style={{ position: 'relative', marginLeft: 'auto', top: '20px', cursor: 'pointer' }} onClick={this.clearLogo} />}
                        <input
                            style={{ display: 'none' }}
                            accept="image/png, image/jpeg"
                            id="contained-button-file"
                            type="file"
                            onChange={this.handleImageChange}
                        />
                        <label htmlFor="contained-button-file">
                            {imagePreviewUrl ?
                                <img
                                    style={{ width: "350px", height: "auto", cursor: 'pointer' }}
                                    src={imagePreviewUrl} alt="logo" />
                                :
                                <Fab
                                    style={{ width: "110px", height: "50px" }}
                                    component="span"
                                    color="primary">
                                    <AddPhotoAlternate style={{ fontSize: 30 }} />
                                </Fab>
                            }
                        </label>

                    </Grid>
                    {file && <Grid item container direction="column" xs={6} sm={6} md={5} lg={4} xl={3}>
                        <Fab style={{ width: '131px', height: '40px' }} color="primary" className="default-button" disabled={!file} onClick={() => this.uploadImage()}>
                            {this.props.t("save")}
                        </Fab>
                    </Grid>}
                </DialogContent>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    diagnosisReducer: state.diagnosisReducer
})

const mapActionsToProps = { importDoctorSignature, getDoctorSignature }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(UploadSignatureModal))
