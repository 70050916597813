import React, { Component } from 'react';
import {
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

class SearchInput extends Component {

    onEnterSearch = (event) => {
        if (event.key === 'Enter') {
            this.props.onSearch(event);
        }
    }

    render() {
        return (
            <FormControl fullWidth variant="outlined">
                <OutlinedInput
                    type={this.props.type}
                    id={this.props.id}
                    name={this.props.name}
                    placeholder={this.props.actualValue ? String(this.props.actualValue) : this.props.placeholder}
                    value={this.props.value || ''}
                    onChange={(e) => this.props.onChange(e)}
                    onKeyDown={(e) => this.props.value && this.props.value.length > 0 && this.onEnterSearch(e)}
                    endAdornment={
                        <InputAdornment position="end">
                            {this.props.value && <IconButton
                                id={this.props.id}
                                name={this.props.name}
                                component="span"
                                edge="end"
                                onClick={(e) => this.props.onClear(e)}
                            >
                                <ClearIcon />
                            </IconButton>}
                            {this.props.value && this.props.value.length > 0 && <IconButton
                                component="span"
                                edge="end"
                                onClick={(e) => this.props.onSearch(e)}
                            >
                                <SearchIcon />
                            </IconButton>}
                        </InputAdornment>
                    }
                    inputProps={{
                        autoComplete: 'off',
                        spellCheck: false
                    }}
                />
            </FormControl>
        );
    }
}

export default SearchInput;
