import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Zoom,
    Tooltip
} from '@mui/material';
import 'moment/locale/hr';

class CustomEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false
        };
    }

    render() {
        let { event } = this.props

        return (
            <>
                <Grid item container direction="row">
                    <Grid item container style={{ display: 'flex', alignItems: 'center', zIndex: '0' }}>
                        <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top" title={<p style={{ fontSize: '14px' }}><b>{event.user.firstName + " " + event.user.lastName + " (" + event.user.phone + ") " + event.infirmary.name}</b></p>} style={{ cursor: 'pointer' }}>
                            <span>{event.user.firstName + " " + event.user.lastName + " (" + event.user.phone + ") " + (event.infirmary && event.infirmary.name.length > 55 ? event.infirmary.name.substring(0, 55) + "..." : event.infirmary.name)}</span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {})(withTranslation()(CustomEvent));
