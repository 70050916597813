import types from './types';
import api from '../../api/scheduleApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';

export const getScheduleEvents = (config = {}) => async (dispatch) => {
    await api.getScheduleEvents(config).then(response => {
        dispatch({
            type: types.SET_SCHEDULE_EVENTS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_SCHEDULE_EVENTS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_schedule_events_error")));
    });
    return Promise.resolve();
}

export const createSchedule = (data) => async (dispatch) => {
    return await api.createSchedule(data).then((response) => {
        dispatch(snackActions.success(i18next.t("schedule_entry_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const updateSchedule = (id, data) => async (dispatch) => {
    return await api.updateSchedule(id, data).then((response) => {
        dispatch(snackActions.success(i18next.t("schedule_entry_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const getScheduleEventById = (id, config = {}) => async (dispatch) => {
    return await api.getScheduleEventById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_schedule_event_by_id_error")));
    });
}

export const deleteScheduleEvent = (id) => async (dispatch) => {
    return await api.deleteScheduleEvent(id).then((response) => {
        dispatch(snackActions.success(i18next.t("schedule_entry_deleted_successfully")));
        return response
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}