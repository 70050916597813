import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    FormControl,
    Typography,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Check } from '@mui/icons-material'
import PropTypes from 'prop-types';
import {
    createInfirmary,
    updateInfirmary,
    getInfirmaryById
} from '../../redux/infrimary/actions';
import { withTranslation } from 'react-i18next';
import { ColorPicker } from 'mui-color';
import { NAME_SHORT_REGEX } from '../../components/common/regex';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class CreateOrUpdateInfirmary extends Component {

    emptyInfirmary = {
        name: '',
        currentOccupantId: null,
        comment: '',
        calendarHexColor: '#B2E68C'
    };

    constructor(props) {
        super(props);
        this.state = {
            initialInfirmary: JSON.parse(JSON.stringify(this.emptyInfirmary)),
            infirmary: JSON.parse(JSON.stringify(this.emptyInfirmary)),
            selectedCurrentOccupant: null
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.infirmaryToEdit && prevProps.infirmaryToEdit !== this.props.infirmaryToEdit) {
            this.props.getInfirmaryById(this.props.infirmaryToEdit.toString()).then(response => {
                this.setState({
                    infirmary: JSON.parse(JSON.stringify(response.data)),
                    initialInfirmary: JSON.parse(JSON.stringify(response.data)),
                    selectedCurrentOccupant: this.props.userReducer.doctors.find(d => d.id === response.data.currentOccupantId)
                });
            })
        }
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            infirmary: JSON.parse(JSON.stringify(this.emptyInfirmary)),
            selectedCurrentOccupant: null
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let { infirmary } = this.state;

        infirmary[target.name] = target.value;
        this.setState({ infirmary: infirmary })
    };

    handleColorChange = (value) => {
        let infirmary = { ...this.state.infirmary };
        infirmary['calendarHexColor'] = "#" + value.hex;
        this.setState({ infirmary: infirmary });
    };

    handleChangecurrentOccupant = (e, doctor) => {
        let { infirmary } = this.state;
        if (doctor !== null) {
            infirmary.currentOccupantId = doctor.id
            this.setState({ selectedCurrentOccupant: doctor, infirmary: infirmary })
        } else {
            infirmary.currentOccupantId = null
            this.setState({ selectedCurrentOccupant: null, infirmary: infirmary })
        }
    }

    createOrUpdateInfirmary = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }

        const { infirmary } = this.state;

        let infirmaryObject = {
            ...infirmary,
            comment: infirmary.comment && infirmary.comment.trim().toUpperCase()
        }
        const action = this.props.infirmaryToEdit ?
            this.props.updateInfirmary(this.props.infirmaryToEdit, infirmaryObject) :
            this.props.createInfirmary(infirmaryObject);
        action.then((response) => {
            if (response.status !== 400) {
                this.setState({ infirmary: JSON.parse(JSON.stringify(this.emptyInfirmary)), selectedCurrentOccupant: null });
                this.props.onSave();
            }
        })
    };

    validateForm() {
        let { infirmary, initialInfirmary } = this.state;
        let valid = true;
        if (!NAME_SHORT_REGEX.test(infirmary.name)) {
            valid = false;
        }
        if (JSON.stringify(infirmary) === JSON.stringify(initialInfirmary)) {
            valid = false
        }
        if (!infirmary.name
        ) {
            valid = false;
        }
        return valid;
    }

    disabledOption = (id) => {
        const { infirmaryList } = this.props.infirmaryReducer;
        for (let i = 0; i < infirmaryList.length; i++) {
            if (infirmaryList[i].currentOccupant && infirmaryList[i].currentOccupant.id === id) {
                return true;
            }
        }
        return false;
    }

    getNameHelperText() {
        let { infirmary } = this.state;
        if (!infirmary.name.trim()) {
            return this.props.t("required_field_message");
        }
        if (infirmary.name.length > 0 && !NAME_SHORT_REGEX.test(infirmary.name)) {
            return this.props.t("name_regex_message", { min: 1, max: 50 });
        }
    }

    render() {
        let { infirmary, selectedCurrentOccupant } = this.state
        const { doctors } = this.props.userReducer

        return (
            <BootstrapDialog
                onClose={this.closeDialog}
                open={this.props.isOpen}
                TransitionComponent={Transition}
                fullWidth>
                <BootstrapDialogTitle onClose={this.closeDialog}>
                    {this.props.infirmaryToEdit ? this.props.t("edit_infirmary") : this.props.t("new_infirmary")}
                </BootstrapDialogTitle>
                <form onSubmit={this.createOrUpdateInfirmary} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("name_infirmary")}
                                        name="name"
                                        value={(infirmary.name && infirmary.name.charAt(0).toUpperCase() + infirmary.name.slice(1)) || ''}
                                        onChange={this.handleChange}
                                        required
                                        autoFocus
                                        error={!NAME_SHORT_REGEX.test(infirmary.name) || !infirmary.name.trim()}
                                        helperText={this.getNameHelperText()} />
                                </Grid>
                                <Grid item container>
                                    <FormControl>
                                        <WrappedAutocomplete
                                            label={this.props.t("select_current_occupant")}
                                            placeholder={this.props.t("select_current_occupant")}
                                            name="selectedCurrentOccupant"
                                            value={selectedCurrentOccupant || null}
                                            getOptionLabel={(option) => option.firstName + " " + option.lastName + " (" + option.phone + ")"}
                                            options={doctors}
                                            getOptionDisabled={(option) => this.disabledOption(option.id)}
                                            onChange={(event, doctor) => {
                                                this.handleChangecurrentOccupant(event, doctor)
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item container>
                                    <TextField
                                        label={this.props.t("comment")}
                                        name="comment"
                                        value={infirmary.comment || ''}
                                        multiline
                                        inputProps={{
                                            style: { textTransform: "uppercase" }
                                        }}
                                        rows={3}
                                        onChange={this.handleChange} />
                                </Grid>
                                <Grid item container style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="subtitle1" style={{ color: '#666666', marginLeft: '2px' }}>{this.props.t("background_color")}</Typography>
                                    <ColorPicker
                                        disabled
                                        hideTextfield
                                        value={infirmary.calendarHexColor}
                                        onChange={this.handleColorChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" className="default-button" onClick={this.closeDialog} >
                            <Close /> {this.props.t("close")}
                        </Button>
                        <Button type="submit" color="primary" className="default-button" disabled={!this.validateForm()} >
                            <Check /> {this.props.infirmaryToEdit ? this.props.t("update") : this.props.t("create")}
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    infirmaryReducer: state.infirmaryReducer,
    userReducer: state.userReducer
})

const mapActionsToProps = { createInfirmary, updateInfirmary, getInfirmaryById }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateOrUpdateInfirmary)))
