import types from '../service/types';
import api from '../../api/serviceApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';

export const getServices = () => async (dispatch) => {
    dispatch({
        type: types.SET_SERVICES_LOADING
    });
    await api.getServices({ params: store.getState().serviceReducer.servicesParams }).then(response => {
        if (store.getState().serviceReducer.servicesParams.page > 0 && response.data.content.length === 0) {
            dispatch(setServicesPage(0));
        }
        dispatch({
            type: types.SET_SERVICES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_SERVICES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_service_error")));
    });
    return Promise.resolve();
}

export const setServicesPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SERVICES_PAGE,
        payload: Number(value)
    });
    dispatch(getServices());
    return Promise.resolve();
};

export const setServicesSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_SERVICES_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getServices());
    }
    return Promise.resolve();
};

export const setServicesParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_SERVICES_PARAMS,
        payload: Object.assign(store.getState().serviceReducer.servicesParams, params)
    });
    dispatch(getServices());
    return Promise.resolve();
};

export const getServiceList = () => async (dispatch) => {
    await api.getServiceList().then(response => {
        dispatch({
            type: types.SET_SERVICE_LIST,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_SERVICE_LIST,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_service_list_error")));
    });
    return Promise.resolve();
}

export const createService = (data) => async (dispatch) => {
    return await api.createService(data).then((response) => {
        dispatch(snackActions.success(i18next.t("service_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response.data
    })
}

export const updateService = (id, data) => async (dispatch) => {
    return await api.updateService(id, data).then(response => {
        dispatch(snackActions.success(i18next.t("service_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response.data
    })
}

export const getServiceById = (id, config = {}) => async (dispatch) => {
    return await api.getServiceById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_service_by_id_error")));
    });
}

export const deleteService = (id) => async (dispatch) => {
    await api.deleteService(id).then(() => {
        dispatch(snackActions.success(i18next.t("service_deleted_successfully")));
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
    })
    return Promise.resolve();
}

export const importServices = (data) => async (dispatch) => {
    dispatch({
        type: types.SET_LOADING_IMPORT_SERVICES,
        payload: true
    });
    return await api.importServices(data).then((response) => {
        dispatch({
            type: types.SET_LOADING_IMPORT_SERVICES,
            payload: false
        });
        dispatch(snackActions.success(i18next.t("import_successfully")));
        return response
    }).catch((error) => {
        dispatch({
            type: types.SET_LOADING_IMPORT_SERVICES,
            payload: false
        });
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const getServicesByDoctorId = (id, config = {}) => async (dispatch) => {
    return await api.getServicesByDoctorId(id, config).then(response => {
        dispatch({
            type: types.SET_SERVICES_BY_DOCTOR_ID,
            payload: response.data
        });
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_services_by_doctor_id_error")));
    });
}

export const changeServiceStatus = (id, status, config = {}) => async (dispatch) => {
    let params = {};
    params.active = !status
    await api.changeServiceStatus(id, { params: params }).then((response) => {
        dispatch(getServices());
        dispatch(snackActions.success(i18next.t("service_deleted_successfully")));
    }).catch((error) => {
    });
    return Promise.resolve();
}



