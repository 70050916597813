import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    CircularProgress,
    Button,
    TableRow,
    Typography,
    Fab
} from '@mui/material'
import { getGoogleCredentials, setGoogleCredentialsPage, setGoogleCredentialsSize, setGoogleCredentialsParams } from '../../redux/setting/actions'
import Base58Table from '../../components/common/Base58Table';
import { Add, Check, Clear, FindInPage } from '@mui/icons-material';
import CreateCredentialModal from './CreateCredentialModal';
import ParamsDetailsDialog from '../auditLog/ParamsDetailsDialog';
import moment from 'moment';

class Configuration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: true,
            dialogOpen: false,
            isOpen: false,
            selectedParams: null
        }
    }

    componentDidMount() {
        Promise.all([
            this.props.getGoogleCredentials()])
            .then(() => {
                this.setState({ mainLoading: false });
            })
            .catch(error => {
                this.setState({ mainLoading: false });
            })
    }

    getParamDetails = (jsonData) => {
        let selectedParams = jsonData
        if (this.isJsonString(selectedParams.rawResponse)) {
            let rawResponse = JSON.parse(selectedParams.rawResponse);
            selectedParams.rawResponse = rawResponse;
        }
        this.setState({ selectedParams: selectedParams });
    }

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    render() {
        const { mainLoading, dialogOpen } = this.state;
        const { googleCredentials, googleCredentialsTotalElement, googleCredentialsParams, googleCredentialsLoading } = this.props.settingReducer;

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }
        let credentials = googleCredentials && googleCredentials.map((credential, index) => {
            let jsonObject = {
                type: credential.type,
                project_id: credential.project_id,
                private_key_id: credential.project_id,
                private_key: credential.project_id,
                client_email: credential.project_id,
                client_id: credential.client_id,
                auth_uri: credential.auth_uri,
                token_uri: credential.token_uri,
                auth_provider_x509_cert_url: credential.auth_provider_x509_cert_url,
                client_x509_cert_url: credential.client_x509_cert_url,
                universe_domain: credential.universe_domain,
                gmail_username: credential.gmail_username,
                gmail_password: credential.gmail_password
            }
            return <TableRow key={index}>
                <TableCell align='center'>{credential.is_active ? <Check style={{ color: '#22AE73' }} /> : <Clear style={{ color: '#F44812' }} />}</TableCell>

                <TableCell>{moment.unix(credential.created_date).format("DD.MM.YYYY. HH:mm")}</TableCell>
                <TableCell>{credential.created_by}</TableCell>
                <TableCell>
                    <Fab color="secondary" onClick={() => this.setState({ isOpen: true }, () => this.getParamDetails(jsonObject))}>
                        <FindInPage />
                    </Fab>
                </TableCell>
            </TableRow>
        })

        let columns = [
            {
                name: "is_active",
                width: '10%'
            },
            {
                name: "created_date",
                width: '40%'
            },
            {
                name: "created_by",
                width: '40%'
            },
            {
                name: "",
                width: '10%'
            }

        ]

        return (<>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }} mt={1}>
                        <Button color="primary" className="default-button" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ dialogOpen: true })} >
                            <Add />{this.props.t("new_credentials")}
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                        <Base58Table
                            isLoading={googleCredentialsLoading}
                            columns={columns}
                            data={credentials}
                            count={googleCredentialsTotalElement}
                            rowsPerPage={googleCredentialsParams.size}
                            page={googleCredentialsParams.page}
                            onPageChange={(e, page) => this.props.setGoogleCredentialsPage(page)}
                            onRowsPerPageChange={(e) => this.props.setGoogleCredentialsSize(e.target.value)}
                            onFilterChange={(params) => this.props.setGoogleCredentialsParams(params)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <CreateCredentialModal
                isOpen={dialogOpen}
                onClose={() => this.setState({ dialogOpen: false })}
                onSave={() => this.setState({ dialogOpen: false }, () => this.props.getGoogleCredentials())} />
            <ParamsDetailsDialog
                isOpen={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false })}
                closeButtonTitle={this.props.t("close")}
                selectedParams={this.state.selectedParams}
            />
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

const mapActionsToProps = { getGoogleCredentials, setGoogleCredentialsPage, setGoogleCredentialsSize, setGoogleCredentialsParams }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Configuration))
