import types from './types';

const initialState = {
    examinations: [],
    examinationsToday: [],
    isLoading: false,
    examinationStats: null,
    examinationsCount: null,
    examinationStatsPerServices: null
}

export default function examination(state = initialState, action) {
    switch (action.type) {
        case types.SET_EXAMINATIONS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case types.SET_EXAMINATIONS:
            return {
                ...state,
                examinations: action.payload,
                isLoading: false
            }
        case types.SET_TODAY_EXAMINATIONS:
            return {
                ...state,
                examinationsToday: action.payload,
                isLoading: false
            }
        case types.SET_EXAMINATION_STATISTICS:
            return {
                ...state,
                examinationStats: action.payload
            }
        case types.SET_EXAMINATION_PER_DOCTOR:
            return {
                ...state,
                examinationsCount: action.payload
            }
        case types.SET_EXAMINATION_STATISTICS_PER_SERVICES:
            return {
                ...state,
                examinationStatsPerServices: action.payload
            }
        default:
            return state;
    }
}