const countries = [
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AT', label: 'Austria', phone: '43' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387', },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    { code: 'DE', label: 'Germany', phone: '49' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'FR', label: 'France', phone: '33' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GR', label: 'Greece', phone: '30' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'US', label: 'United States', phone: '1' },
    { code: 'XK', label: 'Kosovo', phone: '383' },


];

export default countries;