import types from './types';
import api from '../../api/userApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';

export const getUsers = () => async (dispatch) => {
    dispatch({
        type: types.SET_USERS_LOADING
    });
    await api.getUsers({ params: store.getState().userReducer.usersParams }).then(response => {
        dispatch({
            type: types.SET_USERS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_USERS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_users_error")));
    });
    return Promise.resolve();
}

export const setUsersPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_USERS_PAGE,
        payload: Number(value)
    });
    dispatch(getUsers());
    return Promise.resolve();
};

export const setUsersSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_USERS_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getUsers());
    }
    return Promise.resolve();
};

export const setUsersParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_USERS_PARAMS,
        payload: Object.assign(store.getState().userReducer.usersParams, params)
    });
    dispatch(getUsers());
    return Promise.resolve();
};

export const getRoles = () => async (dispatch) => {
    await api.getRoles().then(response => {
        dispatch({
            type: types.SET_ROLES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_ROLES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_roles_error")));
    });
    return Promise.resolve();
}

export const getDoctors = (config = {}) => async (dispatch) => {
    await api.getDoctors(config).then(response => {
        dispatch({
            type: types.SET_DOCTORS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_DOCTORS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_doctors_error")));
    });
    return Promise.resolve();
}

export const createUser = (data) => async (dispatch) => {
    return await api.createUser(data).then((response) => {
        dispatch(snackActions.success(i18next.t("user_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const updateUser = (id, data) => async (dispatch) => {
    return await api.updateUser(id, data).then((response) => {
        dispatch(snackActions.success(i18next.t("user_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const getUserById = (id, config = {}) => async (dispatch) => {
    return await api.getUserById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_user_by_id_error")));
    });
}

export const deleteUser = (id) => async (dispatch) => {
    await api.deleteUser(id).then(() => {
        dispatch(snackActions.success(i18next.t("user_deleted_successfully")));
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("delete_user_error")));
    })
    return Promise.resolve();
}

export const importDoctorSignature = (id, data) => async (dispatch) => {
    return await api.importDoctorSignature(id, data).then((response) => {
        dispatch(snackActions.success(i18next.t("import_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response && error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const getDoctorSignature = (id, config = {}) => async (dispatch) => {
    return await api.getDoctorSignature(id, config).then(response => {
        return response
    }).catch(error => {
        if (error.response && error.response.data.code === 1901) {
        } else {
            dispatch(snackActions.error(
                error.response && error.response.data.errors.map((item, index) => {
                    return <li key={index}>{item}</li>
                })
            ));
        }
    });
}