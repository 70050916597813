import React, { Component } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    IconButton,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';

import { Close } from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { Send } from '@mui/icons-material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class CustomMessageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: ''
        };
    }

    handleChange = (e) => {
        this.setState({ message: e.target.value })
    }

    render() {

        return (
            <BootstrapDialog
                fullWidth
                maxWidth={"sm"}
                onClose={() => this.props.onClose()}
                aria-labelledby="customized-dialog-title"
                open={this.props.isOpen}
                TransitionComponent={Transition}>
                <BootstrapDialogTitle onClose={this.props.onClose}>
                    {this.props.t("message")}
                </BootstrapDialogTitle>
                <DialogContent>

                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid item container>
                                <TextField
                                    label={this.props.t("message")}
                                    name="message"
                                    value={this.state.message}
                                    multiline
                                    rows={5}
                                    onChange={this.handleChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography variant="body2" gutterBottom>
                        <strong>{this.props.t("number_of_character")}</strong>: {this.state.message.length}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                            className="default-button"
                            title={this.props.t("send_messages")}
                            color="primary"
                            style={{ marginLeft: 'auto' }}
                            disabled={!this.state.message}
                            onClick={() => this.props.sendMessage(this.state.message)}>
                            <Send />
                            <span style={{ marginLeft: '5px' }}>{this.props.t("send_message")}</span>
                        </Button>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        );
    }
}

export default withTranslation()(CustomMessageModal);
