import React, { Component } from 'react';
import './Login.css';
import { GOOGLE_AUTH_URL } from '../../config';
import { Redirect } from 'react-router-dom'
import googleLogo from '../../assets/icon/google-logo.png';
import { withTranslation } from 'react-i18next';
import { FlagIcon } from "react-flag-kit";
import {
    Grid,
    Container,
    Typography,
    Chip,
    Avatar,
    IconButton
} from "@mui/material";

class Login extends Component {

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                this.props.enqueueSnackbar(this.props.location.state.error, { variant: 'error' });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
    }

    render() {
        if (this.props.user) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: { from: this.props.location }
                }} />;
        }

        return (
            <Container maxWidth={false} className="loading-position-login" >
                <Grid container direction="row" justifyContent="end">
                    <div>
                        <IconButton onClick={() => this.props.i18n.changeLanguage("en")}>
                            <FlagIcon code={"GB"} size={26} title={this.props.t("english")} />
                        </IconButton>
                        <IconButton onClick={() => this.props.i18n.changeLanguage("hr")}>
                            <FlagIcon code={"HR"} size={26} title={this.props.t("croatian")} />
                        </IconButton>
                    </div>
                </Grid>
                <Grid container direction="row" alignItems="center" spacing={2} >
                    <Grid item container direction="column" xs={12} sm={12} md={7} lg={5} xl={5} style={{ textAlign: 'center', marginTop: '10%' }} >
                        <Typography variant="h2" className="login-title"> <b>{this.props.t("welcome_to_medica")}</b></Typography>
                        <div style={{ textAlign: 'center' }}>
                            <Chip
                                className="custom-chip"
                                onClick={() => window.open(GOOGLE_AUTH_URL, "_self")}
                                avatar={<Avatar src={googleLogo} alt="Google" style={{ width: 40, height: 40 }} />}
                                label={<Typography variant="h6" gutterBottom>{this.props.t("login_with_google")}</Typography>} />
                        </div>
                        {this.props.loginError && <div style={{ textAlign: 'center' }}>
                            <Chip
                                style={{ padding: '20px', marginTop: '10px', backgroundColor: '#FAA0A0', color: 'white' }}
                                label={<Typography variant="body1" gutterBottom>{this.props.t(this.props.loginError)}</Typography>} />
                        </div>}
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withTranslation()(Login);
