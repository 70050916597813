import axios from '../axiosConfig';

export function getServiceGroups(config = {}) {
    return axios.get(`/v1/service-group`, config);
}

export function getServiceGroupsList(config = {}) {
    return axios.get(`/v1/service-group/list`, config);
}

export function getServiceGroupById(id, config = {}) {
    return axios.get(`/v1/service-group/${id}`, config);
}

export function createServiceGroup(data) {
    return axios.post(`/v1/service-group`, data);
}

export function updateServiceGroup(id, data) {
    return axios.patch(`/v1/service-group/${id}`, data);
}

export function deleteServiceGroup(id) {
    return axios.delete(`/v1/service-group/${id}`);
}

const actions = {
    getServiceGroups,
    getServiceGroupsList,
    getServiceGroupById,
    createServiceGroup,
    updateServiceGroup,
    deleteServiceGroup
}

export default actions;
