export const SET_SERVICE_GROUPS_LOADING = 'SET_SERVICE_GROUPS_LOADING';
export const SET_SERVICE_GROUPS = 'SET_SERVICE_GROUPS';
export const SET_SERVICE_GROUPS_LIST = 'SET_SERVICE_GROUPS_LIST'
export const SET_SERVICE_GROUPS_PAGE = 'SET_SERVICE_GROUPS_PAGE'
export const SET_SERVICE_GROUPS_SIZE = 'SET_SERVICE_GROUPS_SIZE'
export const SET_SERVICE_GROUPS_PARAMS = 'SET_SERVICE_GROUPS_PARAMS'
export const RESET_SERVICE_GROUP_DATA = 'RESET_SERVICE_GROUP_DATA'

const types = {
    SET_SERVICE_GROUPS_LOADING,
    SET_SERVICE_GROUPS,
    SET_SERVICE_GROUPS_LIST,
    SET_SERVICE_GROUPS_PAGE,
    SET_SERVICE_GROUPS_SIZE,
    SET_SERVICE_GROUPS_PARAMS,
    RESET_SERVICE_GROUP_DATA
};

export default types;