import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    TextField,
    Fab,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    CircularProgress,
    ListItem,
    ListItemButton,
    ListItemText,
    List,
    ListItemIcon,
    Button,
    Divider,
    Chip,
    Tooltip,
    Zoom
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Close, InsertDriveFile, Delete, Download, Clear, Image, PictureAsPdf, Feed, Search, VideoFile } from '@mui/icons-material'
import { getPatientFiles, importFiles, deleteFile, downloadPatientFile, filterPatientFiles } from '../../redux/patient/actions';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import moment from 'moment';
import { snackActions } from '../../redux/snackbar/reducer';
import store from '../../redux/store';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ExaminationPatientFilesModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            filesData: [],
            confirmationDialog: false,
            cdnId: null,
            fileName: '',
            filterFiles: '',
            description: '',
            validFile: true
        };
    }

    /* componentDidMount() {
        this.props.getPatientFiles(this.props.patientId)
    } */

    handleChangeFile = (e) => {
        const arrayFiles = Array.from(e.target.files)
        if (!arrayFiles) {
            return;
        }

        arrayFiles.forEach(file => {
            if (file.size > 10485760) {
                this.setState({ validFile: false })
                store.dispatch(snackActions.warning(this.props.t("file_size_error")));
            } else {
                this.setState({ validFile: true })
            }
        })

        const data = new FormData()
        arrayFiles.map(element => {
            return data.append('file', element)
        });

        this.setState({ files: arrayFiles, filesData: data })
    }

    handleChangeDescription = (e) => {
        this.setState({ description: e.target.value.trimStart() })
    }

    importFiles = () => {
        let { files } = this.state
        let formData = new FormData();

        //Adding files to the formdata
        files.map(element => {
            return formData.append('file', element)
        });
        formData.append("description", this.state.description);
        this.props.importFiles(this.props.patientId, formData).then(response => {
            if (response.status !== 400) {
                if (response.status !== 409) {
                    this.setState({ filesData: [], files: [], description: '', validFile: true })
                    this.props.getPatientFiles(this.props.patientId)
                }
            }
        })
    }

    deleteFile = () => {
        this.props.deleteFile(this.state.cdnId).then(response => {
            if (response.status !== 400) {
                this.setState({ confirmationDialog: false, cdnId: null })
                this.props.getPatientFiles(this.props.patientId)
            }
        })
    }

    downloadPatientFile = (id, pathName) => {
        this.props.downloadPatientFile(id, pathName)
    }

    getIcon = (extension) => {
        switch (extension) {
            case 'pdf':
                return <PictureAsPdf />
            case 'jpg':
                return <Image />
            case 'png':
                return <Image />
            case 'jpeg':
                return <Image />
            case 'docx':
                return <Feed />
            case 'txt':
                return <Feed />
            case 'xslx':
                return <Feed />
            case 'odt':
                return <Feed />
            case 'mp4':
                return <VideoFile />
            default:
                return <InsertDriveFile />
        }
    }

    handleChangeFilter = (event) => {
        this.setState({ filterFiles: event.target.value })
    }

    filterFiles = () => {
        let params = {}
        params.name = this.state.filterFiles
        this.props.filterPatientFiles(this.props.patientId, { params: params })
    }

    handleRemoveFile = (item) => {
        let { files } = this.state
        const index = files.indexOf(item, 0);
        if (index > -1) {
            files.splice(index, 1)
            files.forEach(file => {
                if (file.size > 10485760) {
                    this.setState({ validFile: false })
                } else {
                    this.setState({ validFile: true })
                }
            })
        }
        if (files.length === 0) {
            this.setState({ validFile: true })
        }
        this.setState({ files: files })
    }

    render() {
        let { files, confirmationDialog, filterFiles, description } = this.state
        const { patientFiles } = this.props.patientReducer

        return (<>
            <BootstrapDialog
                fullWidth
                maxWidth={"md"}
                onClose={() => { this.setState({ files: [], filesData: [], filterFiles: '' }); this.props.onClose() }}
                aria-labelledby="customized-dialog-title"
                open={this.props.isOpen}
                TransitionComponent={Transition}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { this.setState({ files: [], filesData: [], filterFiles: '', description: '' }); this.props.onClose() }}>
                    {this.props.t("files")}
                </BootstrapDialogTitle>
                <DialogContent>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                id="servicesFile"
                                variant="outlined"
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => { this.handleChangeFile(e); this.setState(() => e.target.value = null) }}
                                inputProps={{
                                    multiple: true
                                }}
                            />
                            <TextField
                                id="deleteFiles"
                                variant="outlined"
                                style={{ display: 'none' }}
                                type="button"
                                onClick={() => this.setState({ files: [], filesData: [], description: '', validFile: true })}
                            />
                            <FormControl fullWidth style={{ maxWidth: '100%' }}>
                                <InputLabel>{files.length > 0 ? this.props.t("selected_files") : this.props.t("select_files")}</InputLabel>
                                <OutlinedInput
                                    style={{ display: 'inline-block' }}
                                    labelid="fileName"
                                    label={files.length > 0 ? this.props.t("selected_files") : this.props.t("select_files")}
                                    id="fileName"
                                    multiline={true}
                                    name="fileName"
                                    disabled={true}
                                    startAdornment={files.length === 0 ? "" : files.map(item => (
                                        <Chip
                                            style={{ margin: '2px' }}
                                            key={item.name}
                                            tabIndex={-1}
                                            label={item.name}
                                            variant="filled"
                                            onDelete={() => this.handleRemoveFile(item)}
                                        />
                                    ))}
                                    endAdornment={
                                        <InputAdornment position="end" style={{ display: 'inline-flex', marginLeft: '93%' }}>
                                            <label htmlFor="servicesFile">
                                                <IconButton
                                                    component="span"
                                                    edge="end"
                                                >
                                                    <InsertDriveFile />
                                                </IconButton>
                                            </label>
                                            <label htmlFor="deleteFiles">
                                                <IconButton
                                                    component="span"
                                                    edge="end"
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </label>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        {files.length > 0 &&
                            <Grid item container>
                                <TextField
                                    label={this.props.t("description")}
                                    name="description"
                                    value={description}
                                    multiline
                                    rows={3}
                                    onChange={this.handleChangeDescription} />
                            </Grid>}
                        {files.length > 0 && <Grid item container direction="column" xs={6} sm={6} md={5} lg={4} xl={3}>
                            <Fab style={{ width: '131px', height: '40px' }} color="primary" className="default-button" disabled={this.props.patientReducer.isLoadingImport || description === '' || this.state.validFile === false} onClick={() => this.importFiles()}>
                                {this.props.patientReducer.isLoadingImport ? <CircularProgress
                                    size={20}
                                    color="secondary"
                                /> : this.props.t("import")}
                            </Fab>
                        </Grid>}
                        {patientFiles && patientFiles.length > 0 && <Grid item container direction="column" xs={6} sm={6} md={7} lg={8} xl={9}></Grid>}
                        {patientFiles && patientFiles.length > 0 && <>
                            <Grid item container direction="column" xs={9} sm={9} md={9} lg={6} xl={6}>
                                <OutlinedInput
                                    name="search"
                                    placeholder={this.props.t("search")}
                                    value={filterFiles}
                                    onChange={(e) => this.handleChangeFilter(e)}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            this.filterFiles()
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {filterFiles !== '' && <IconButton
                                                component="span"
                                                edge="end"
                                                onClick={(e) => this.setState({ filterFiles: '', description: '' }, () => this.props.getPatientFiles(this.props.patientId))}
                                            >
                                                <Clear />
                                            </IconButton>}
                                        </InputAdornment>
                                    }
                                    inputProps={{
                                        autoComplete: 'off',
                                        spellCheck: false
                                    }}
                                />
                            </Grid>
                            {patientFiles && patientFiles.length > 0 && <Grid item container direction="column" xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Button onClick={() => this.filterFiles()} disabled={filterFiles === ''} className="button-with-border-radius" style={{ height: '40px', textTransform: 'none' }}>
                                    <Search />{this.props.t("search")}
                                </Button>
                            </Grid>}
                            {patientFiles && patientFiles.length > 0 && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Divider style={{ backgroundColor: 'lightgrey', height: 1, width: '100%' }} />
                            </Grid>}
                        </>}
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <PerfectScrollbar style={{ maxHeight: '40vh' }}>
                                    <List sx={{ width: '100%' }} dense={true} >
                                        {patientFiles && patientFiles.map((value) => {
                                            const labelId = { value }
                                            return (
                                                <ListItem
                                                    key={value.cdnId}
                                                    disablePadding
                                                    secondaryAction={<>
                                                        <Fab
                                                            title={this.props.t("download")}
                                                            color="success"
                                                            onClick={() => this.downloadPatientFile(value.cdnId, value.name)}>
                                                            <Download className="table-fab-icon" />
                                                        </Fab>
                                                        <Fab
                                                            title={this.props.t("delete")}
                                                            color="error"
                                                            style={{ marginLeft: '10px' }}
                                                            onClick={() => this.setState({ confirmationDialog: true, cdnId: value.cdnId, fileName: value.name })}>
                                                            <Delete className="table-fab-icon" />
                                                        </Fab>
                                                    </>
                                                    }
                                                >
                                                    <ListItemButton role={undefined} dense>
                                                        <ListItemIcon>
                                                            {this.getIcon(value.extension)}
                                                        </ListItemIcon>
                                                        <ListItemText id={labelId} primary={value.name} secondary={<span style={{ fontSize: '12px' }}>{
                                                            value.description.length > 40 ? <Tooltip
                                                                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={value.description} style={{ cursor: 'pointer' }}>
                                                                <span>{value.description && value.description.length > 40 ? value.description.substring(0, 40) + "... , " + moment.unix(value.createdDate).format("DD.MM.YYYY. HH:mm:ss") + ", " + value.userEmail : value.description}</span>
                                                            </Tooltip> :
                                                                value.description + ", " + moment.unix(value.createdDate).format("DD.MM.YYYY. HH:mm:ss") + ", " + value.userEmail}</span>} />
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </PerfectScrollbar>
                            </Grid>
                        </Grid>

                    </Grid>
                </DialogContent>
            </BootstrapDialog>
            <ConfirmationDialog
                isOpen={confirmationDialog}
                title={this.props.t("delete_file")}
                message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_file_message", { name: this.state.fileName }) }} />}
                onClose={() => this.setState({ confirmationDialog: false, cdnId: null })}
                onConfirm={() => this.deleteFile()}
                closeButtonTitle={this.props.t("close")}
                confirmButtonTitle={this.props.t("delete")}
            />
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer
})

const mapActionsToProps = { getPatientFiles, importFiles, deleteFile, downloadPatientFile, filterPatientFiles }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(ExaminationPatientFilesModal))
