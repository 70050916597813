import React from 'react';
import { withTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux'
import store from '../../redux/store'
import types, { SET_CURRENT_PATIENT } from '../../redux/patient/types';
import {
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Grid,
    Fab,
    Card,
    CardContent,
    Box,
    LinearProgress,
    Typography,
    CardHeader,
    TextField,
    Autocomplete,
    FormControl,
    IconButton,
    TableRow,
    TableCell,
    Collapse,
    Zoom,
    Tooltip,
    CircularProgress,
    createFilterOptions
} from '@mui/material';
import { ArrowBack, Edit, Add, Delete, Clear, ExpandMore, Search, Save, Feed, ArrowDownward, ArrowUpward, PictureAsPdf, AttachFile, Info } from '@mui/icons-material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { getPatientById, updatePatient, getPatientFiles } from '../../redux/patient/actions'
import { getServiceList } from '../../redux/service/actions'
import { getDiagnosesList } from '../../redux/diagnosis/actions'
import { getExaminations, createExamination, updateExamination, getExaminationById, searchExaminations, exportExaminationByIdToPdf, exportExaminationByIdToDocx, exportExaminationsToDocx, exportExaminationsToPdf, deleteExamination, getExaminationCountPerDoctor } from '../../redux/examination/actions'
import Base58Table from '../../components/common/Base58Table';
import moment from 'moment'
import NumberFormat from "react-number-format";
import PatientInfo from './PatientInfo';
import ExaminationPatientFilesModal from './ExaminationPatientFilesModal';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import { hasAnyRole, hasRole } from '../../utils/Security';

class Examination extends React.Component {

    emptyExamination = {
        patientId: null,
        comment: '',
        diagnosisIds: [],
        internalNote: '',
        examinationServices: []
    }

    emptyExaminationService = {
        serviceId: null,
        price: null,
        quantity: null,
        discountAmount: null,
        discountPercentage: null,
        comment: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            examination: JSON.parse(JSON.stringify(this.emptyExamination)),
            initialExamination: JSON.parse(JSON.stringify(this.emptyExamination)),
            examinationService: JSON.parse(JSON.stringify(this.emptyExaminationService)),
            collapseIndex: -1,
            collapseExamination: 1,
            collapseService: 1,
            collapseInternalNote: -1,
            examinationId: null,
            examinationsFilter: '',
            selectedDiagnoses: [],
            mainLoading: true,
            internalNote: null,
            initialInternalNote: null,
            modalOpen: false,
            openDeleteDialog: false,
            examinationToDelete: null
        };
    }

    componentDidMount() {
        Promise.all([this.props.getServiceList(), this.props.getDiagnosesList(), this.props.getExaminations({ params: { patientId: this.props.match.params.id } },

            this.props.getExaminationCountPerDoctor({ params: { patientId: this.props.match.params.id } }),
            this.props.getPatientById(this.props.match.params.id).then(response => {
                this.setState({ internalNote: response.data.internalNote, initialInternalNote: response.data.internalNote })
                store.dispatch({
                    type: SET_CURRENT_PATIENT,
                    payload: response.data
                });
            }))])
            .then(() => {
                this.setState({ mainLoading: false });
            })
            .catch(error => {
                this.setState({ mainLoading: false });
            })
    }

    handleChangeExamination = (event) => {
        const target = event.target;
        let { examination } = this.state;
        examination[target.name] = target.value.trimStart();
        this.setState({ examination: examination })
    }

    calculateDiscount = (index) => {
        let { examination } = this.state;
        let result = (examination.examinationServices[index].price * examination.examinationServices[index].quantity) * (examination.examinationServices[index].discountPercentage / 100)
        examination.examinationServices[index].discountAmount = result.toFixed(2);
        this.setState({ examination: examination })
    }

    handleChangeQuantity = (e, index) => {
        if (e.target) {
            let { examination } = this.state;
            examination.examinationServices[index][e.target.name] = Number(e.target.value);
            examination.examinationServices[index].discountAmount = null
            examination.examinationServices[index].discountPercentage = null
            this.setState({ examination: examination })
        }
    }

    handleChangePrice = (values, sourceInfo, index) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            let { examination } = this.state;
            examination.examinationServices[index][sourceInfo.event.target.name] = floatValue;
            this.setState({ examination: examination })
        }
    }

    handleChangeDiscountPercentage = (values, sourceInfo, index) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            let { examination } = this.state;
            if (sourceInfo.event.target.name === "discountPercentage" && examination.examinationServices[index].discountAmount) {
                examination.examinationServices[index].discountAmount = null
            }
            examination.examinationServices[index].discountPercentage = floatValue === 0 ? null : floatValue;
            this.setState({ examination: examination })
        }
    }

    calculatePercentage = (index) => {
        let { examination } = this.state;
        let result = (examination.examinationServices[index].discountAmount / (examination.examinationServices[index].price * examination.examinationServices[index].quantity)) * 100
        examination.examinationServices[index].discountPercentage = result.toFixed(2);
        this.setState({ examination: examination })
    }

    handleChangeDiscountPrice = (values, sourceInfo, index) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            let { examination } = this.state;
            if (sourceInfo.event.target.name === "discountAmount" && examination.examinationServices[index].discountPercentage) {
                examination.examinationServices[index].discountPercentage = null
            }
            examination.examinationServices[index].discountAmount = floatValue === 0 ? null : floatValue
            this.setState({ examination: examination })
        }
    }

    handleChangeServiceComment = (e, name, index) => {
        let { examination } = this.state;
        examination.examinationServices[index][name] = e.target.value.trimStart();
        this.setState({ examination: examination })
    }

    handleChangeService = (e, service, index) => {
        let { examination } = this.state;
        let examinationService = {};
        if (service !== null) {
            examinationService.serviceId = service.id
            examinationService.price = service.price
            examinationService.quantity = 1
            examinationService.discountAmount = null
            examinationService.discountPercentage = null
            examination.examinationServices[index] = examinationService;
        } else {
            examinationService.serviceId = null
            examinationService.price = ''
            examinationService.quantity = null
            examinationService.discountAmount = null
            examinationService.discountPercentage = null
            examination.examinationServices[index] = examinationService;
        }
        this.setState({ examination: examination })
    }

    handleChangeDiagnosis = (e, diagnosis) => {
        let { selectedDiagnoses, examination } = this.state

        selectedDiagnoses = diagnosis
        examination.diagnosisIds = []

        selectedDiagnoses.forEach(data => {
            examination.diagnosisIds.push(data.id)
        })
        this.setState({ examination: examination, selectedDiagnoses: selectedDiagnoses })
    }

    saveExamination = () => {
        let { examination, examinationId } = this.state
        examination.patientId = this.props.patientReducer.currentPatient.id
        const action = examinationId ? this.props.updateExamination(examinationId, examination) : this.props.createExamination(examination);

        action.then((response) => {
            if (response.status === 200 || response.status === 201) {
                this.setState({ examination: JSON.parse(JSON.stringify(this.emptyExamination)), examinationService: JSON.parse(JSON.stringify(this.emptyExaminationService)), examinationId: null, selectedDiagnoses: [] });
                this.props.getExaminations({ params: { patientId: this.props.match.params.id } })
            }
        })
    }

    saveAsNewExamination = () => {
        let { examination } = this.state
        examination.patientId = this.props.patientReducer.currentPatient.id
        this.props.createExamination(examination).then(() => {
            this.setState({ examination: JSON.parse(JSON.stringify(this.emptyExamination)), examinationService: JSON.parse(JSON.stringify(this.emptyExaminationService)), examinationId: null, selectedDiagnoses: [] });
            this.props.getExaminations({ params: { patientId: this.props.match.params.id } })
        })
    }

    getExaminationById = (id) => {
        this.props.getExaminationById(id).then((response) => {
            const selectedDiagnoses = this.props.diagnosisReducer.diagnosesList.filter((d) => {
                return response.data.diagnosisIds.some((f) => {
                    return f === d.id
                });
            })
            this.setState({
                examination: JSON.parse(JSON.stringify(response.data)),
                initialExamination: JSON.parse(JSON.stringify(response.data)),
                examinationId: id,
                selectedDiagnoses: selectedDiagnoses,
                collapseExamination: 1,
                collapseService: 1
            })
            store.dispatch({
                type: types.SET_CURRENT_PATIENT,
                payload: response.data.patient
            })
        })
    }

    addNewService = () => {
        let { examination } = this.state;
        examination.examinationServices.push(JSON.parse(JSON.stringify(this.emptyExaminationService)));
        this.setState({ examination: examination });
    }

    removeService = (index) => {
        let { examination } = this.state;
        examination.examinationServices.splice(index, 1);
        this.setState({ examination: examination });
    }

    handleChangeFilter = (event) => {
        this.setState({ examinationsFilter: event.target.value })
    }

    filterExaminations = () => {
        let params = {}
        params.patientId = this.props.patientReducer.currentPatient.id
        params.query = this.state.examinationsFilter
        this.props.searchExaminations({ params: params })
    }

    validateForm() {
        let { examination, initialExamination } = this.state;
        let valid = true;
        if (examination.comment.length > 5000) {
            valid = false;
        }
        if (examination.comment.length === 0) {
            valid = false;
        }
        if (JSON.stringify(examination) === JSON.stringify(initialExamination)) {
            valid = false
        }
        if (examination.examinationServices.length === 0) {
            valid = false
        }
        for (let i = 0; i < examination.examinationServices.length; i++) {
            if (examination.examinationServices[i].serviceId === null ||
                examination.examinationServices[i].price === undefined ||
                (examination.examinationServices[i].comment && examination.examinationServices[i].comment.length > 255) ||
                (examination.examinationServices[i].discountPercentage && examination.examinationServices[i].discountPercentage > 100) ||
                (examination.examinationServices[i].quantity === 0) ||
                (examination.examinationServices[i].discountAmount > (examination.examinationServices[i].price * examination.examinationServices[i].quantity))) {
                return valid = false;
            }
        }
        return valid;
    }

    getNameHelperTextComment() {
        let { examination } = this.state;
        if (!examination.comment.trim()) {
            return this.props.t("required_field_message");
        }
        if (examination.comment && examination.comment.length > 5000) {
            return this.props.t("number_regex_message", { max: 5000 })
        }
    }

    disabledOption = (id) => {
        let { examination } = this.state
        for (let i = 0; i < examination.examinationServices.length; i++) {
            if (examination.examinationServices[i].serviceId === id) {
                return true;
            }
        }
        return false;
    }

    disabledDeleteOption = (data) => {
        if (data.createdBy === this.props.authReducer.user.email && hasRole("DOCTOR")) {
            return true
        }
        if (hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM"])) {
            return true
        }
        return false
    }

    handleChangeInternalNote = (event) => {
        const target = event.target;
        let { internalNote } = this.state;
        internalNote = target.value ? target.value.trimStart() : null;
        this.setState({ internalNote: internalNote })
    }

    updateInternalNote = () => {
        let data = {
            ...this.props.patientReducer.currentPatient,
            internalNote: this.state.internalNote
        }
        this.props.updatePatient(this.props.patientReducer.currentPatient.id, data).then((response) => {
            this.setState({ internalNote: response.data.internalNote, initialInternalNote: response.data.internalNote })
        })
    }

    validationInternalNote = () => {
        let { internalNote, initialInternalNote } = this.state;
        let valid = true;
        if (internalNote && internalNote.length === 0) {
            valid = false;
        }
        if (JSON.stringify(internalNote) === JSON.stringify(initialInternalNote)) {
            valid = false
        }
        return valid;
    }

    closeDialog = () => {
        this.setState({ modalOpen: false })
    }

    deleteExamination = () => {
        let params = {}
        params.activeStatus = false
        this.props.deleteExamination(this.state.examinationToDelete.id, { params: params }).then(() => {
            this.setState({ openDeleteDialog: false, examination: JSON.parse(JSON.stringify(this.emptyExamination)), examinationId: null, selectedDiagnoses: [], collapseIndex: -1 })
            this.props.getExaminations({ params: { patientId: this.props.match.params.id } })
        })
    }

    render() {
        const { examination, collapseIndex, selectedDiagnoses, collapseExamination, collapseService, collapseInternalNote, examinationsFilter, mainLoading, initialExamination, internalNote } = this.state
        const { currentPatient } = this.props.patientReducer
        const { serviceList } = this.props.serviceReducer
        const { examinations, isLoading, examinationsCount } = this.props.examinationReducer
        const { diagnosesList } = this.props.diagnosisReducer

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        let columns = [
            {
                name: "service",
                width: '45%'
            },
            {
                name: "quantity",
                width: '10%',
            },
            {
                name: "price",
                width: '18%'
            },
            {
                name: "discount_amount",
                width: '15%',
            },
            {
                name: "discount_percentage_%",
                width: '12%',
            }
        ]

        const filterOptions = createFilterOptions({
            matchFrom: 'any',
            limit: 1000,
        });

        let examinationServices = examination.examinationServices.map((examinationService, index) => {
            return <React.Fragment key={index}>
                <TableRow>
                    <TableCell>
                        <FormControl>
                            {
                                serviceList.some(es => es.id === examinationService.serviceId) ?
                                    <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={<div style={{ fontSize: '14px', maxWidth: '250px' }}>
                                        {serviceList.some(es => es.id === examinationService.serviceId) ? <p><b>{this.props.t("code")}:</b> {serviceList.find(es => es.id === examinationService.serviceId).code}</p> : null}
                                        {serviceList.some(es => es.id === examinationService.serviceId) ? <p><b>{this.props.t("name")}:</b> {serviceList.find(es => es.id === examinationService.serviceId).name}</p> : null}
                                    </div>}>
                                        <Autocomplete
                                            required
                                            label={this.props.t("select_service")}
                                            placeholder={this.props.t("select_service")}
                                            name="selectedService"
                                            value={serviceList.some(es => es.id === examinationService.serviceId) ? serviceList.find(es => es.id === examinationService.serviceId) : null}
                                            getOptionLabel={(option) => option ? option.code + " - " + option.name : ""}
                                            options={serviceList}
                                            getOptionDisabled={option => this.disabledOption(option.id)}
                                            onChange={(event, service) => {
                                                this.handleChangeService(event, service, index)
                                            }}
                                            clearText={this.props.t("clear")}
                                            closeText={this.props.t("close")}
                                            openText={this.props.t("open")}
                                            renderInput={(params) => <TextField {...params} required label={this.props.t("select_service")} inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', spellCheck: false // disable autocomplete and autofill
                                            }} />}
                                        />
                                    </Tooltip>
                                    : <Autocomplete
                                        required
                                        label={this.props.t("select_service")}
                                        placeholder={this.props.t("select_service")}
                                        name="selectedService"
                                        value={serviceList.some(es => es.id === examinationService.serviceId) ? serviceList.find(es => es.id === examinationService.serviceId) : null}
                                        getOptionLabel={(option) => option ? option.code + " - " + option.name : ""}
                                        options={serviceList}
                                        getOptionDisabled={option => this.disabledOption(option.id)}
                                        onChange={(event, service) => {
                                            this.handleChangeService(event, service, index)
                                        }}
                                        clearText={this.props.t("clear")}
                                        closeText={this.props.t("close")}
                                        openText={this.props.t("open")}
                                        renderInput={(params) => <TextField {...params} required label={this.props.t("select_service")}
                                            error={examination.examinationServices[index].serviceId === null ? true : false} inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', spellCheck: false // disable autocomplete and autofill
                                            }} />}
                                    />
                            }
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <TextField
                            required
                            id="quantity"
                            name="quantity"
                            type="number"
                            value={examination.examinationServices[index].quantity || ''}
                            error={examination.examinationServices[index].quantity === 0 || examination.examinationServices[index].quantity === ''}
                            onChange={(e) => this.handleChangeQuantity(e, index)}
                            disabled={examination.examinationServices[index].serviceId === null}
                            InputProps={{
                                inputProps: {
                                    min: 1
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell>
                        <NumberFormat
                            id="price"
                            name="price"
                            customInput={TextField}
                            decimalScale={2}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            value={examination.examinationServices[index].price}
                            onValueChange={(values, sourceInfo) => this.handleChangePrice(values, sourceInfo, index)}
                            allowNegative={false}
                            disabled={examination.examinationServices[index].serviceId === null}
                        />
                    </TableCell>
                    <TableCell>
                        <NumberFormat
                            id="discountAmount"
                            name="discountAmount"
                            title={this.props.t("press_enter_info_discount_amount")}
                            customInput={TextField}
                            decimalScale={2}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            value={examination.examinationServices[index].discountAmount || ''}
                            onValueChange={(values, sourceInfo) => this.handleChangeDiscountPrice(values, sourceInfo, index)}
                            disabled={examination.examinationServices[index].serviceId === null}
                            error={examination.examinationServices[index].discountAmount > (examination.examinationServices[index].price * examination.examinationServices[index].quantity) ? true : false}
                            allowNegative={false}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.calculatePercentage(index)
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell>
                        <NumberFormat
                            id="discountPercentage"
                            name="discountPercentage"
                            title={this.props.t("press_enter_info_discount_price")}
                            customInput={TextField}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={examination.examinationServices[index].discountPercentage || ''}
                            onValueChange={(values, sourceInfo) => this.handleChangeDiscountPercentage(values, sourceInfo, index)}
                            allowNegative={false}
                            disabled={examination.examinationServices[index].serviceId === null}
                            error={examination.examinationServices[index].discountPercentage > 100 ? true : false}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.calculateDiscount(index)
                                }
                            }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan="4">
                        <TextField
                            label={this.props.t("comment")}
                            name="comment"
                            value={examination.examinationServices[index].comment || ''}
                            onChange={(e) => this.handleChangeServiceComment(e, "comment", index)}
                            disabled={examination.examinationServices[index].serviceId === null}
                            error={examination.examinationServices[index].comment && examination.examinationServices[index].comment.length > 255 ? true : false}
                            helperText={examination.examinationServices[index].comment && examination.examinationServices[index].comment.length > 255 && this.props.t("number_regex_message", { max: 255 })}
                            inputProps={{ autoComplete: 'off', spellCheck: false, style: { textTransform: "uppercase" } }} />
                    </TableCell>
                    <TableCell align='right'>
                        <Fab title={this.props.t("delete")} className="table-fab" color="primary" aria-label="delete" onClick={() => this.removeService(index)}>
                            <Delete className="table-fab-icon" />
                        </Fab>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        })

        return (<>
            <Prompt when={JSON.stringify(examination) !== JSON.stringify(initialExamination)} message={this.props.t("warning_message")} />
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item container>
                        <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }}>
                            <Fab
                                title={this.props.t("back")}
                                color="primary"
                                onClick={() => this.props.history.goBack()}>
                                <ArrowBack className="table-fab-icon" />
                            </Fab> <span style={{ marginLeft: 10 }}>{this.props.t("patient")}: {currentPatient.firstName + " " + currentPatient.lastName + " (" + currentPatient.dateOfBirth + ") "}</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} mt={1}>
                <Grid item container direction="column" xs={6} sm={6} md={6} lg={7} xl={7}>
                    <Grid item container>
                        <Card style={{ height: '87vh', width: '100%' }}>
                            <CardHeader
                                title={this.props.t("new_examination")}
                                action={<>
                                    <Fab
                                        style={{ marginRight: '10px' }}
                                        title={this.props.t("files")}
                                        color="primary"
                                        onClick={() => this.setState({ modalOpen: true }, () => this.props.getPatientFiles(this.props.match.params.id))}>
                                        <AttachFile className="table-fab-icon" />
                                    </Fab>
                                    <Fab
                                        style={{ marginRight: '10px' }}
                                        title={this.props.t("save")}
                                        color="primary"
                                        onClick={() => this.saveExamination()}
                                        disabled={!this.validateForm()}>
                                        <Save className="table-fab-icon" />
                                    </Fab>
                                    <Fab
                                        style={{ marginRight: '10px' }}
                                        title={this.props.t("save_as_new")}
                                        color="primary"
                                        onClick={() => this.saveAsNewExamination()}
                                        disabled={!this.validateForm()}>
                                        <Add className="table-fab-icon" />
                                    </Fab>
                                    <Fab
                                        style={{ marginRight: '10px' }}
                                        title={this.props.t("clear_form")}
                                        color="secondary"
                                        onClick={() => this.setState({ examination: JSON.parse(JSON.stringify(this.emptyExamination)), examinationId: null, selectedDiagnoses: [] })}>
                                        <Clear className="table-fab-icon" />
                                    </Fab>
                                </>
                                }>
                            </CardHeader>
                            <PerfectScrollbar style={{ maxHeight: '76vh' }}>
                                <CardContent>
                                    <Box margin={1}>
                                        <PatientInfo />
                                    </Box>
                                    <Box margin={1}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={10} xl={10}>
                                                <TextField
                                                    label={this.props.t("internal_note_patient")}
                                                    name="internalNote"
                                                    value={internalNote || ''}
                                                    onChange={this.handleChangeInternalNote}
                                                    multiline
                                                    rows={3}
                                                    inputProps={{
                                                        autoComplete: 'off',
                                                        spellCheck: false,
                                                        style: { textTransform: "uppercase" }
                                                    }} />
                                            </Grid>
                                            <Grid item container direction="column" xs={2} sm={2} md={2} lg={1} xl={1}>
                                                <FormControl>
                                                    <Button onClick={() => this.updateInternalNote()} disabled={!this.validationInternalNote()}>
                                                        <Edit />
                                                    </Button>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <IconButton size="small" onClick={() => this.setState({ collapseExamination: collapseExamination !== 1 ? 1 : -1 })}>
                                            {1 === collapseExamination ? <ArrowUpward style={{ marginRight: '10px', color: '#87A397' }} /> : <ArrowDownward style={{ marginRight: '10px', color: '#87A397' }} />}
                                            <Typography variant="h6" gutterBottom style={{ color: '#87A397', marginTop: '4px' }}>
                                                <strong>{this.props.t("examination")}</strong>
                                            </Typography>
                                        </IconButton>
                                    </Grid>
                                    <Collapse in={1 === this.state.collapseExamination} timeout="auto" unmountOnExit>
                                        <Box margin={1}>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item container direction="column" xs={8} sm={8} md={8} lg={8} xl={8}>
                                                    <FormControl>
                                                        <Autocomplete
                                                            label={this.props.t("diagnosis")}
                                                            placeholder={this.props.t("diagnosis")}
                                                            name="selectedDiagnoses"
                                                            disableListWrap
                                                            filterOptions={filterOptions}
                                                            value={selectedDiagnoses}
                                                            options={diagnosesList}
                                                            multiple={true}
                                                            getOptionLabel={(option) => option ? option.code + " " + option.name : ""}
                                                            onChange={(event, diagnosis) => {
                                                                this.handleChangeDiagnosis(event, diagnosis)
                                                            }}
                                                            clearText={this.props.t("clear")}
                                                            closeText={this.props.t("close")}
                                                            openText={this.props.t("open")}
                                                            renderInput={(params) => <TextField {...params} label={this.props.t("diagnosis")} inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'off', spellCheck: false
                                                            }} />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <TextField
                                                        required
                                                        label={this.props.t("therapy_comment")}
                                                        name="comment"
                                                        value={(examination.comment && examination.comment.charAt(0).toUpperCase() + examination.comment.slice(1))}
                                                        onChange={this.handleChangeExamination}
                                                        multiline
                                                        rows={6}
                                                        onKeyDown={(e) => {
                                                            const { value } = e.target;
                                                            if (e.key === 'Tab') {
                                                                e.preventDefault();
                                                                const cursorPosition = e.target.selectionStart;
                                                                const cursorEndPosition = e.target.selectionEnd;
                                                                const tab = '\t';
                                                                e.target.value =
                                                                    value.substring(0, cursorPosition) +
                                                                    tab +
                                                                    value.substring(cursorEndPosition);
                                                                e.target.selectionStart = cursorPosition + 1;
                                                                e.target.selectionEnd = cursorPosition + 1;
                                                            }
                                                        }}
                                                        inputProps={{
                                                            autoComplete: 'off',
                                                            spellCheck: false
                                                        }}
                                                        error={examination.comment.length > 5000 || !examination.comment.trim() ? true : false}
                                                        helperText={this.getNameHelperTextComment()} />
                                                </Grid>
                                            </Grid>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <IconButton size="small" onClick={() => this.setState({ collapseInternalNote: collapseInternalNote !== 1 ? 1 : -1 })}>
                                                    {1 === collapseInternalNote ? <ArrowUpward style={{ marginRight: '10px', color: '#87A397' }} /> : <ArrowDownward style={{ marginRight: '10px', color: '#87A397' }} />}
                                                    <Typography variant="h7" gutterBottom style={{ color: '#87A397', marginTop: '4px' }}>
                                                        <strong>{this.props.t("internal_note")}</strong>
                                                    </Typography>
                                                </IconButton>
                                            </Grid>
                                            <Collapse in={1 === this.state.collapseInternalNote} timeout="auto" unmountOnExit>
                                                <Box margin={1}>
                                                    <Grid container direction="row" spacing={2}>
                                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <TextField
                                                                label={this.props.t("internal_note")}
                                                                name="internalNote"
                                                                value={examination.internalNote || ''}
                                                                onChange={this.handleChangeExamination}
                                                                multiline
                                                                rows={6}
                                                                inputProps={{
                                                                    autoComplete: 'off',
                                                                    spellCheck: false,
                                                                    style: { textTransform: "uppercase" }
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    const { value } = e.target;

                                                                    if (e.key === 'Tab') {
                                                                        e.preventDefault();
                                                                        const cursorPosition = e.target.selectionStart;
                                                                        const cursorEndPosition = e.target.selectionEnd;
                                                                        const tab = '\t';

                                                                        e.target.value =
                                                                            value.substring(0, cursorPosition) +
                                                                            tab +
                                                                            value.substring(cursorEndPosition);
                                                                        e.target.selectionStart = cursorPosition + 1;
                                                                        e.target.selectionEnd = cursorPosition + 1;
                                                                    }
                                                                }} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Collapse>
                                        </Box>
                                    </Collapse>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ collapseService: collapseService !== 1 ? 1 : -1 })}>
                                            {1 === collapseService ? <ArrowUpward style={{ marginRight: '10px', color: '#87A397' }} /> : <ArrowDownward style={{ marginRight: '10px', color: '#87A397' }} />}
                                            <Typography variant="h6" gutterBottom style={{ color: '#87A397', marginTop: '4px' }}>
                                                <strong>{this.props.t("services")}</strong>
                                            </Typography>
                                        </IconButton>
                                    </Grid>
                                    <Collapse in={1 === this.state.collapseService} timeout="auto" unmountOnExit>
                                        <Box margin={1}>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Fab className="table-fab"
                                                        title={this.props.t("add")}
                                                        size="small"
                                                        color="primary"
                                                        style={{ marginLeft: 'auto' }}
                                                        onClick={() => this.addNewService()}>
                                                        <Add className="table-fab-icon" />
                                                    </Fab>
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Base58Table
                                                        columns={columns}
                                                        data={examinationServices}
                                                        pagination={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Collapse>
                                </CardContent>
                            </PerfectScrollbar>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={6} sm={6} md={6} lg={5} xl={5}>
                    <Grid item container>
                        <Card style={{ height: '87vh', width: '100%' }}>
                            {isLoading ? <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box> : null}
                            <CardHeader
                                title={this.props.t("history_examination")}
                                action={<>
                                    <Fab
                                        style={{ marginRight: 10 }}
                                        title={this.props.t("export_to_pdf")}
                                        color="primary"
                                        disabled={examinations.length === 0}
                                        onClick={() => this.props.exportExaminationsToPdf(currentPatient.id)}>
                                        <PictureAsPdf className="table-fab-icon" />
                                    </Fab>
                                    <Fab
                                        style={{ marginRight: 10 }}
                                        title={this.props.t("export_to_docx")}
                                        color="primary"
                                        disabled={examinations.length === 0}
                                        onClick={() => this.props.exportExaminationsToDocx(currentPatient.id)}>
                                        <Feed className="table-fab-icon" />
                                    </Fab>
                                </>}>
                            </CardHeader>
                            <PerfectScrollbar style={{ height: 'auto', maxHeight: '80vh' }}>
                                <CardContent>
                                    {examinations && <>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <TextField
                                                    label={this.props.t("search")}
                                                    name="search"
                                                    value={examinationsFilter}
                                                    onChange={this.handleChangeFilter}
                                                    onKeyPress={(event) => {
                                                        if (event.key === "Enter") {
                                                            this.filterExaminations()
                                                        }
                                                    }} />

                                            </Grid>
                                            <Grid item container direction="column" xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <FormControl>
                                                    <Button onClick={() => this.filterExaminations()} className="button-with-border-radius" style={{ height: '40px', textTransform: 'none' }}>
                                                        <Search />{this.props.t("search")}
                                                    </Button>
                                                </FormControl>
                                            </Grid>
                                            {examinations && examinations.length > 0 && <Grid item container direction="column" xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Tooltip
                                                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="bottom-end" title={
                                                        <span>
                                                            <p>{this.props.t("examination_number_doctors")}:</p>
                                                            {examinationsCount && examinationsCount.map((c, index) => {
                                                                return <li key={index}>{c.doctorsFirstName + " " + c.doctorsLastName + ": " + c.examinationCount}</li>
                                                            })}
                                                        </span>}>
                                                    <Info style={{ fontSize: '25px', cursor: 'pointer', marginLeft: 'auto', color: '#6F8684' }} />
                                                </Tooltip>
                                            </Grid>}
                                        </Grid>
                                    </>}
                                    <br />
                                    {examinations.map((data, index) => {
                                        return <Accordion className={index === this.state.collapseIndex ? 'accordion-custom-body-background' : null} TransitionProps={{ unmountOnExit: true }} onClick={() => this.setState({ collapseIndex: collapseIndex !== index ? index : -1 })} key={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls={"panel-content" + index}
                                                id={"panel-header" + index}
                                                style={{ alignItems: 'center', display: 'flex' }}>
                                                <Typography sx={{ width: '25%', flexShrink: 0 }}>
                                                    {moment(data.createdDate).format("DD.MM.YYYY. HH:mm")}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography sx={{ color: 'text.secondary' }}>{data.diagnoses && data.diagnoses.map(data => { return data.name }).join(", ")}</Typography>
                                                <Typography style={{ alignItems: 'center', display: 'flex' }} sx={{ whiteSpace: 'pre-line' }}>
                                                    {data.comment}
                                                </Typography>
                                            </AccordionDetails>
                                            <AccordionActions >
                                                <Typography sx={{ color: 'text.secondary' }} style={{ marginRight: 'auto' }}>{this.props.t("created_by")}: {this.props.authReducer.user.email === data.createdBy ? this.props.t("you") : data.createdBy}</Typography>
                                                <Fab
                                                    style={{ marginRight: '10px' }}
                                                    title={this.props.t("export_to_docx")}
                                                    color="primary"
                                                    onClick={() => this.props.exportExaminationByIdToDocx(data.id)}>
                                                    <Feed className="table-fab-icon" />
                                                </Fab>
                                                <Fab
                                                    style={{ marginRight: '10px' }}
                                                    title={this.props.t("export_to_pdf")}
                                                    color="primary"
                                                    onClick={() => this.props.exportExaminationByIdToPdf(data.id)}>
                                                    <PictureAsPdf className="table-fab-icon" />
                                                </Fab>
                                                <Button color="primary" className="default-button" onClick={() => this.getExaminationById(data.id)}><Edit className="table-fab-icon" />{this.props.t("edit")}</Button>
                                                {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "DOCTOR"]) && <Button disabled={!this.disabledDeleteOption(data)} color="secondary" className="default-button" onClick={() => this.setState({ openDeleteDialog: true, examinationToDelete: data })}><Delete className="table-fab-icon" />{this.props.t("delete")}</Button>}
                                            </AccordionActions>
                                        </Accordion>
                                    })}
                                </CardContent>
                            </PerfectScrollbar>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <ExaminationPatientFilesModal
                isOpen={this.state.modalOpen}
                onClose={this.closeDialog}
                patientId={this.props.match.params.id}
            />
            <ConfirmationDialog
                isOpen={this.state.openDeleteDialog}
                title={this.props.t("delete_examination")}
                message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_examination_message") }} />}
                onClose={() => this.setState({ openDeleteDialog: false })}
                onConfirm={() => this.deleteExamination()}
                closeButtonTitle={this.props.t("close")}
                confirmButtonTitle={this.props.t("delete")}
            />
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer,
    serviceReducer: state.serviceReducer,
    examinationReducer: state.examinationReducer,
    diagnosisReducer: state.diagnosisReducer,
    authReducer: state.authReducer
})

const mapActionsToProps = { getServiceList, getExaminations, createExamination, updateExamination, getExaminationById, getDiagnosesList, searchExaminations, exportExaminationByIdToPdf, exportExaminationByIdToDocx, exportExaminationsToDocx, getPatientById, exportExaminationsToPdf, updatePatient, getPatientFiles, deleteExamination, getExaminationCountPerDoctor }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Examination))
