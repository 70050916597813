import axios from '../axiosConfig';

export function getAuditLogs(config = {}) {
    return axios.get(`/v1/audit/filter`, config);
}

export function getAuditLogsActions(config = {}) {
    return axios.get(`/v1/audit/get-actions`, config)
}

export function deleteAuditLogs(config = {}) {
    return axios.delete(`/v1/audit/delete-by-action-and-time`, config);
}

const actions = {
    getAuditLogs,
    getAuditLogsActions,
    deleteAuditLogs
}

export default actions;
