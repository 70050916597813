import types from './types';

const initialState = {
    userLoading: false,
    user: null,
    unsubscribeMessage:''
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case types.SET_LOADING_USER:
            return {
                ...state,
                userLoading: true
            }
        case types.SET_LOGGED_USER:
            return {
                ...state,
                user: action.payload,
                userLoading: false
            }
        case types.SET_UNSUBSCRIBE_MESSAGE:
            return {
                ...state,
                unsubscribeMessage: action.payload
            }
        default:
            return state;
    }
}