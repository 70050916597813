import types from '../serviceGroup/types';
import api from '../../api/serviceGroupApi';
import { snackActions } from '../../redux/snackbar/reducer';
import i18next from 'i18next';
import store from '../store';

export const getServiceGroups = () => async (dispatch) => {
    dispatch({
        type: types.SET_SERVICE_GROUPS_LOADING
    });
    await api.getServiceGroups({ params: store.getState().serviceGroupReducer.serviceGroupsParams }).then(response => {
        dispatch({
            type: types.SET_SERVICE_GROUPS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_SERVICE_GROUPS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_service_groups_error")));
    });
    return Promise.resolve();
}

export const setServiceGroupsPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SERVICE_GROUPS_PAGE,
        payload: Number(value)
    });
    dispatch(getServiceGroups());
    return Promise.resolve();
};

export const setServiceGroupsSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_SERVICE_GROUPS_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getServiceGroups());
    }
    return Promise.resolve();
};

export const setServiceGroupsParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_SERVICE_GROUPS_PARAMS,
        payload: Object.assign(store.getState().serviceGroupReducer.serviceGroupsParams, params)
    });
    dispatch(getServiceGroups());
    return Promise.resolve();
};

export const getServiceGroupsList = () => async (dispatch) => {
    await api.getServiceGroupsList().then(response => {
        dispatch({
            type: types.SET_SERVICE_GROUPS_LIST,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_SERVICE_GROUPS_LIST,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_service_groups_list_error")));
    });
    return Promise.resolve();
}

export const createServiceGroup = (data) => async (dispatch) => {
    return await api.createServiceGroup(data).then((response) => {
        dispatch(snackActions.success(i18next.t("service_group_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const updateServiceGroup = (id, data) => async (dispatch) => {
    return await api.updateServiceGroup(id, data).then((response) => {
        dispatch(snackActions.success(i18next.t("service_group_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const getServiceGroupById = (id, config = {}) => async (dispatch) => {
    return await api.getServiceGroupById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_service_group_by_id_error")));
    });
}

export const deleteServiceGroup = (id) => async (dispatch) => {
    return await api.deleteServiceGroup(id).then((response) => {
        dispatch(snackActions.success(i18next.t("service_group_deleted_successfully")));
        return response
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}


