import axios from '../axiosConfig';

export function getDiagnoses(config = {}) {
    return axios.get(`/v1/diagnosis`, config);
}

export function getDiagnosesList(config = {}) {
    return axios.get(`/v1/diagnosis/list`, config);
}

export function importDiagnoses(config = {}) {
    return axios.post(`/v1/diagnosis/import`, config);
}

export function createDiagnosis(data) {
    return axios.post(`/v1/diagnosis`, data);
}

export function updateDiagnosis(id, data) {
    return axios.patch(`/v1/diagnosis/${id}`, data);
}

export function deleteDiagnosis(id) {
    return axios.delete(`/v1/diagnosis/${id}`);
}

export function getDiagnosisById(id, config = {}) {
    return axios.get(`/v1/diagnosis/${id}`, config);
}


const actions = {
    getDiagnoses,
    getDiagnosesList,
    importDiagnoses,
    createDiagnosis,
    updateDiagnosis,
    deleteDiagnosis,
    getDiagnosisById
}

export default actions;
