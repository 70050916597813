import types from './types';

const initialState = {
    documentLanguages: null,
    settings: null,
    balance: null,
    historyLoading: false,
    history: [],
    historyTotalElements: 0,
    historyParams: {
        page: 0,
        size: 10,
        timeFrom: null,
        timeTo: null,
        sort: 'dateToSend,asc'
    },
    notificationTypes: null,
    configurations: [],
    configurationsTotalElements: 0,
    configurationsParams: {
        page: 0,
        size: 10
    },
    configurationsLoading: false,
    googleFromUrl: '',
    googleFormLoading: false,
    googleForms: [],
    googleFormsTotalElements: 0,
    googleFormsParams: {
        page: 0,
        size: 10
    },
    googleCredentialsLoading: false,
    googleCredentials: [],
    googleCredentialsTotalElements: 0,
    googleCredentialsParams: {
        page: 0,
        size: 10
    },
}

export default function setting(state = initialState, action) {
    switch (action.type) {
        case types.SET_DOCUMENT_LANGUAGES:
            return {
                ...state,
                documentLanguages: action.payload
            }
        case types.SET_SETTINGS:
            return {
                ...state,
                settings: action.payload
            }
        case types.SET_BALANCE:
            return {
                ...state,
                balance: action.payload.body.balance
            }
        case types.SET_HISTORY_LOADING:
            return {
                ...state,
                historyLoading: true
            }
        case types.SET_HISTORY:
            return {
                ...state,
                history: action.payload.content,
                historyTotalElements: action.payload.totalElements ? action.payload.totalElements : 0,
                historyLoading: false
            }
        case types.SET_HISTORY_PAGE:
            return {
                ...state, historyParams: { ...state.historyParams, page: action.payload }
            }
        case types.SET_HISTORY_SIZE:
            return {
                ...state, historyParams: { ...state.historyParams, size: action.payload, page: 0 }
            }
        case types.SET_HISTORY_PARAMS:
            return {
                ...state,
                historyParams: action.payload
            }
        case types.SET_NOTIFICATION_TYPE: {
            return {
                ...state,
                notificationTypes: action.payload
            }
        }
        case types.SET_CONFIGURATIONS:
            return {
                ...state,
                configurations: action.payload.content ? action.payload.content : [],
                configurationsTotalElements: action.payload.totalElements ? action.payload.totalElements : 0,
                configurationsLoading: false
            }
        case types.SET_CONFIGURATIONS_PAGE:
            return {
                ...state, configurationsParams: { ...state.configurationsParams, page: action.payload }
            }
        case types.SET_CONFIGURATIONS_SIZE:
            return {
                ...state, configurationsParams: { ...state.configurationsParams, size: action.payload, page: 0 }
            }
        case types.SET_CONFIGURATIONS_PARAMS:
            return {
                ...state,
                configurationsParams: action.payload
            }
        case types.SET_CONFIGURATIONS_LOADING:
            return {
                ...state,
                configurationsLoading: true
            }
        case types.SET_GOOGLE_FORM_TEST_URL: {
            return {
                ...state,
                googleFromUrl: action.payload
            }
        }
        case types.GOOGLE_FORM_LOADING: {
            return {
                ...state,
                googleFormLoading: action.payload
            }
        }
        case types.SET_ALL_GOOGLE_FORMS: {
            return {
                ...state,
                googleForms: action.payload.content,
                googleFormsTotalElements: action.payload.totalElements ? action.payload.totalElements : 0,
                googleFormLoading: false
            }
        }
        case types.SET_GOOGLE_FORMS_PAGE:
            return {
                ...state, googleFormsParams: { ...state.googleFormsParams, page: action.payload }
            }
        case types.SET_GOOGLE_FORMS_SIZE:
            return {
                ...state, googleFormsParams: { ...state.googleFormsParams, size: action.payload, page: 0 }
            }
        case types.SET_GOOGLE_FORMS_PARAMS:
            return {
                ...state,
                googleFormsParams: action.payload
            }
        case types.SET_GOOGLE_CREDENTIALS_LOADING:
            return {
                ...state,
                googleCredentialsLoading: true
            }
        case types.SET_GOOGLE_CREDENTIALS:
            return {
                ...state,
                googleCredentials: action.payload.content,
                googleCredentialsTotalElement: action.payload.totalElements ? action.payload.totalElements : 0,
            }
        case types.SET_GOOGLE_CREDENTIALS_PAGE:
            return {
                ...state, googleCredentialsParams: { ...state.googleCredentialsParams, page: action.payload }
            }
        case types.SET_GOOGLE_CREDENTIALS_SIZE:
            return {
                ...state, googleCredentialsParams: { ...state.googleCredentialsParams, size: action.payload, page: 0 }
            }
        case types.SET_GOOGLE_CREDENTIALS_PARAMS:
            return {
                ...state,
                googleCredentialsParams: action.payload
            }
        default:
            return state;
    }
}