import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

class WrappedDatePicker extends Component {

    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "en"}>
                <DesktopDatePicker
                    label={this.props.t(this.props.label)}
                    id={this.props.name}
                    name={this.props.name}
                    mask="__.__.____."
                    onChange={(e) => this.props.onChange(e)}
                    value={this.props.value}
                    inputFormat={"DD.MM.YYYY."}
                    minDate={this.props.minDate ? this.props.minDate : null}
                    maxDate={this.props.maxDate ? this.props.maxDate : moment(new Date())}
                    renderInput={(params) =>
                        <TextField {...params}
                            required={this.props.required}
                            error={this.props.error}
                            helperText={this.props.helperText}
                        />}

                    leftArrowButtonText={this.props.t("previous_month")}
                    rightArrowButtonText={this.props.t("next_month")}
                />
            </LocalizationProvider>
        );
    }
}

export default withTranslation()(WrappedDatePicker);
