
import types, { SET_UNSUBSCRIBE_MESSAGE } from './types';
import api from '../../api/authApi';
import { logoutDoctor } from '../../api/infirmaryApi';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { getTodayEntries, getCalendarEvents } from '../calendar/actions';
import { getPatients } from '../patient/actions';
import { getTodayExaminations } from '../examination/actions'
import { getInfirmaryList } from '../infrimary/actions'
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';
import { POPOVER_OPEN, SET_SIGNER_EVENT, SET_SIGNER_EVENT_PROMO } from '../patient/types';
import moment from 'moment';

export const getLoggedUser = () => async (dispatch) => {
  dispatch({
    type: types.SET_LOADING_USER
  });
  await api.getLoggedUser().then(response => {
    dispatch({
      type: types.SET_LOGGED_USER,
      payload: response.data
    });
  }).catch(error => {
    dispatch({
      type: types.SET_LOGGED_USER,
      payload: null
    });
  });
}

export const onLogout = () => async (dispatch) => {
  await api.logout().then(response => {
    dispatch({
      type: types.SET_LOGGED_USER,
      payload: null
    });
    window.location.href = "/"
  }).catch(error => {
    window.location.href = "/"
  });
}

export const establishSseConnection = () => async (dispatch) => {
  const headers = {
    'Content-Type': 'text/event-stream',
    'Connection': 'keep-alive',
    'Cache-Control': 'no-cache',
    'X-Accel-Buffering': 'no'
  };
  let esp = new EventSourcePolyfill(`${process.env.REACT_APP_BASE_URL}/api/v1/sse`, {
    headers: headers,
    withCredentials: true,
    heartbeatTimeout: 24 * 60 * 60 * 1000 //24h
  });
  esp.addEventListener('calendar_entry', e => {
    if (window.location.pathname.startsWith("/calendar")) {
      let params = {}
      params.infirmaryIds = store.getState().authReducer.user.currentInfirmary.id;
      params.startTime = moment().startOf('day').add(1, 'hours')
      params.endTime = moment().endOf('day').add(1, 'hours')
      dispatch(getInfirmaryList());
      dispatch(getCalendarEvents({ params: params }))
      dispatch(snackActions.success(i18next.t("calendar_entry_change")));
    }
    if (window.location.pathname.startsWith("/dashboard")) {
      dispatch(getTodayEntries());
      dispatch(snackActions.success(i18next.t("calendar_entry_change")));
    }
  })
  esp.addEventListener('patient_status_event', e => {
    dispatch(getTodayEntries());
    dispatch(snackActions.success(i18next.t("patient_status_changed")));
  })
  esp.addEventListener('examination', e => {
    dispatch(getTodayExaminations());
    dispatch(snackActions.success(i18next.t("finish_examination_for_patient")));
  })
  esp.addEventListener('infirmary_occupation_change', e => {
    if (window.location.pathname.startsWith("/dashboard")) {
      dispatch(getTodayEntries());
      dispatch(snackActions.success(i18next.t("infirmary_occupation_change")));
    }
    if (window.location.pathname.startsWith("/choose-infirmary")) {
      dispatch(getInfirmaryList());
    }
  })
  esp.addEventListener('signer_event', e => {
    if (window.location.pathname.startsWith("/patients")) {
      dispatch({
        type: SET_SIGNER_EVENT,
        payload: JSON.parse(e.data)
      })
    }
  })
  esp.addEventListener('signer_event_promo', e => {
    if (window.location.pathname.startsWith("/patients")) {
      dispatch({
        type: SET_SIGNER_EVENT_PROMO,
        payload: JSON.parse(e.data)
      })
    }
  })
  esp.addEventListener('close_signer_event', e => {
    if (window.location.pathname.startsWith("/patients")) {
      dispatch({
        type: SET_SIGNER_EVENT,
        payload: null
      })
    }
  })
  esp.addEventListener('gdpr_signed_event', e => {
    if (window.location.pathname.startsWith("/patients")) {
      dispatch({
        type: SET_SIGNER_EVENT,
        payload: null
      })
      dispatch({
        type: POPOVER_OPEN,
        payload: false
      })
      dispatch(getPatients());
    }
  })
  esp.onerror = () => {
    esp.close();
  }
}

export const updateInfirmaryForLogout = (id) => async (dispatch) => {
  await logoutDoctor(id).then(() => {
    dispatch(onLogout());
  }).catch(() => {
  });
  return Promise.resolve();
}

export const unsubscribe = (googleFormId) => async (dispatch) => {
  await api.unsubscribe(googleFormId).then(response => {
    if (response.status === 200) {
      dispatch({
        type: SET_UNSUBSCRIBE_MESSAGE,
        payload: i18next.t("successfully_unsubscribe_email")
      })
    }
  }).catch(error => {
    dispatch({
      type: SET_UNSUBSCRIBE_MESSAGE,
      payload: i18next.t("error_unsubscribe_email")
    })
  });
}