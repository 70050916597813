import types from './types';

const initialState = {
    serviceGroupsLoading: false,
    serviceGroups: [],
    serviceGroupsTotalElements: 0,
    serviceGroupsParams: {
        page: 0,
        size: 10,
        sort: 'name,asc'
    },
    serviceGroupsList: []
}

export default function serviceGroup(state = initialState, action) {
    switch (action.type) {
        case types.SET_SERVICE_GROUPS_LOADING:
            return {
                ...state,
                serviceGroupsLoading: true
            }
        case types.SET_SERVICE_GROUPS:
            return {
                ...state,
                serviceGroups: action.payload.content,
                serviceGroupsTotalElements: action.payload ? action.payload.totalElements : 0,
                serviceGroupsLoading: false
            }
        case types.SET_SERVICE_GROUPS_PAGE:
            return {
                ...state, serviceGroupsParams: { ...state.serviceGroupsParams, page: action.payload }
            }
        case types.SET_SERVICE_GROUPS_SIZE:
            return {
                ...state, serviceGroupsParams: { ...state.serviceGroupsParams, size: action.payload, page: 0 }
            }
        case types.SET_SERVICE_GROUPS_PARAMS:
            return {
                ...state,
                serviceGroupsParams: action.payload
            }
        case types.SET_SERVICE_GROUPS_LIST:
            return {
                ...state,
                serviceGroupsList: action.payload
            }
        case types.RESET_SERVICE_GROUP_DATA:
            return {
                ...initialState,
                serviceGroupsParams: {
                    size: 10,
                    page: 0,
                    sort: 'name,asc'
                }
            }
        default:
            return state;
    }
}