import React, { Component } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Slide, IconButton, Grid } from '@mui/material'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import { Close, Check, Clear } from '@mui/icons-material'
import SignatureCanvas from 'react-signature-canvas'
import { Document, Page, pdfjs } from 'react-pdf';
import GDPR from '../../assets/document/GDPR.pdf'
import GDPRPromo from '../../assets/document/GDPR_new.pdf'
import { withTranslation } from 'react-i18next';
import { sendSignature } from '../../redux/patient/actions'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let sigPad = {}

class GDPRmodal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            trimmedDataURL: null,
            setIsSigned: false
        };
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ pageNumber: numPages })
    }

    clear = () => {
        this.setState({ setIsSigned: false })
        sigPad.clear()
    }

    trim = () => {
        this.setState({
            trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL('image/png')
        }, () => this.props.sendSignature(this.props.patientReducer.patientId, { img: sigPad.getTrimmedCanvas().toDataURL('image/png'), promo: this.props.patientReducer.gdprPromo }).then(() => this.setState({ setIsSigned: false })))
    }

    render() {
        return (
            <BootstrapDialog
                fullScreen
                aria-labelledby="customized-dialog-title"
                open={this.props.patientReducer.patientId ? true : false}
                TransitionComponent={Transition}
            >
                <DialogContent>
                    <Grid container direction="row" spacing={2} style={{ overflow: 'hidden' }}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container direction="row" spacing={2}>
                                {this.props.patientReducer.gdprPromo ?
                                    <Grid item container style={{ height: '78vh' }}>
                                        <Document file={GDPRPromo} onLoadSuccess={this.onDocumentLoadSuccess} >
                                            <Page size={"A4"} pageNumber={1} scale={1.25} />
                                        </Document>
                                    </Grid> :
                                    <Grid item container style={{ height: '78vh' }}>
                                        <Document file={GDPR} onLoadSuccess={this.onDocumentLoadSuccess}>
                                            <Page size={"A4"} pageNumber={1} width={500} scale={1.6} />
                                        </Document>
                                    </Grid>
                                }
                                <Grid item container>
                                    <div style={{ border: '1px solid grey', zIndex: 10 }}>
                                        <SignatureCanvas penColor='black' backgroundColor={'white'} canvasProps={{ width: 774, height: 130, className: 'sigCanvas' }} ref={(ref) => { sigPad = ref }} onEnd={() => { this.setState({ setIsSigned: true }) }} />
                                    </div>
                                </Grid>
                                <Grid item container>
                                    <Button style={{ marginRight: 'auto' }} color="secondary" size="large" className="default-button" onClick={this.clear}>
                                        <Clear />{this.props.t("clear")}
                                    </Button>
                                    <Button style={{ marginLeft: 'auto' }} color="primary" size="large" className="default-button" onClick={this.trim} disabled={!this.state.setIsSigned}>
                                        <Check />{this.props.t("save")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer
})

const mapActionsToProps = { sendSignature }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(GDPRmodal))