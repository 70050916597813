import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    IconButton,
    Autocomplete,
    InputAdornment,
    Box,
    FormControlLabel,
    Checkbox,
    FormGroup
} from '@mui/material';
import countries from './countries';
import { styled } from '@mui/material/styles';
import { Close, Check } from '@mui/icons-material'
import PropTypes from 'prop-types';
import {
    createPatient,
    updatePatient,
    getPatientById
} from '../../redux/patient/actions';
import { withTranslation } from 'react-i18next';
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import moment from 'moment';
import { hasAnyRole } from '../../utils/Security';
import { EMAIL_REGEX, FIRST_NAME_REGEX, LAST_NAME_REGEX } from '../../components/common/regex';
import NumberFormat from 'react-number-format';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { List } from "react-virtualized";
import cities from './cities.json'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class CreateOrUpdatePatient extends Component {

    emptyPatient = {
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        email: null,
        phone: '',
        gender: null,
        address: null,
        country: 'Hrvatska',
        county: null,
        city: null,
        source: null,
        recommendedBy: null,
        sendSms: true,
        sendEmail: true
    };

    constructor(props) {
        super(props);
        this.state = {
            initialPatient: JSON.parse(JSON.stringify(this.emptyPatient)),
            patient: JSON.parse(JSON.stringify(this.emptyPatient)),
            selectedDateOfBirth: null,
            selectedCounty: null,
            selectedCity: null,
            gdprModel: false,
            phonePrefix: countries.find(c => c.code === "HR").phone
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.patientToEdit && prevProps.patientToEdit !== this.props.patientToEdit) {
            hasAnyRole(["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM", "RECEPTIONIST"]) && this.props.getPatientById(this.props.patientToEdit.toString()).then(response => {
                let patientObject = {
                    ...response.data,
                    phone: response.data.phone.replace(countries.find(c => response.data.phone.startsWith("+" + c.phone)).phone, "")
                }
                this.setState({
                    patient: JSON.parse(JSON.stringify(patientObject)),
                    initialPatient: JSON.parse(JSON.stringify(patientObject)),
                    selectedCounty: response.data.county,
                    selectedCity: (response.data.country === "Hrvatska" || response.data.country === "HRVATSKA") && response.data.city ? { name: response.data.city, zipCode: cities.find(c => c.name.toUpperCase() === response.data.city.toUpperCase()) && cities.find(c => c.name.toUpperCase() === response.data.city.toUpperCase()).zipCode } : null,
                    selectedDateOfBirth: moment(response.data.dateOfBirth, "DD.MM.YYYY."),
                    phonePrefix: countries.find(c => response.data.phone.startsWith("+" + c.phone)).phone
                });
            })
        }
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            patient: JSON.parse(JSON.stringify(this.emptyPatient)),
            selectedDateOfBirth: null,
            selectedCounty: null,
            selectedCity: null,
            phonePrefix: countries.find(c => c.code === "HR").phone
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let { patient } = this.state;
        if (target.name === "country" && (target.value === "Hrvatska" || patient.country === "HRVATSKA")) {
            patient.city = null
            patient.county = null
            patient.address = null
            this.setState({ selectedCounty: null, selectedCity: null })
        }
        patient[target.name] = target.value = 0 || target.value === " " || target.value === "" ? null : target.value ? target.value.trimStart() : null;
        if (target.type === 'checkbox') {
            patient[target.name] = target.checked
        }
        this.setState({ patient: patient })
    };

    handleChangePhone = (values) => {
        const { value } = values;
        let { patient } = this.state;
        patient.phone = value.indexOf(0) === 0 ? value.substring(1) : value;
        this.setState({ patient: patient })
    }

    handleChangeDate = (date) => {
        let { patient } = this.state
        if (date !== null && date._isValid) {
            this.setState({ selectedDateOfBirth: null })
            if (moment(date).isAfter(new Date()) || moment(date).isBefore('1900-01-01', 'year')) {
                patient.dateOfBirth = null
                this.setState({ patient: patient, selectedDateOfBirth: null })
            } else {
                patient.dateOfBirth = moment(date).format("DD.MM.YYYY.")
                this.setState({ patient: patient, selectedDateOfBirth: date })
            }
        }
    }

    handleChangeCounty = (e, county) => {
        let { patient } = this.state
        if (county !== null) {
            patient.county = county
            this.setState({ selectedCounty: county, patient: patient })
        } else {
            patient.county = null
            this.setState({ selectedCounty: null, patient: patient })
        }
    }

    handleChangeCity = (e, city) => {
        let { patient, selectedCounty } = this.state
        if (city !== null) {
            patient.city = city.name
            patient.county = city.county
            selectedCounty = city.county
            this.setState({ selectedCity: city, patient: patient, selectedCounty: selectedCounty })
        } else {
            patient.city = null
            patient.county = null
            selectedCounty = null
            this.setState({ selectedCity: null, patient: patient, selectedCounty: selectedCounty })
        }
    }

    createOrUpdatePatient = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { patient } = this.state;
        let dataPatient = {
            ...patient,
            firstName: patient.firstName && patient.firstName.trim(),
            lastName: patient.lastName && patient.lastName.trim(),
            address: patient.address && patient.address.length > 0 && patient.address.trim(),
            city: patient.city && patient.city.length > 0 && patient.city.trim(),
            country: patient.country && patient.country.length > 0 && patient.country.trim(),
            recommendedBy: patient.recommendedBy && patient.recommendedBy.length > 0 && patient.recommendedBy.trim(),
            phone: "+" + this.state.phonePrefix + patient.phone
        }
        const action = this.props.patientToEdit ? this.props.updatePatient(this.props.patientToEdit, dataPatient) : this.props.createPatient(dataPatient);
        action.then((response) => {
            if (response.status !== 400) {
                this.setState({ patient: JSON.parse(JSON.stringify(this.emptyPatient)), selectedDateOfBirth: null, selectedCounty: null, selectedCity: null });
                this.props.onSave(this.props.patientToEdit ? response.data.id : response.data)
            }
        })
    };

    validateForm() {
        let { patient, initialPatient } = this.state;
        let valid = true;
        if (!FIRST_NAME_REGEX.test(patient.firstName)) {
            valid = false;
        }
        if (!LAST_NAME_REGEX.test(patient.lastName)) {
            valid = false;
        }
        if (patient.phone.length > 16 || patient.phone.length <= 7) {
            valid = false;
        }
        if (patient.email && !EMAIL_REGEX.test(patient.email)) {
            valid = false;
        }
        if (JSON.stringify(patient) === JSON.stringify(initialPatient)) {
            valid = false
        }
        if (patient.address && patient.address.length > 255) {
            valid = false;
        }
        if (patient.city && patient.city.length > 255) {
            valid = false;
        }
        if (patient.country && patient.country.length > 255) {
            valid = false;
        }
        if (patient.recommendedBy && patient.recommendedBy.length > 255) {
            valid = false;
        }
        if (!patient.firstName
            || !patient.lastName
            || !patient.dateOfBirth
            || !patient.gender
            || !patient.phone
            || patient.dateOfBirth === "Invalid date"
        ) {
            valid = false;
        }
        return valid;
    }

    getNameHelperTextFirstName() {
        let { patient } = this.state;
        if (!patient.firstName) {
            return this.props.t("required_field_message");
        }
        if (patient.firstName.length > 0 && !FIRST_NAME_REGEX.test(patient.firstName)) {
            return this.props.t("name_regex_message", { min: 1, max: 50 });
        }
    }

    getNameHelperTextLastName() {
        let { patient } = this.state;
        if (!patient.lastName) {
            return this.props.t("required_field_message");
        }
        if (patient.lastName.length > 0 && !LAST_NAME_REGEX.test(patient.lastName)) {
            return this.props.t("name_regex_message", { min: 1, max: 50 });
        }
    }

    getEmailHelperText() {
        let { patient } = this.state;
        if (patient.email && !EMAIL_REGEX.test(patient.email)) {
            return this.props.t("email_regex_message");
        }
    }

    handleChangePhonePrefix = (event) => {
        this.setState({ phonePrefix: event.target.value })
    }

    render() {

        let { patient, selectedDateOfBirth, selectedCounty, selectedCity } = this.state
        const { genders, sources, counties } = this.props.patientReducer;

        const ListboxComponent = React.forwardRef(function ListboxComponent(
            props,
            ref
        ) {
            const { children, role, ...other } = props;
            const itemCount = Array.isArray(children) ? children.length : 0;
            const itemSize = 45;

            return (
                <div ref={ref}>
                    <div {...other}>
                        <List
                            height={400}
                            width={555}
                            rowHeight={itemSize}
                            overscanCount={5}
                            rowCount={itemCount}
                            rowRenderer={props => {
                                return React.cloneElement(children[props.index], {
                                    style: props.style
                                });
                            }}
                            role={role}
                        />
                    </div>
                </div>
            );
        });

        return (<>
            <BootstrapDialog
                onClose={this.closeDialog}
                open={this.props.isOpen}
                TransitionComponent={Transition}>
                <BootstrapDialogTitle onClose={this.closeDialog}>
                    {this.props.patientToEdit ? this.props.t("edit_patient") : this.props.t("new_patient")}
                </BootstrapDialogTitle>
                <form onSubmit={this.createOrUpdatePatient} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Grid item container>
                                            <TextField
                                                label={this.props.t("first_name")}
                                                name="firstName"
                                                value={(patient.firstName && patient.firstName.charAt(0).toUpperCase() + patient.firstName.slice(1)) || ''}
                                                onChange={this.handleChange}
                                                required
                                                autoFocus
                                                error={!FIRST_NAME_REGEX.test(patient.firstName) || patient.firstName === null || patient.firstName === ''}
                                                helperText={this.getNameHelperTextFirstName()}
                                                inputProps={{
                                                    autoComplete: 'off',
                                                    spellCheck: false
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Grid item container>
                                            <TextField
                                                label={this.props.t("last_name")}
                                                name="lastName"
                                                value={(patient.lastName && patient.lastName.charAt(0).toUpperCase() + patient.lastName.slice(1)) || ''}
                                                onChange={this.handleChange}
                                                required
                                                error={!LAST_NAME_REGEX.test(patient.lastName) || patient.lastName === null || patient.lastName === ''}
                                                helperText={this.getNameHelperTextLastName()}
                                                inputProps={{
                                                    autoComplete: 'off',
                                                    spellCheck: false
                                                }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Grid item container>
                                            <WrappedDatePicker
                                                label="date_of_birth"
                                                name="dateOfBirth"
                                                disableFuture
                                                onChange={this.handleChangeDate}
                                                value={selectedDateOfBirth ? Date.parse(selectedDateOfBirth) : null}
                                                error={patient.dateOfBirth === "Invalid date" || patient.dateOfBirth === null}
                                                helperText={(patient.dateOfBirth === "Invalid date" || patient.dateOfBirth === null) && this.props.t("required_field_message")}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Grid item container>
                                            <FormControl required error={!patient.gender}>
                                                <InputLabel id="select-label">{this.props.t("gender")}</InputLabel>
                                                <Select
                                                    labelId="select-label"
                                                    value={patient.gender || ''}
                                                    label={this.props.t("gender")}
                                                    name="gender"
                                                    onChange={this.handleChange}>
                                                    {genders.map(gender => { return <MenuItem key={gender} value={gender}>{this.props.t(gender)}</MenuItem> })}
                                                </Select>
                                                <FormHelperText>{!patient.gender && this.props.t("required_field_message")}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("email")}
                                            name="email"
                                            value={patient.email || ''}
                                            onChange={this.handleChange}
                                            error={patient.email && !EMAIL_REGEX.test(patient.email)}
                                            helperText={this.getEmailHelperText()}
                                            inputProps={{
                                                autoComplete: 'off',
                                                spellCheck: false
                                            }}
                                        />
                                    </Grid>
                                    <Grid item container>
                                        <NumberFormat
                                            required
                                            id="phone"
                                            name="phone"
                                            label={this.props.t("phone")}
                                            decimalScale={0}
                                            onValueChange={this.handleChangePhone}
                                            value={patient.phone || ''}
                                            customInput={TextField}
                                            format="## ### ### ### #"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <Select
                                                        labelId="select-label"
                                                        value={this.state.phonePrefix}
                                                        name="phonePrefix"
                                                        sx={{ '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' } }}
                                                        onChange={this.handleChangePhonePrefix}>
                                                        {countries.map(country => {
                                                            return <MenuItem key={country.code} value={country.phone}>
                                                                <Box sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                                                        srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                    />
                                                                    {country.code} +{country.phone}
                                                                </Box>
                                                            </MenuItem>
                                                        })}
                                                    </Select>
                                                </InputAdornment>
                                            }}
                                            error={patient.phone.length > 7 ? false : true}
                                            helperText={!patient.phone && this.props.t("required_field_message")}
                                        />
                                    </Grid>

                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("country")}
                                            name="country"
                                            value={(patient.country && patient.country.charAt(0).toUpperCase() + patient.country.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            error={patient.country && patient.country.length > 255}
                                            helperText={patient.country && patient.country.length > 255 && this.props.t("number_regex_message", { max: 255 })}
                                            inputProps={{
                                                autoComplete: 'off',
                                                spellCheck: false
                                            }} />
                                    </Grid>
                                    <Grid item container>
                                        {(patient.country === "Hrvatska" || patient.country === "HRVATSKA") ? <FormControl>
                                            <Autocomplete
                                                label={this.props.t("city")}
                                                placeholder={this.props.t("city")}
                                                name="selectedCity"
                                                disableListWrap
                                                value={selectedCity}
                                                options={selectedCounty !== null ? cities.filter(c => selectedCounty === c.county) : cities}
                                                ListboxComponent={ListboxComponent}
                                                getOptionLabel={(option) => option ? option.name + " (" + option.zipCode + ")" : ""}
                                                onChange={(event, city) => {
                                                    this.handleChangeCity(event, city)
                                                }}
                                                clearText={this.props.t("clear")}
                                                closeText={this.props.t("close")}
                                                openText={this.props.t("open")}
                                                renderInput={(params) => <TextField {...params} label={this.props.t("city")} inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', spellCheck: false
                                                }} />
                                                }
                                            />
                                        </FormControl> :
                                            <TextField
                                                label={this.props.t("city")}
                                                name="city"
                                                value={patient.city || ''}
                                                onChange={this.handleChange}
                                                error={patient.city && patient.city.length > 255}
                                                helperText={patient.city && patient.city.length > 255 && this.props.t("number_regex_message", { max: 255 })}
                                                inputProps={{
                                                    autoComplete: 'off',
                                                    spellCheck: false
                                                }} />}

                                    </Grid>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("address")}
                                            name="address"
                                            value={(patient.address && patient.address.charAt(0).toUpperCase() + patient.address.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            error={patient.address && patient.address.length > 255}
                                            helperText={patient.address && patient.address.length > 255 && this.props.t("number_regex_message", { max: 255 })}
                                            inputProps={{
                                                autoComplete: 'off',
                                                spellCheck: false
                                            }} />
                                    </Grid>

                                    {(patient.country === "Hrvatska" || patient.country === "HRVATSKA") && <Grid item container>
                                        <FormControl>
                                            <WrappedAutocomplete
                                                label={this.props.t("county")}
                                                placeholder={this.props.t("select_county")}
                                                name="selectedCounty"
                                                value={selectedCounty}
                                                options={counties}
                                                onChange={(event, county) => {
                                                    this.handleChangeCounty(event, county)
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>}
                                    <Grid item container>
                                        <FormControl>
                                            <InputLabel>{this.props.t("source")}</InputLabel>
                                            <Select
                                                value={patient.source || ''}
                                                label={this.props.t("source")}
                                                name="source"
                                                onChange={this.handleChange}>
                                                {sources.map(data => { return <MenuItem key={data} value={data}>{this.props.t(data)}</MenuItem> })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {patient.source === "IN_PERSON" && <Grid item container>
                                        <TextField
                                            label={this.props.t("recommended_by")}
                                            name="recommendedBy"
                                            value={(patient.recommendedBy && patient.recommendedBy.charAt(0).toUpperCase() + patient.recommendedBy.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            error={patient.recommendedBy && patient.recommendedBy.length > 255}
                                            helperText={patient.recommendedBy && patient.recommendedBy.length > 255 && this.props.t("number_regex_message", { max: 255 })}
                                            inputProps={{
                                                autoComplete: 'off',
                                                spellCheck: false
                                            }} />
                                    </Grid>}
                                    <Grid item container direction="column" xs={12} sm={6} md={6} lg={4} xl={4}>
                                        <FormControl>
                                            <FormGroup aria-label="position" row>
                                                <FormControlLabel
                                                    style={{ marginLeft: '0px' }}
                                                    name="sendSms"
                                                    control={<Checkbox
                                                        onChange={this.handleChange}
                                                        checked={patient.sendSms || false} />
                                                    }
                                                    label={this.props.t("send_sms")}
                                                    labelPlacement="start"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    {this.props.patientToEdit && <Grid item container direction="column" xs={12} sm={6} md={6} lg={4} xl={4}>
                                        <FormControl>
                                            <FormGroup aria-label="position" row>
                                                <FormControlLabel
                                                    style={{ marginLeft: '0px' }}
                                                    name="sendEmail"
                                                    control={<Checkbox
                                                        onChange={this.handleChange}
                                                        checked={patient.sendEmail || false} />
                                                    }
                                                    label={this.props.t("send_email")}
                                                    labelPlacement="start"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <DialogActions>
                        <Button color="secondary" className="default-button" onClick={this.closeDialog} >
                            <Close /> {this.props.t("close")}
                        </Button>
                        <Button type="submit" color="primary" className="default-button" disabled={!this.validateForm()} >
                            <Check /> {this.props.patientToEdit ? this.props.t("update") : this.props.t("create")}
                        </Button>
                    </DialogActions>}
                </form>
            </BootstrapDialog>
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer
})

const mapActionsToProps = { createPatient, updatePatient, getPatientById }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateOrUpdatePatient)))
