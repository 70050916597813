export const SET_PATIENTS_LOADING = 'SET_PATIENTS_LOADING';
export const SET_PATIENTS = 'SET_PATIENTS';
export const SET_GENDERS = 'SET_GENDERS'
export const SET_SOURCES = 'SET_SOURCES'
export const SET_PATIENT_LIST = 'SET_PATIENT_LIST'
export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENT'
export const SET_PATIENTS_PAGE = 'SET_PATIENTS_PAGE'
export const SET_PATIENTS_SIZE = 'SET_PATIENTS_SIZE'
export const SET_PATIENTS_PARAMS = 'SET_PATIENTS_PARAMS'
export const SET_SIGNER_EVENT = 'SET_SIGNER_EVENT'
export const SET_SIGNER_EVENT_PROMO = 'SET_SIGNER_EVENT_PROMO'
export const RESET_PATIENT_DATA = 'RESET_PATIENT_DATA'
export const SET_COUNTIES = 'SET_COUNTIES'
export const SET_LOADING_IMPORT_FILES = 'SET_LOADING_IMPORT_FILES'
export const SET_PATIENT_FILES = 'SET_PATIENT_FILES'
export const POPOVER_OPEN = 'POPOVER_OPEN'

const types = {
    SET_PATIENTS_LOADING,
    SET_PATIENTS,
    SET_GENDERS,
    SET_SOURCES,
    SET_PATIENT_LIST,
    SET_CURRENT_PATIENT,
    SET_PATIENTS_PAGE,
    SET_PATIENTS_SIZE,
    SET_PATIENTS_PARAMS,
    SET_SIGNER_EVENT,
    SET_SIGNER_EVENT_PROMO,
    RESET_PATIENT_DATA,
    SET_COUNTIES,
    SET_LOADING_IMPORT_FILES,
    SET_PATIENT_FILES,
    POPOVER_OPEN
};

export default types;