import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit, Close, Delete, Check, Add } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getPatientList, getGenders, getSources, getCounties } from '../../redux/patient/actions'
import { createCalendarEvent, updateCalendarEvent, getCalendarEntryById, updateCalendarEventStatus, deleteCalendarEntry, changePatientInfirmary } from '../../redux/calendar/actions'
import CreateOrUpdatePatient from '../patient/CreateOrUpdatePatient';
import WrappedDateTimePicker from '../../components/common/WrappedDateTimePicker';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import NumberFormat from 'react-number-format';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
class CreateOrUpdateEvent extends Component {

    emptyCalendarEntry = {
        startTime: null,
        endTime: null,
        patientId: null,
        infirmaryId: null,
        comment: '',
        priceEstimate: null
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            newPatientDialog: false,
            selectPatient: null,
            startTime: null,
            endTime: null,
            calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
            initialCalendarEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
            selectedStartTime: null,
            selectedEndTime: null,
            patientToEdit: null,
            selectedInfirmaryToChange: null,
            infirmarySelected: null
        };
    }

    componentDidMount() {
        this.getPatientList()
        this.props.getGenders()
        this.props.getSources()
        this.props.getCounties()
    }

    componentDidUpdate(prevProps) {
        if (this.props.calendarEventToEdit && prevProps.calendarEventToEdit !== this.props.calendarEventToEdit) {
            this.props.getCalendarEntryById(this.props.calendarEventToEdit.id.toString()).then(response => {
                this.setState({
                    calendaryEntry: JSON.parse(JSON.stringify(response.data)),
                    initialCalendarEntry: JSON.parse(JSON.stringify(response.data)),
                    selectPatient: this.props.patientReducer.patientList.find(p => p.id === response.data.patientId),
                    selectedStartTime: moment(response.data.startTime),
                    selectedEndTime: moment(response.data.endTime),
                    infirmarySelected: response.data.infirmaryId
                })
            })
        }
    }

    getPatientList = (patientId) => {
        this.props.getPatientList().then(() => {
            this.setState({ selectPatient: patientId ? this.props.patientReducer.patientList.find(e => e.id === patientId) : null })
        })
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({ selectPatient: null, selectedStartTime: null, selectedEndTime: null, calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)), selectedInfirmaryToChange: null })
    }

    closeDialogPatient = () => {
        this.setState({ newPatientDialog: false, patientToEdit: null })
    }

    handleChangePatient = (e, patient) => {
        let { calendaryEntry } = this.state
        if (patient !== null) {
            calendaryEntry.patientId = patient.id
            this.setState({ selectPatient: patient, calendaryEntry: calendaryEntry })
        } else {
            calendaryEntry.patientId = null
            this.setState({ selectPatient: null, calendaryEntry: calendaryEntry })
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { calendaryEntry } = this.state;
        calendaryEntry[target.name] = target.value.trimStart()
        this.setState({ calendaryEntry: calendaryEntry })
    }

    handleChangeStartTime = (date) => {
        let { calendaryEntry } = this.state
        if (date !== null && date._isValid) {
            if (date === null && this.state.selectedStartTime !== null) {
                return
            }
            if (moment(date).isBefore(new Date())) {
                calendaryEntry.startTime = new Date()
                this.setState({ calendaryEntry: calendaryEntry, selectedStartTime: new Date() })
            } else {
                calendaryEntry.startTime = moment(date)
                this.setState({ calendaryEntry: calendaryEntry, selectedStartTime: date });
            }
        } else {
            calendaryEntry.startTime = "Invalid date"
            this.setState({ calendaryEntry: calendaryEntry, selectedStartTime: date });
        }
    }

    handleChangeEndTime = (date) => {
        let { calendaryEntry } = this.state
        if (date !== null && date._isValid) {
            if (date === null && this.state.selectedEndTime !== null) {
                return
            }
            if (moment(date).isBefore(new Date())) {
                calendaryEntry.endTime = new Date()
                this.setState({ calendaryEntry: calendaryEntry, selectedEndTime: new Date() })
            } else {
                calendaryEntry.endTime = moment(date)
                this.setState({ calendaryEntry: calendaryEntry, selectedEndTime: date });
            }
        } else {
            calendaryEntry.endTime = "Invalid date"
            this.setState({ calendaryEntry: calendaryEntry, selectedEndTime: date });
        }
    }

    createCalendarEvent = (e) => {
        e.preventDefault()
        let { calendaryEntry, selectPatient } = this.state
        let { infirmary, selectedSlot, calendarEventToEdit } = this.props
        let body = {
            ...calendaryEntry,
            infirmaryId: calendarEventToEdit ? calendarEventToEdit.infirmary.id : infirmary[0].id,
            startTime: calendarEventToEdit ? calendaryEntry.startTime : selectedSlot.start,
            endTime: calendarEventToEdit ? calendaryEntry.endTime : selectedSlot.end,
            comment: calendaryEntry.comment && calendaryEntry.comment.toUpperCase(),
            patientId: selectPatient.id
        }
        const action = this.props.calendarEventToEdit ?
            this.props.updateCalendarEvent(this.props.calendarEventToEdit.id, body) :
            this.props.createCalendarEvent(body);
        action.then((response) => {
            this.props.calendarEventToEdit && this.state.selectedInfirmaryToChange !== null && this.changePatientInfirmary()
            if (response.status !== 400) {
                this.props.onSave()
                this.setState({ calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)), selectPatient: null, selectedInfirmaryToChange: null });
            }
        })
    }

    deleteCalendarEntry = () => {
        this.props.deleteCalendarEntry(this.props.calendarEventToEdit.id).then((response) => {
            if (response.status !== 400) {
                this.setState({ calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)), selectPatient: null, selectedInfirmaryToChange: null });
                this.props.onSave();
            }
        })
    }

    validationForm = () => {
        let { calendaryEntry, initialCalendarEntry, selectPatient, selectedInfirmaryToChange } = this.state;
        const { calendarEventToEdit } = this.props
        let valid = true;
        if (calendaryEntry.comment && calendaryEntry.comment.length > 500) {
            valid = false
        }
        if (JSON.stringify(calendaryEntry) === JSON.stringify(initialCalendarEntry)) {
            if (calendarEventToEdit !== null && selectedInfirmaryToChange === null) {
                valid = false
            }
        }
        if (selectPatient === null) {
            valid = false
        }

        if (calendarEventToEdit) {
            if (!calendaryEntry.startTime || !calendaryEntry.endTime || calendaryEntry.startTime === "Invalid date" || calendaryEntry.endTime === "Invalid date") {
                valid = false
            }
        }
        return valid;
    }

    getCommentHelperText() {
        let { calendaryEntry } = this.state;
        if (!calendaryEntry.comment.trim()) {
            return this.props.t("required_field_message");
        }
        if (calendaryEntry.comment.length > 0 && calendaryEntry.comment.length > 500) {
            return this.props.t("name_regex_message", { min: 1, max: 500 });
        }
    }

    disabledOption = (id) => {
        let { infirmarySelected } = this.state
        if (infirmarySelected === id) {
            return true
        }
        return false;
    }

    handleChangeInfirmary = (e, infirmary) => {
        if (infirmary !== null) {
            this.setState({ selectedInfirmaryToChange: infirmary })
        } else {
            this.setState({ selectedInfirmaryToChange: null })
        }
    }

    changePatientInfirmary = () => {
        let params = {}
        params.infirmaryId = this.state.selectedInfirmaryToChange.id
        this.props.changePatientInfirmary(this.props.calendarEventToEdit.id, { params: params }).then((response) => {
            if (response.status !== 400) {
                this.props.onSave()
                this.setState({ calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)), selectPatient: null, selectedInfirmaryToChange: null });
            }
        })
    }

    handleChangePrice = (values) => {
        const { floatValue } = values;
        let { calendaryEntry } = this.state
        calendaryEntry.priceEstimate = floatValue;
        this.setState({ calendaryEntry: calendaryEntry })
    }

    render() {
        const { selectPatient, calendaryEntry, selectedStartTime, selectedEndTime, selectedInfirmaryToChange } = this.state
        const { calendarEventToEdit, infirmary, } = this.props
        const { patientList } = this.props.patientReducer
        const { infirmaryList } = this.props.infirmaryReducer

        let infirmaryName = infirmary.length > 0 && infirmary[0].name

        return (
            <>
                <BootstrapDialog
                    onClose={this.closeDialog}
                    open={this.props.isOpen}
                    fullWidth
                    maxWidth={"md"}
                    TransitionComponent={Transition}>
                    <BootstrapDialogTitle onClose={this.closeDialog}>
                        <span>
                            {calendarEventToEdit ? this.props.t("edit_event") + " (" + ((calendarEventToEdit && calendarEventToEdit.infirmary.name)) + ") " : this.props.t("new_event") + " (" + (infirmaryName) + ")"}
                        </span>
                    </BootstrapDialogTitle>
                    <form onSubmit={this.createCalendarEvent} autoComplete="off" spellCheck="false">
                        <DialogContent>
                            <Grid container direction="row" spacing={2}>
                                {calendarEventToEdit && <><Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Grid item container>
                                        <WrappedDateTimePicker
                                            label="start_time"
                                            name="startTime"
                                            required
                                            minutesStep={15}
                                            onChange={this.handleChangeStartTime}
                                            disablePast
                                            value={selectedStartTime ? Date.parse(selectedStartTime) : null}
                                            error={calendaryEntry.startTime === "Invalid date" || calendaryEntry.startTime === null}
                                            helperText={(calendaryEntry.startTime === "Invalid date" || calendaryEntry.startTime === null) && this.props.t("required_field_message")}
                                        />
                                    </Grid>
                                </Grid>
                                    <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Grid item container>
                                            <WrappedDateTimePicker
                                                label="end_time"
                                                name="endTime"
                                                required
                                                disablePast
                                                minutesStep={15}
                                                onChange={this.handleChangeEndTime}
                                                value={selectedEndTime ? Date.parse(selectedEndTime) : null}
                                                error={calendaryEntry.endTime === "Invalid date" || calendaryEntry.endTime === null}
                                                helperText={(calendaryEntry.endTime === "Invalid date" || calendaryEntry.endTime === null) && this.props.t("required_field_message")}
                                            />
                                        </Grid>
                                    </Grid>
                                </>}
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item container direction="column" xs={9} sm={9} md={9} lg={9} xl={8}>
                                            <FormControl>
                                                <WrappedAutocomplete
                                                    freeSolo={true}
                                                    label={this.props.t("patient")}
                                                    placeholder={this.props.t("select_patient")}
                                                    name="selectPatient"
                                                    value={selectPatient}
                                                    getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                                    options={patientList}
                                                    onChange={(event, patient) => {
                                                        this.handleChangePatient(event, patient)
                                                    }}
                                                    disabled={calendarEventToEdit ? true : false}
                                                    required
                                                    error={!selectPatient}
                                                    helperText={!selectPatient && this.props.t("required_field_message")}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option.id} className="selected-dropdown-custom">
                                                            <div>
                                                                {option.firstName + " " + option.lastName}
                                                                <div>
                                                                    <small>{this.props.t("phone")}: {option.phone}</small>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item container direction="column" xs={2} sm={2} md={3} lg={3} xl={4}>
                                            <Grid item container>
                                                <Button color="primary" className="default-button" onClick={() => this.setState({ newPatientDialog: true, patientToEdit: selectPatient !== null ? selectPatient.id : null })} style={{ marginTop: '20px' }}>
                                                    {selectPatient !== null ? <Edit /> : <Add />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("comment")}
                                            name="comment"
                                            value={calendaryEntry.comment || ''}
                                            multiline
                                            rows={5}
                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                            onChange={this.handleChange}
                                            required
                                            error={calendaryEntry.comment.length > 500 || !calendaryEntry.comment.trim() ? true : false}
                                            helperText={this.getCommentHelperText()} />
                                    </Grid>
                                </Grid>

                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <Grid item container>
                                        <NumberFormat
                                            label={this.props.t("price_estimate")}
                                            id="priceEstimate"
                                            name="priceEstimate"
                                            customInput={TextField}
                                            decimalScale={2}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            value={calendaryEntry.priceEstimate}
                                            suffix=' €'
                                            onValueChange={(values, sourceInfo) => this.handleChangePrice(values, sourceInfo)}
                                            allowNegative={false}

                                        />
                                    </Grid>
                                </Grid>
                                {calendarEventToEdit && <Grid item container direction="column" xs={12} sm={12} md={10} lg={6} xl={6}>
                                    <WrappedAutocomplete
                                        label={this.props.t("change_to_another_infirmary")}
                                        name="selectedInfirmaryToChange"
                                        value={selectedInfirmaryToChange}
                                        getOptionLabel={(option) => option ? option.name : ""}
                                        getOptionDisabled={option => this.disabledOption(option.id)}
                                        options={infirmaryList}
                                        onChange={(event, infirmary) => {
                                            this.handleChangeInfirmary(event, infirmary)
                                        }}
                                    />
                                </Grid>}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {calendarEventToEdit && <Button className="default-button" style={{ marginRight: 'auto' }} color="primary" onClick={() => this.deleteCalendarEntry()}>
                                <Delete /> {this.props.t("delete")}
                            </Button>}
                            <Button color="secondary" className="default-button" onClick={this.closeDialog}>
                                <Close />{this.props.t("close")}
                            </Button>
                            <Button type="submit" className="default-button" color="primary" disabled={!this.validationForm()}>
                                <Check /> {calendarEventToEdit ? this.props.t("update") : this.props.t("create")}
                            </Button>
                        </DialogActions>
                    </form>
                </BootstrapDialog>
                <CreateOrUpdatePatient
                    isOpen={this.state.newPatientDialog}
                    onClose={this.closeDialogPatient}
                    patientToEdit={this.state.patientToEdit}
                    createFlag={true}
                    onSave={(patientId) => this.setState({ newPatientDialog: false, patientToEdit: null }, () => this.getPatientList(patientId))}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer,
    infirmaryReducer: state.infirmaryReducer,
})

const mapActionsToProps = { getPatientList, getGenders, getSources, getCounties, createCalendarEvent, updateCalendarEvent, getCalendarEntryById, updateCalendarEventStatus, deleteCalendarEntry, changePatientInfirmary }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateOrUpdateEvent)))
