import types from './types';

const initialState = {
    servicesLoading: false,
    services: [],
    servicesTotalElements: 0,
    servicesParams: {
        page: 0,
        size: 10,
        sort: 'code,asc',
        active: true
    },
    serviceList: [],
    isLoadingImport: false,
    servicesByDoctorId: []
}

export default function service(state = initialState, action) {
    switch (action.type) {
        case types.SET_SERVICES_LOADING:
            return {
                ...state,
                servicesLoading: true
            }
        case types.SET_SERVICES:
            return {
                ...state,
                services: action.payload.content,
                servicesTotalElements: action.payload ? action.payload.totalElements : 0,
                servicesLoading: false
            }
        case types.SET_SERVICES_PAGE:
            return {
                ...state, servicesParams: { ...state.servicesParams, page: action.payload }
            }
        case types.SET_SERVICES_SIZE:
            return {
                ...state, servicesParams: { ...state.servicesParams, size: action.payload, page: 0 }
            }
        case types.SET_SERVICES_PARAMS:
            return {
                ...state,
                servicesParams: action.payload
            }
        case types.SET_SERVICE_LIST:
            return {
                ...state,
                serviceList: action.payload
            }
        case types.SET_SERVICES_BY_DOCTOR_ID:
            return {
                ...state,
                servicesByDoctorId: action.payload
            }
        case types.SET_LOADING_IMPORT_SERVICES:
            return {
                ...state,
                isLoadingImport: action.payload
            }
        case types.RESET_SERVICE_DATA:
            return {
                ...initialState,
                servicesParams: {
                    size: 10,
                    page: 0,
                    sort: 'code,asc',
                    active: true
                }
            }
        default:
            return state;
    }
}