import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import snackbarReducer from '../redux/snackbar/reducer';
import authReducer from '../redux/auth/reducer';
import patientReducer from '../redux/patient/reducer';
import infirmaryReducer from './infrimary/reducer';
import userReducer from './user/reducer'
import serviceGroupReducer from '../redux/serviceGroup/reducer'
import serviceReducer from '../redux/service/reducer'
import diagnosisReducer from '../redux/diagnosis/reducer'
import calendarReducer from '../redux/calendar/reducer'
import examinationReducer from '../redux/examination/reducer'
import invoiceReducer from '../redux/invoice/reducer'
import scheduleReducer from '../redux/schedule/reducer'
import settingReducer from '../redux/setting/reducer'
import auditReducer from './auditLog/reducer'
import Cookies from 'js-cookie';
import { createCookieMiddleware } from 'redux-cookie';

const initialState = {};

const reducers = combineReducers({
    snackbarReducer: snackbarReducer,
    authReducer: authReducer,
    patientReducer: patientReducer,
    infirmaryReducer: infirmaryReducer,
    userReducer: userReducer,
    serviceGroupReducer: serviceGroupReducer,
    serviceReducer: serviceReducer,
    diagnosisReducer: diagnosisReducer,
    calendarReducer: calendarReducer,
    examinationReducer: examinationReducer,
    invoiceReducer: invoiceReducer,
    scheduleReducer: scheduleReducer,
    settingReducer: settingReducer,
    auditReducer: auditReducer
});

const middleware = [thunk, createCookieMiddleware(Cookies)];

const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

export default store;
