import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Button,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import './Statistics.css'
import { getAllGoogleForms, setGoogleFormsPage, setGoogleFormsSize, setGoogleFormsParams } from '../../redux/setting/actions'
import { getPatientList } from '../../redux/patient/actions'
import { Check, Clear, QuestionAnswer } from '@mui/icons-material';
import Base58Table from '../../components/common/Base58Table';
import ParamsDetailsDialog from '../auditLog/ParamsDetailsDialog';
import moment from 'moment';
import types from '../../redux/setting/types';
import store from '../../redux/store';

class DashboardStatisticsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentDidMount() {
        this.props.getAllGoogleForms()
        this.props.getPatientList()
    }

    componentWillUnmount() {
        store.dispatch({
            type: types.SET_GOOGLE_FORMS_PARAMS,
            payload: { page: 0, size: 10 }
        });
    }

    getParamDetails = (index) => {
        const { googleForms } = this.props.settingReducer;
        let selectedParams = googleForms[index].googleFormAnswer;
        if (this.isJsonString(selectedParams.rawResponse)) {
            let rawResponse = JSON.parse(selectedParams.rawResponse);
            selectedParams.rawResponse = rawResponse;
        }
        this.setState({ selectedParams: selectedParams });
    }

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    render() {

        const { googleForms, googleFormLoading, googleFormsTotalElements, googleFormsParams } = this.props.settingReducer
        const { patientList } = this.props.patientReducer

        let formData = googleForms && googleForms.map((gF, index) => {
            let patient = patientList.find(p => p.id === gF.patientId)
            return <TableRow key={index}>
                <TableCell>{patient && `${patient.firstName} ${patient.lastName} ${patient.dateOfBirth}, ${patient.phone}, ${patient.email}${patient.city === null ? "" : ", " + patient.city}`}</TableCell>
                <TableCell>{moment.unix(gF.createdDate).format("DD.MM.YYYY. HH:mm:ss")}</TableCell>
                <TableCell align='center'>{gF.responseCollected ? <Check style={{ color: '#22AE73' }} /> : <Clear style={{ color: '#F44812' }} />}</TableCell>
                <TableCell align='center'>
                    {gF.responseCollected && <Button title={this.props.t("answers")} className="default-button" color="primary" onClick={() => this.setState({ isOpen: true }, () => this.getParamDetails(index))}>
                        <QuestionAnswer />
                        {this.props.t("answers")}
                    </Button>}
                </TableCell>
            </TableRow>
        })

        let columns = [
            {
                name: "patient",
                width: '50%'
            },
            {
                name: "created_date",
                dbName: "createdDate",
                width: '20%',
                sort: true
            },
            {
                name: "response_collected",
                dbName: 'responseCollected',
                width: '15%',
                filter: true,
                type: 'boolean-select'
            },
            {
                name: "googleFormAnswer",
                width: '15%'
            }
        ]

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6">{this.props.t("patient_satisfaction_questionnaire")}</Typography>
                    <Base58Table
                        isLoading={googleFormLoading}
                        columns={columns}
                        data={formData}
                        count={googleFormsTotalElements}
                        rowsPerPage={googleFormsParams.size}
                        page={googleFormsParams.page}
                        onPageChange={(e, page) => this.props.setGoogleFormsPage(page)}
                        onRowsPerPageChange={(e) => this.props.setGoogleFormsSize(e.target.value)}
                        onFilterChange={(params) => this.props.setGoogleFormsParams(params)}
                    />
                </Grid>
                <ParamsDetailsDialog
                    isOpen={this.state.isOpen}
                    title={"answers_details"}
                    onClose={() => this.setState({ isOpen: false })}
                    closeButtonTitle={this.props.t("close")}
                    selectedParams={this.state.selectedParams}
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer,
    patientReducer: state.patientReducer
})

export default connect(mapStateToProps, { getAllGoogleForms, getPatientList, setGoogleFormsPage, setGoogleFormsSize, setGoogleFormsParams })(withTranslation()(DashboardStatisticsForm))