import axios from '../axiosConfig';

export function getLoggedUser(config = {}) {
    return axios.get('/v1/user/info', config);
}

export function logout(config = {}) {
    return axios.get(`/logout`, config);
}

export function unsubscribe(googleFormId, config = {}) {
    return axios.get(`/v1/notifications/public/email-unsubscribe/${googleFormId}`, config);
}

const actions = {
    getLoggedUser,
    logout,
    unsubscribe
}

export default actions;