import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    FormHelperText,
    OutlinedInput,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Check } from '@mui/icons-material'
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { createInvoice, updateInvoice, getInvoiceById, getReferenceNumber } from '../../redux/invoice/actions';
import { INVOICE_REF_NUMBER_REGEX } from '../../components/common/regex';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class CreateOrUpdateInvoice extends Component {

    emptyInvoice = {
        invoiceReference: '',
        invoiceType: null,
        examinationId: null
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            initialInvoice: JSON.parse(JSON.stringify(this.emptyInvoice)),
            invoice: JSON.parse(JSON.stringify(this.emptyInvoice)),
            invoiceReferenceNumber: ''
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.invoiceToEdit && prevProps.invoiceToEdit !== this.props.invoiceToEdit) {
            this.props.getInvoiceById(this.props.invoiceToEdit).then(response => {
                this.setState({
                    invoice: JSON.parse(JSON.stringify(response.data)),
                    initialInvoice: JSON.parse(JSON.stringify(response.data)),
                });
            })
        }
        if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
            if (!this.props.invoiceToEdit && prevProps.invoiceToEdit === this.props.invoiceToEdit) {
                this.getReferenceNumber()
            }
        }
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            invoice: JSON.parse(JSON.stringify(this.emptyInvoice))
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let { invoice } = this.state;
        invoice[target.name] = target.value.trimStart();
        this.setState({ invoice: invoice })
    };

    createOrUpdateInvoice = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { invoice } = this.state;
        invoice.examinationId = this.props.examinationId
        const action = this.props.invoiceToEdit ?
            this.props.updateInvoice(this.props.invoiceToEdit, invoice) :
            this.props.createInvoice(invoice);
        action.then((response) => {
            if (response.status !== 400) {
                this.setState({ invoice: JSON.parse(JSON.stringify(this.emptyInvoice)) });
                this.props.onSave();
            }
        })
    };

    validateForm() {
        let { invoice, initialInvoice } = this.state;
        let valid = true;
        if (!INVOICE_REF_NUMBER_REGEX.test(invoice.invoiceReference)) {
            valid = false;
        }
        if (JSON.stringify(invoice) === JSON.stringify(initialInvoice)) {
            valid = false
        }
        if (!invoice.invoiceReference || !invoice.invoiceType
        ) {
            valid = false;
        }
        return valid;
    }

    getNameHelperTextRefNumber() {
        let { invoice } = this.state;
        if (!invoice.invoiceReference.trim()) {
            return this.props.t("required_field_message");
        }
        if (invoice.invoiceReference.length > 0 && !INVOICE_REF_NUMBER_REGEX.test(invoice.invoiceReference)) {
            return this.props.t("name_regex_message", { min: 1, max: 30 });
        }
    }

    getReferenceNumber = () => {
        this.props.getReferenceNumber().then(response => {
            let { invoice } = this.state
            invoice.invoiceReference = response.data
            this.setState({ invoice: invoice });
        })
    }

    handleResetReferenceNumber = () => {
        let { invoice } = this.state;
        !this.props.invoiceToEdit ? this.getReferenceNumber() : invoice.invoiceReference = this.state.invoiceReferenceNumber
        this.setState({ invoice: invoice })
    }

    render() {
        let { invoice } = this.state
        let { invoiceTypes } = this.props.invoiceReducer

        return (
            <BootstrapDialog
                onClose={this.closeDialog}
                open={this.props.isOpen}
                TransitionComponent={Transition}
                fullWidth
            >
                <BootstrapDialogTitle onClose={this.closeDialog}>
                    {this.props.invoiceToEdit ? this.props.t("edit_offer") : this.props.t("new_offer")}
                </BootstrapDialogTitle>
                <form onSubmit={this.createOrUpdateInvoice} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container>
                                        <FormControl fullWidth>
                                            <InputLabel margin="dense">{this.props.t("offer_reference")}</InputLabel>
                                            <OutlinedInput
                                                name="invoiceReference"
                                                label={this.props.t("offer_reference")}
                                                id="invoiceReference"
                                                value={invoice.invoiceReference}
                                                onChange={this.handleChange}
                                                required
                                                disabled={this.props.invoiceToEdit ? true : false}
                                                error={!INVOICE_REF_NUMBER_REGEX.test(invoice.invoiceReference) || !invoice.invoiceReference.trim()}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item container>
                                        <FormControl required error={!invoice.invoiceType}>
                                            <InputLabel id="invoiceType">{this.props.t("select_payment_method")}</InputLabel>
                                            <Select
                                                id="invoiceType"
                                                name="invoiceType"
                                                defaultValue=""
                                                value={invoice.invoiceType || ""}
                                                onChange={this.handleChange}
                                                label={this.props.t("select_payment_method")}>
                                                {invoiceTypes && invoiceTypes.map((type, index) => {
                                                    return <MenuItem key={index} value={type}>{this.props.t(type)}</MenuItem>
                                                })}
                                            </Select>
                                            <FormHelperText>{!invoice.invoiceType && this.props.t("required_field_message")}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" className="default-button" onClick={this.closeDialog} >
                            <Close /> {this.props.t("close")}
                        </Button>
                        <Button style={{ height: '32px' }} type="submit" color="primary" className="default-button" disabled={!this.validateForm()}>
                            {this.props.invoiceReducer.loadingCreate ? <CircularProgress
                                size={20}
                                color="secondary"
                            /> :
                                <><Check /> {this.props.invoiceToEdit ? this.props.t("update") : this.props.t("create")}</>
                            }
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    invoiceReducer: state.invoiceReducer
})

const mapActionsToProps = { createInvoice, updateInvoice, getInvoiceById, getReferenceNumber }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateOrUpdateInvoice)))
