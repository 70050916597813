import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    Zoom,
} from '@mui/material';
import { getConfigurations, createConfiguration, setConfigurationsPage, setConfigurationsSize, setConfigurationsParams } from '../../../redux/setting/actions'
import WrappedAutocomplete from '../../../components/common/WrappedAutocomplete';
import Base58Table from '../../../components/common/Base58Table';
import { Check, Clear, Help } from '@mui/icons-material';
import moment from 'moment';


class ConfigurationMessageTab extends Component {

    emptyConfiguration = {
        notificationType: '',
        message: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            configuration: this.emptyConfiguration,
            selectedType: null
        };
    }

    componentDidMount() {
        this.props.getConfigurations()
    }

    handleChange = (event) => {
        const target = event.target;
        let { configuration } = this.state;
        configuration[target.name] = target.value.trimStart()
        this.setState({ configuration: configuration })
    }


    handleChangeType = (e, type) => {
        let { configuration } = this.state
        if (type !== null) {
            configuration.notificationType = type
            this.setState({ selectedType: type, configuration: configuration })
        } else {
            configuration.notificationType = ''
            this.setState({ selectedType: null, configuration: configuration })
        }
    }

    clearData = () => {
        this.setState({
            configuration: {
                notificationType: '',
                message: ''
            }, selectedType: null
        })
    }

    createConfiguration = () => {
        let { configuration } = this.state
        this.props.createConfiguration(configuration).then(response => {
            if (response.status === 200) {
                this.props.getConfigurations()
                this.clearData()
            }
        })
    }

    render() {
        const { selectedType, configuration } = this.state
        const { notificationTypes, configurations, configurationsLoading, configurationsTotalElements, configurationsParams } = this.props.settingReducer;

        let configurationList;
        if (configurations && configurations.length > 0) {
            configurationList = configurations.map((conf, index) => {
                return <React.Fragment key={index}>
                    <TableRow>
                        <TableCell>{this.props.t(conf.notificationType)}</TableCell>
                        <TableCell>{conf.message}</TableCell>
                        <TableCell>{conf.active ? <Check style={{ color: 'green', fontSize: '26px' }} /> : <Clear style={{ color: 'red', fontSize: '26px' }} />}</TableCell>
                        <TableCell>
                            <Tooltip
                                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={<span>
                                    <p>{this.props.t("created_by") + ": " + conf.createdBy} </p>
                                    <p>{this.props.t("created_date") + ": " + moment.unix(conf.createdDate).format("DD.MM.YYYY. HH:mm")} </p>
                                </span>}>
                                <Help style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </React.Fragment>
            })
        } else {
            configurationList = <TableRow>
                <TableCell align="center" colSpan={2}>
                    {this.props.t("records_not_found")}
                </TableCell>
            </TableRow>
        }

        let columns = [
            {
                name: "type",
                dbName: "type",
                width: '25%',
                type: "auto-complete",
                items: notificationTypes.map(n => { return this.props.t(n) }),
                filter: true,
            },
            {
                name: "message",
                dbName: "message",
                width: '62%'
            },
            {
                name: "active",
                dbName: "active",
                width: '8%',
                type: 'boolean-select',
                filter: true
            },
            {
                name: "info",
                dbName: "info",
                width: '5%'
            }
        ]

        return (
            <Grid item container direction="row" spacing={2}>
                <Grid item container direction="column" xs={8} sm={8} md={8} lg={4} xl={4}>
                    <Card>
                        <CardHeader subheader={<strong>{this.props.t("create_configuration")}</strong>}></CardHeader>
                        <CardContent>
                            <Grid item container>
                                <FormControl>
                                    <WrappedAutocomplete
                                        label={this.props.t("type")}
                                        placeholder={this.props.t("select_type")}
                                        name="selectedType"
                                        value={selectedType}
                                        getOptionLabel={(option) => option ? this.props.t(option) : ""}
                                        options={notificationTypes}
                                        onChange={(event, type) => {
                                            this.handleChangeType(event, type)
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container>
                                <TextField
                                    label={this.props.t("message")}
                                    name="message"
                                    value={configuration.message}
                                    multiline
                                    rows={7}
                                    onChange={this.handleChange} />
                            </Grid>
                            <Typography variant="body2" gutterBottom>
                                <strong>{this.props.t("number_of_character")}</strong>: {configuration.message.length}
                            </Typography>
                            {configuration.notificationType === "CALENDER_ENTRY_REMINDER" ? <Typography variant="body2" gutterBottom>
                                <strong>{this.props.t("info_message")}</strong>
                            </Typography> : null}
                            <Button
                                className="default-button"
                                title={this.props.t("create_configuration")}
                                color="primary"
                                disabled={!selectedType || !configuration.message || !configuration.notificationType}
                                onClick={() => this.createConfiguration()}>
                                <span>{this.props.t("create_configuration")}</span>
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item container direction="column" xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Base58Table
                        isLoading={configurationsLoading}
                        columns={columns}
                        data={configurationList}
                        count={configurationsTotalElements}
                        rowsPerPage={configurationsParams.size}
                        page={configurationsParams.page}
                        onPageChange={(e, page) => this.props.setConfigurationsPage(page)}
                        onRowsPerPageChange={(e) => this.props.setConfigurationsSize(e.target.value)}
                        onFilterChange={(params) => this.props.setConfigurationsParams(params)}
                    />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

export default connect(mapStateToProps, { getConfigurations, createConfiguration, setConfigurationsPage, setConfigurationsSize, setConfigurationsParams })(withTranslation()(ConfigurationMessageTab))