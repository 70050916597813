import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    FormControl,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Check, Close } from '@mui/icons-material'
import PropTypes from 'prop-types';
import {
    getAuditLogsActions,
    deleteAuditLogs
} from '../../redux/auditLog/actions';
import { withTranslation } from 'react-i18next';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import moment from 'moment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class DeleteAuditLogsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAction: null,
            selectedStartDate: null,
            selectedEndDate: null
        };
    }

    componentDidMount() {
        this.props.getAuditLogsActions()
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            selectedAction: null,
            selectedStartDate: null,
            selectedEndDate: null
        });
    }

    handleChangeAction = (e, action) => {
        if (action !== null) {
            this.setState({ selectedAction: action })
        } else {
            this.setState({ selectedAction: null })
        }
    }

    handleChangeStartDate = (date) => {
        this.setState({ selectedStartDate: date });
    }

    handleChangeEndDate = (date) => {
        this.setState({ selectedEndDate: date });
    }

    deleteAuditLogs = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { selectedAction, selectedStartDate, selectedEndDate } = this.state;
        let params = {}
        params.action = selectedAction
        params.startTime = selectedStartDate !== null ? moment(selectedStartDate) : null
        params.endTime = selectedEndDate !== null ? moment(selectedEndDate) : null
        this.props.deleteAuditLogs({ params: params }).then(() => {
            this.closeDialog()
            this.props.onSave()
        })
    };

    validateForm() {
        let { selectedAction } = this.state;
        let valid = true;
        if (!selectedAction) {
            valid = false;
        }
        return valid;
    }

    render() {
        let { selectedAction, selectedStartDate, selectedEndDate } = this.state
        const { auditLogsActions } = this.props.auditReducer

        return (
            <BootstrapDialog
                onClose={this.closeDialog}
                open={this.props.isOpen}
                fullWidth
                TransitionComponent={Transition}>
                <BootstrapDialogTitle onClose={this.closeDialog}>
                    {this.props.t("delete_audit_logs")}
                </BootstrapDialogTitle>
                <form onSubmit={this.deleteAuditLogs} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl>
                                    <WrappedAutocomplete
                                        label={this.props.t("select_action")}
                                        placeholder={this.props.t("select_action")}
                                        name="selectedAction"
                                        value={selectedAction || null}
                                        options={auditLogsActions}
                                        onChange={(e, action) => {
                                            this.handleChangeAction(e, action)
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl>
                                    <WrappedDatePicker
                                        label="start_date"
                                        name="startDate"
                                        onChange={this.handleChangeStartDate}
                                        value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl>
                                    <WrappedDatePicker
                                        label="end_date"
                                        name="endDate"
                                        onChange={this.handleChangeEndDate}
                                        value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary" className="default-button" disabled={!this.validateForm()} >
                            <Check /> {this.props.t("delete")}
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    auditReducer: state.auditReducer
})

const mapActionsToProps = { getAuditLogsActions, deleteAuditLogs }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(DeleteAuditLogsDialog)))
