export const SET_EXAMINATIONS_LOADING = 'SET_EXAMINATIONS_LOADING';
export const SET_EXAMINATIONS = 'SET_EXAMINATIONS';
export const SET_TODAY_EXAMINATIONS = 'SET_TODAY_EXAMINATIONS'
export const SET_EXAMINATION_STATISTICS = 'SET_EXAMINATION_STATISTICS'
export const SET_EXAMINATION_PER_DOCTOR = 'SET_EXAMINATION_PER_DOCTOR'
export const SET_EXAMINATION_STATISTICS_PER_SERVICES = 'SET_EXAMINATION_STATISTICS_PER_SERVICES'

const types = {
    SET_EXAMINATIONS_LOADING,
    SET_EXAMINATIONS,
    SET_TODAY_EXAMINATIONS,
    SET_EXAMINATION_STATISTICS,
    SET_EXAMINATION_PER_DOCTOR,
    SET_EXAMINATION_STATISTICS_PER_SERVICES
};

export default types;