import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Check } from '@mui/icons-material'
import PropTypes from 'prop-types';
import {
    createDiagnosis,
    updateDiagnosis,
    getDiagnosisById
} from '../../redux/diagnosis/actions';
import { withTranslation } from 'react-i18next';
import { CODE_REGEX, NAME_REGEX } from '../../components/common/regex';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class CreateOrUpdateDiagnosis extends Component {

    emptyDiagnosis = {
        code: '',
        name: '',
        latinName: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            initialDiagnosis: JSON.parse(JSON.stringify(this.emptyDiagnosis)),
            diagnosis: JSON.parse(JSON.stringify(this.emptyDiagnosis))
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.diagnosisToEdit && prevProps.diagnosisToEdit !== this.props.diagnosisToEdit) {
            this.props.getDiagnosisById(this.props.diagnosisToEdit.toString()).then(response => {
                this.setState({
                    diagnosis: JSON.parse(JSON.stringify(response.data)),
                    initialDiagnosis: JSON.parse(JSON.stringify(response.data)),
                });
            })
        }
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            diagnosis: JSON.parse(JSON.stringify(this.emptyDiagnosis))
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let { diagnosis } = this.state;

        diagnosis[target.name] = target.value.trimStart();
        this.setState({ diagnosis: diagnosis })
    };


    createOrUpdateDiagnosis = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { diagnosis, initialDiagnosis } = this.state;
        let createDiagnosisObject = {
            ...diagnosis,
            latinName: diagnosis.latinName === '' ? null : diagnosis.latinName
        }
        let diagnosisObject = {
            ...diagnosis,
            code: initialDiagnosis.code === diagnosis.code ? null : diagnosis.code,
            name: initialDiagnosis.name === diagnosis.name ? null : diagnosis.name,
            latinName: diagnosis.latinName === "" ? null : diagnosis.latinName,
        }

        const action = this.props.diagnosisToEdit ?
            this.props.updateDiagnosis(this.props.diagnosisToEdit, diagnosisObject) :
            this.props.createDiagnosis(createDiagnosisObject);
        action.then(response => {
            if (response.status !== 400) {
                this.setState({ diagnosis: JSON.parse(JSON.stringify(this.emptyDiagnosis)) });
                this.props.onSave();
            }
        })
    };

    validateForm() {
        let { diagnosis, initialDiagnosis } = this.state;
        let valid = true;
        if (!CODE_REGEX.test(diagnosis.code)) {
            valid = false;
        }
        if (!NAME_REGEX.test(diagnosis.name)) {
            valid = false;
        }
        if (JSON.stringify(diagnosis) === JSON.stringify(initialDiagnosis)) {
            valid = false
        }
        if (diagnosis.latinName && diagnosis.latinName.length > 255) {
            valid = false
        }
        if (!diagnosis.code || !diagnosis.name) {
            valid = false;
        }
        return valid;
    }

    getNameHelperTextCode() {
        let { diagnosis } = this.state;
        if (!diagnosis.code.trim()) {
            return this.props.t("required_field_message");
        }
        if (diagnosis.code.length > 0 && !CODE_REGEX.test(diagnosis.code)) {
            return this.props.t("name_regex_message", { min: 1, max: 50 });
        }
    }

    getNameHelperTextName() {
        let { diagnosis } = this.state;
        if (!diagnosis.name.trim()) {
            return this.props.t("required_field_message");
        }
        if (diagnosis.name.length > 0 && !NAME_REGEX.test(diagnosis.name)) {
            return this.props.t("name_regex_message", { min: 1, max: 255 });
        }
    }

    render() {
        let { diagnosis } = this.state

        return (
            <BootstrapDialog
                onClose={this.closeDialog}
                open={this.props.isOpen}
                TransitionComponent={Transition}>
                <BootstrapDialogTitle onClose={this.closeDialog}>
                    {this.props.diagnosisToEdit ? this.props.t("edit_diagnosis") : this.props.t("new_diagnosis")}
                </BootstrapDialogTitle>
                <form onSubmit={this.createOrUpdateDiagnosis} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("code")}
                                            name="code"
                                            value={(diagnosis.code && diagnosis.code.charAt(0).toUpperCase() + diagnosis.code.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            required
                                            autoFocus
                                            error={!CODE_REGEX.test(diagnosis.code) || !diagnosis.code.trim()}
                                            helperText={this.getNameHelperTextCode()} />
                                    </Grid>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("name")}
                                            name="name"
                                            value={(diagnosis.name && diagnosis.name.charAt(0).toUpperCase() + diagnosis.name.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            required
                                            error={!NAME_REGEX.test(diagnosis.name) || !diagnosis.name.trim()}
                                            helperText={this.getNameHelperTextName()} />
                                    </Grid>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("latin_name")}
                                            name="latinName"
                                            value={(diagnosis.latinName && diagnosis.latinName.charAt(0).toUpperCase() + diagnosis.latinName.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            error={!!diagnosis.latinName && diagnosis.latinName.length > 255}
                                            helperText={diagnosis.latinName && diagnosis.latinName.length > 255 && this.props.t("number_regex_message", { max: 255 })} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={this.closeDialog} className="default-button">
                            <Close /> {this.props.t("close")}
                        </Button>
                        <Button type="submit" color="primary" className="default-button" disabled={!this.validateForm()} >
                            <Check />{this.props.diagnosisToEdit ? this.props.t("update") : this.props.t("create")}
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = { createDiagnosis, updateDiagnosis, getDiagnosisById }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateOrUpdateDiagnosis)))
