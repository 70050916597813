import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Box,
    FormControl,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Check } from '@mui/icons-material'
import PropTypes from 'prop-types';
import {
    createServiceGroup,
    updateServiceGroup,
    getServiceGroupById
} from '../../redux/serviceGroup/actions';
import { withTranslation } from 'react-i18next';
import { NAME_SHORT_REGEX } from '../../components/common/regex';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class CreateOrUpdateServiceGroup extends Component {

    emptyServiceGroup = {
        name: '',
        comment: '',
        userIds: []
    };

    constructor(props) {
        super(props);
        this.state = {
            initialServiceGroup: JSON.parse(JSON.stringify(this.emptyServiceGroup)),
            serviceGroup: JSON.parse(JSON.stringify(this.emptyServiceGroup)),
            selectedDoctors: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.serviceGroupToEdit && prevProps.serviceGroupToEdit !== this.props.serviceGroupToEdit) {
            this.props.getServiceGroupById(this.props.serviceGroupToEdit.toString()).then(response => {
                const selectedDoctors = this.props.userReducer.doctors.filter((el) => {
                    return response.data.userIds.some((f) => {
                        return f === el.id
                    });
                });
                this.setState({
                    serviceGroup: JSON.parse(JSON.stringify(response.data)),
                    initialServiceGroup: JSON.parse(JSON.stringify(response.data)),
                    selectedDoctors: selectedDoctors
                });
            })
        }
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            serviceGroup: JSON.parse(JSON.stringify(this.emptyServiceGroup)),
            selectedDoctors: []
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let { serviceGroup } = this.state;
        serviceGroup[target.name] = target.value.trimStart()
        this.setState({ serviceGroup: serviceGroup })
    };

    createOrUpdateServiceGroup = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { serviceGroup } = this.state;
        let serviceGroupObject = {
            ...serviceGroup,
            name: serviceGroup.name && serviceGroup.name.trim(),
            comment: serviceGroup.comment && serviceGroup.comment.trim().toUpperCase()
        }
        const action = this.props.serviceGroupToEdit ?
            this.props.updateServiceGroup(this.props.serviceGroupToEdit, serviceGroupObject) :
            this.props.createServiceGroup(serviceGroupObject);
        action.then(response => {
            if (response.status !== 400) {
                this.setState({ serviceGroup: JSON.parse(JSON.stringify(this.emptyServiceGroup)), selectedDoctors: [] });
                this.props.onSave();
            }
        })
    };

    validateForm() {
        let { serviceGroup, initialServiceGroup } = this.state;
        let valid = true;
        if (!NAME_SHORT_REGEX.test(serviceGroup.name)) {
            valid = false;
        }
        if (JSON.stringify(serviceGroup) === JSON.stringify(initialServiceGroup)) {
            valid = false
        }
        if (!serviceGroup.name
            || !serviceGroup.comment
        ) {
            valid = false;
        }
        return valid;
    }

    handleChangeDoctors = (e, doctors) => {
        let { serviceGroup } = this.state
        let selectedDoctors = { ...this.state.selectedDoctors }
        selectedDoctors = doctors;
        serviceGroup.userIds = []
        selectedDoctors.forEach(data => {
            serviceGroup.userIds.push(data.id)
        })
        this.setState({ selectedDoctors: selectedDoctors, serviceGroup: serviceGroup });
    }

    getNameHelperTextName() {
        let { serviceGroup } = this.state;
        if (!serviceGroup.name.trim()) {
            return this.props.t("required_field_message");
        }
        if (serviceGroup.name.length > 0 && !NAME_SHORT_REGEX.test(serviceGroup.name)) {
            return this.props.t("name_regex_message", { min: 1, max: 50 });
        }
    }

    getNameHelperTextComment() {
        let { serviceGroup } = this.state;
        if (!serviceGroup.comment.trim()) {
            return this.props.t("required_field_message");
        }
    }

    render() {
        let { serviceGroup, selectedDoctors } = this.state
        const { doctors } = this.props.userReducer

        return (
            <BootstrapDialog
                onClose={this.closeDialog}
                open={this.props.isOpen}
                TransitionComponent={Transition}>
                <BootstrapDialogTitle onClose={this.closeDialog}>
                    {this.props.serviceGroupToEdit ? this.props.t("edit_service_group") : this.props.t("new_service_group")}
                </BootstrapDialogTitle>
                <form onSubmit={this.createOrUpdateServiceGroup} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container>
                                        <TextField
                                            label={this.props.t("name_service_group")}
                                            name="name"
                                            autoFocus
                                            value={(serviceGroup.name && serviceGroup.name.charAt(0).toUpperCase() + serviceGroup.name.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            required
                                            error={!NAME_SHORT_REGEX.test(serviceGroup.name) || !serviceGroup.name.trim()}
                                            helperText={this.getNameHelperTextName()} />
                                    </Grid>
                                    <Grid item container>
                                        <FormControl>
                                            <WrappedAutocomplete
                                                label={this.props.t("select_doctors")}
                                                placeholder={this.props.t("select_doctors")}
                                                name="selectedDoctors"
                                                multiple={true}
                                                value={selectedDoctors}
                                                getOptionLabel={(option) => option.firstName + " " + option.lastName + " (" + option.phone + ")"}
                                                options={doctors}
                                                onChange={(event, value) => {
                                                    this.handleChangeDoctors(event, value)
                                                }}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} className="selected-dropdown-custom">
                                                        <div>
                                                            {option.firstName + " " + option.lastName}
                                                            <div>
                                                                <small>{option.phone}</small>
                                                            </div>
                                                        </div>
                                                    </Box>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item container>
                                        <TextField
                                            multiline
                                            rows={3}
                                            label={this.props.t("comment")}
                                            name="comment"
                                            value={serviceGroup.comment || ''}
                                            inputProps={{
                                                style: { textTransform: "uppercase" }
                                            }}
                                            onChange={this.handleChange}
                                            required
                                            error={!serviceGroup.comment.trim()}
                                            helperText={this.getNameHelperTextComment()} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" className="default-button" onClick={this.closeDialog} >
                            <Close /> {this.props.t("close")}
                        </Button>
                        <Button type="submit" color="primary" className="default-button" disabled={!this.validateForm()} >
                            <Check />{this.props.serviceGroupToEdit ? this.props.t("update") : this.props.t("create")}
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    serviceGroupReducer: state.serviceGroupReducer,
    userReducer: state.userReducer
})

const mapActionsToProps = { createServiceGroup, updateServiceGroup, getServiceGroupById }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateOrUpdateServiceGroup)))
