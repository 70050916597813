import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Box,
    Grid,
    Tab,
    Tabs,
} from '@mui/material';
import CreateConfiguration from './emailTabs/CreateConfiguration';
import EmailConfigurations from './emailTabs/EmailConfigurations';
import { CustomTabPanel, a11yProps } from '../../components/common/tabs';

class GoogleQuestionnaire extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }

    handleChangeTab = (event, value) => {
        this.setState({ selectedTab: value });
    }

    render() {
        const { selectedTab } = this.state

        return (
            <Grid item container>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTab} onChange={this.handleChangeTab}>
                            <Tab key={0} label={this.props.t("configuration")} {...a11yProps(0)} wrapped />
                            <Tab key={1} label={this.props.t("create_configuration")} {...a11yProps(1)} wrapped />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={selectedTab} index={0} style={{ width: "100%" }}>
                        <EmailConfigurations />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={1} style={{ width: "100%" }}>
                        <CreateConfiguration />
                    </CustomTabPanel>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(GoogleQuestionnaire))