export const SET_INOVICE_LOADING = 'SET_INOVICE_LOADING';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_INVOICE_TYPES = 'SET_INVOICE_TYPES'
export const SET_INVOICES_PAGE = 'SET_INVOICES_PAGE'
export const SET_INVOICES_SIZE = 'SET_INVOICES_SIZE'
export const SET_INVOICES_PARAMS = 'SET_INVOICES_PARAMS'
export const SET_INVOICE_STATISTICS = 'SET_INVOICE_STATISTICS'
export const RESET_INVOICE_DATA = 'RESET_INVOICE_DATA'
export const SET_LOADING_CREATE_INVOICE = 'SET_LOADING_CREATE_INVOICE'

const types = {
    SET_INOVICE_LOADING,
    SET_INVOICES,
    SET_INVOICE_TYPES,
    SET_INVOICES_PAGE,
    SET_INVOICES_SIZE,
    SET_INVOICES_PARAMS,
    SET_INVOICE_STATISTICS,
    RESET_INVOICE_DATA,
    SET_LOADING_CREATE_INVOICE
};

export default types;