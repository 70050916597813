import axios from '../axiosConfig';

export function getScheduleEvents(config = {}) {
    return axios.post(`/v1/schedule/list`, config);
}

export function createSchedule(data) {
    return axios.post(`/v1/schedule`, data);
}

export function updateSchedule(id, data) {
    return axios.put(`/v1/schedule/${id}`, data);
}

export function getScheduleEventById(id, config = {}) {
    return axios.get(`/v1/schedule/${id}`, config);
}

export function deleteScheduleEvent(id) {
    return axios.delete(`/v1/schedule/${id}`);
}

const actions = {
    getScheduleEvents,
    createSchedule,
    updateSchedule,
    getScheduleEventById,
    deleteScheduleEvent
}

export default actions;
