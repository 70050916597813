import types from './types';

const initialState = {
    infirmariesLoading: true,
    infirmaries: [],
    infirmariesTotalElements: 0,
    infirmariesParams: {
        page: 0,
        size: 10,
        sort: 'name,asc'
    },
    infirmaryList: []
}

export default function infirmary(state = initialState, action) {
    switch (action.type) {
        case types.SET_INFIRMARIES:
            return {
                ...state,
                infirmaries: action.payload.content,
                infirmariesTotalElements: action.payload ? action.payload.totalElements : 0,
                infirmariesLoading: false
            }
        case types.SET_INFIRMARIES_PAGE:
            return {
                ...state, infirmariesParams: { ...state.infirmariesParams, page: action.payload }
            }
        case types.SET_INFIRMARIES_SIZE:
            return {
                ...state, infirmariesParams: { ...state.infirmariesParams, size: action.payload, page: 0 }
            }
        case types.SET_INFIRMARIES_PARAMS:
            return {
                ...state,
                infirmariesParams: action.payload
            }
        case types.SET_INFIRMARY_LIST: {
            return {
                ...state,
                infirmaryList: action.payload
            }
        }
        case types.RESET_INFIRMARY_DATA: {
            return {
                ...initialState,
                infirmariesParams: {
                    size: 10,
                    page: 0,
                    sort: 'name,asc'
                }
            }
        }
        default:
            return state;
    }
}