export const SET_SERVICES_LOADING = 'SET_SERVICES_LOADING';
export const SET_SERVICES = 'SET_SERVICES';
export const SET_SERVICE_LIST = 'SET_SERVICE_LIST'
export const SET_LOADING_IMPORT_SERVICES = 'SET_LOADING_IMPORT_SERVICES'
export const SET_SERVICES_BY_DOCTOR_ID = 'SET_SERVICES_BY_DOCTOR_ID'
export const SET_SERVICES_PAGE = 'SET_SERVICES_PAGE'
export const SET_SERVICES_SIZE = 'SET_SERVICES_SIZE'
export const SET_SERVICES_PARAMS = 'SET_SERVICES_PARAMS'
export const RESET_SERVICE_DATA = 'RESET_SERVICE_DATA'

const types = {
    SET_SERVICES_LOADING,
    SET_SERVICES,
    SET_SERVICE_LIST,
    SET_LOADING_IMPORT_SERVICES,
    SET_SERVICES_BY_DOCTOR_ID,
    SET_SERVICES_PAGE,
    SET_SERVICES_SIZE,
    SET_SERVICES_PARAMS,
    RESET_SERVICE_DATA
};

export default types;