import types from './types';
import api from '../../api/invoiceApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';

export const getInvoices = () => async (dispatch) => {
    dispatch({
        type: types.SET_INOVICE_LOADING
    });
    await api.getInvoices({ params: store.getState().invoiceReducer.invoicesParams }).then(response => {
        if (store.getState().invoiceReducer.invoicesParams.page > 0 && response.data.content.length === 0) {
            dispatch(setInvoicesPage(0));
        }
        dispatch({
            type: types.SET_INVOICES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_INVOICES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_invoices_error")));
    });
    return Promise.resolve();
}

export const setInvoicesPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_INVOICES_PAGE,
        payload: Number(value)
    });
    dispatch(getInvoices());
    return Promise.resolve();
};

export const setInvoicesSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_INVOICES_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getInvoices());
    }
    return Promise.resolve();
};

export const setInvoicesParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_INVOICES_PARAMS,
        payload: Object.assign(store.getState().invoiceReducer.invoicesParams, params)
    });
    dispatch(getInvoices());
    return Promise.resolve();
};

export const getInvoiceTypes = () => async (dispatch) => {
    await api.getInvoiceTypes().then(response => {
        dispatch({
            type: types.SET_INVOICE_TYPES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_INVOICE_TYPES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_invoice_types_error")));
    });
    return Promise.resolve();
}

export const getInvoiceById = (id, config = {}) => async (dispatch) => {
    return await api.getInvoiceById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_invoice_by_id_error")));
    });
}

export const createInvoice = (data, config) => async (dispatch) => {
    dispatch({
        type: types.SET_LOADING_CREATE_INVOICE,
        payload: true
    });
    return await api.createInvoice(data, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf,application/json',
        },
        responseType: 'blob'
    }).then((response) => {
        dispatch(snackActions.success(i18next.t("offer_created_successfully")));
        handleContentDisposition(response)
        dispatch({
            type: types.SET_LOADING_CREATE_INVOICE,
            payload: false
        });
        return response;
    }).catch(async (err) => {
        if (err.response.status !== 200) {
            const error = JSON.parse(await err.response.data.text());
            dispatch(snackActions.error(
                error.errors.map((item, index) => {
                    return <li key={index}>{item}</li>
                })
            ));
            dispatch({
                type: types.SET_LOADING_CREATE_INVOICE,
                payload: false
            });
        } else {
            // success blob file
        }
        return err.response
    });
}

export const updateInvoice = (id, data) => async (dispatch) => {
    return await api.updateInvoice(id, data).then(response => {
        dispatch(snackActions.success(i18next.t("offer_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const exportInvoiceToPdf = (invoiceId, config) => async (dispatch) => {
    return await api.exportInvoiceToPdf(invoiceId, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        },
        responseType: 'blob'
    }).then(response => {
        handleContentDisposition(response)
        dispatch(snackActions.success(i18next.t("export_successfully")));
        return response;
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}

export const handleContentDisposition = (response) => {
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers["content-disposition"];
    let fileName = contentDisposition.split("filename=")[1];
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

export const getInvoiceStatistics = (data) => async (dispatch) => {
    await api.getInvoiceStatistics(data).then((response) => {
        dispatch({
            type: types.SET_INVOICE_STATISTICS,
            payload: response.data
        })
    }).catch(() => {
        dispatch({
            type: types.SET_INVOICE_STATISTICS,
            payload: null
        })
    });
    return Promise.resolve();
}

export const exportStatisticsToExcel = (data) => async (dispatch) => {
    return await api.exportStatisticsToExcel(data, {
        headers: {
            'Accept': 'application/octet-stream',
            'Access-Control-Expose-Headers': 'Content-Disposition'
        },
        responseType: 'blob'
    }).then((response) => {
        handleContentDisposition(response)
        dispatch(snackActions.success(i18next.t("export_successfully")));
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}

export const exportStatisticsToPdf = (data) => async (dispatch) => {
    return await api.exportStatisticsToPdf(data, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        },
        responseType: 'blob'
    }).then(response => {
        handleContentDisposition(response)
        dispatch(snackActions.success(i18next.t("export_successfully")));
        return response;
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}

export const getReferenceNumber = (config = {}) => async (dispatch) => {
    return await api.getReferenceNumber(config).then(response => {
        return response
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}