import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    Select,
    FormControl,
    MenuItem,
    CircularProgress,
    InputLabel,
    Button,
    CardContent,
    Card
} from '@mui/material'
import { getDocumentLanguages, getSettings, updateSettings } from '../../redux/setting/actions';
import { FlagIcon } from 'react-flag-kit';
import { Check } from '@mui/icons-material';

class Languages extends React.Component {

    emptySettings = {
        documentLanguage: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            settings: JSON.parse(JSON.stringify(this.emptySettings)),
            initialSettings: JSON.parse(JSON.stringify(this.emptySettings)),
            mainLoading: true
        }
    }

    componentDidMount() {
        Promise.all([
            this.props.getDocumentLanguages(),
            this.props.getSettings().then((response) => {
                this.setState({ settings: JSON.parse(JSON.stringify(response.data)), initialSettings: JSON.parse(JSON.stringify(response.data)) })
            })])
            .then(() => {
                this.setState({ mainLoading: false });
            })
            .catch(error => {
                this.setState({ mainLoading: false });
            })
    }


    handleChange = (event) => {
        const target = event.target;
        let { settings } = this.state;
        settings[target.name] = target.value;
        this.setState({ settings: settings })
    };

    updateSettings = () => {
        let { settings } = this.state
        this.props.updateSettings(settings).then((response) => {
            this.setState({ settings: JSON.parse(JSON.stringify(response.data)), initialSettings: JSON.parse(JSON.stringify(response.data)) })
        })
    }

    validateForm = () => {
        let { settings, initialSettings } = this.state;
        let valid = true;
        if (JSON.stringify(settings) === JSON.stringify(initialSettings)) {
            valid = false
        }
        if (!settings.documentLanguage) {
            valid = false;
        }
        return valid;
    }

    render() {
        const { mainLoading, settings } = this.state;
        const { documentLanguages } = this.props.settingReducer;

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (<>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3}>
                    <Card>
                        <CardContent>
                            <Grid item container>
                                <FormControl>
                                    <InputLabel>{this.props.t("document_language")}</InputLabel>
                                    <Select
                                        value={settings && settings.documentLanguage}
                                        label={this.props.t("document_language")}
                                        name="documentLanguage"
                                        onChange={this.handleChange}>
                                        {documentLanguages.map(data => { return <MenuItem key={data} value={data}><FlagIcon style={{ marginRight: 10 }} code={data === "EN" ? "GB" : data} size={20} />{data}</MenuItem> })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item container mt={2}>
                                <Button type="submit" color="primary" className="default-button" disabled={!this.validateForm()} onClick={() => this.updateSettings()}>
                                    <Check /> {this.props.t("update")}
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

const mapActionsToProps = { getDocumentLanguages, getSettings, updateSettings }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Languages))
