import { createTheme } from '@mui/material/styles';

let theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins', 'sans-serif'
        ].join(',')
    },
    palette: {
        primary: {
            main: '#6F8684',
            contrastText: '#fff'
        },
        secondary: {
            main: '#a8a8a8',
            contrastText: '#fff'
        }
    },
    props: {
        MuiInput: { inputProps: { autoComplete: 'off', spellCheck: false } }
    }
});

theme = createTheme(theme, {
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                size: 'small'
            },
            styleOverrides: {
                colorInherit: {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                        backgroundColor: "#a6deca",
                        color: theme.palette.primary.contrastText,
                    }
                },
                root: {
                    "&.default-button": {
                        textTransform: 'none'
                    },
                }
            }
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            }
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
                fullWidth: true
            }
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            }
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            }
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            }
        },
        MuiOutlinedInput: {
            defaultProps: {
                size: 'small',
                margin: 'dense',
                variant: 'outlined'
            }
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
                variant: 'extended'
            },
            styleOverrides: {
                colorInherit: {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                        backgroundColor: "#6F8684",
                        color: theme.palette.primary.contrastText,
                    },
                    root: {
                        "&.table-fab": {
                            maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'
                        },
                        "&.calendar-fab": {
                            maxWidth: '15px', maxHeight: '15px', minWidth: '15px', minHeight: '15px'
                        }
                    }

                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    "&.table-fab-icon": {
                        maxWidth: '20px', maxHeight: '20px', minWidth: '20px', minHeight: '20px'
                    },
                    "&.calendar-fab-icon": {
                        maxWidth: '15px', maxHeight: '15px', minWidth: '15px', minHeight: '15px'
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    /* textTransform: "uppercase", */
                    backgroundColor: 'none',
                    border: 'none !important'
                }
            }
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiTextField: {
            defaultProps: {
                margin: 'dense',
                variant: 'outlined',
                size: 'small',
                fullWidth: true
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: '#6F8684',
                    color: 'white'
                }
            }
        },
        /*  MuiCard: {
             styleOverrides: {
                 root: {
                     "&.custom-card-infirmary": {
                         textAlign: 'center',
                         paddingTop: 70,
                         paddingBottom: 70,
                         backgroundColor: '#f6eeee',
                         borderRadius: '16%',
                         boxShadow: '3px 5px 5px #EFA2A2',
                         cursor: 'pointer'
                     },
                     "&:hover": {
                         textAlign: 'center',
                         paddingTop: 70,
                         paddingBottom: 70,
                         backgroundColor: '#F09292',
                         borderRadius: '16%',
                         boxShadow: '3px 5px 5px #EFA2A2',
                         cursor: 'pointer'
                     }
                 }
             }
         }, */
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ededed',
                    color: '#6F8684'
                },
                subheader: {
                    color: '#6F8684'
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '20px !important'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '15px 25px 25px 25px',
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    /* backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main, */
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                    },
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                    }
                }
            }
        }
    },
});

export default theme;
