export const SET_DIAGNOSES = 'SET_DIAGNOSES'
export const SET_DIAGNOSES_LIST = 'SET_DIAGNOSES_LIST'
export const SET_LOADING_IMPORT = 'SET_LOADING_IMPORT'
export const SET_DIAGNOSES_PAGE = 'SET_DIAGNOSES_PAGE'
export const SET_DIAGNOSES_SIZE = 'SET_DIAGNOSES_SIZE'
export const SET_DIAGNOSES_PARAMS = 'SET_DIAGNOSES_PARAMS'
export const RESET_DIAGNOSES_DATA = 'RESET_DIAGNOSES_DATA'

const types = {
    SET_DIAGNOSES,
    SET_DIAGNOSES_LIST,
    SET_LOADING_IMPORT,
    SET_DIAGNOSES_PAGE,
    SET_DIAGNOSES_SIZE,
    SET_DIAGNOSES_PARAMS,
    RESET_DIAGNOSES_DATA
};

export default types;