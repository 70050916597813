export const FIRST_NAME_REGEX = /^.{1,50}$/;
export const LAST_NAME_REGEX = /^.{1,50}$/;
export const PHONE_REGEX = /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{3,9}$/im;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const CODE_REGEX = /^.{1,50}$/;
export const NAME_REGEX = /^.{1,255}$/;
export const COMMENT_EXAMINATION_REGEX= /^.{2,2000}$/;
export const COMMENT_REGEX= /^.{1,500}$/;
export const INVOICE_REF_NUMBER_REGEX = /^.{1,30}$/;
export const NAME_SHORT_REGEX = /^.{1,50}$/;
