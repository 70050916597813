import axios from '../axiosConfig';

export function createCalendarEvent(config = {}) {
    return axios.post(`/v1/calendar`, config);
}

export function updateCalendarEvent(id, data) {
    return axios.patch(`/v1/calendar/${id}`, data);
}

export function updateCalendarEventStatus(id, config) {
    return axios.patch(`/v1/calendar/${id}/status`, {}, config);
}

export function getCalendarEvents(config = {}) {
    return axios.get(`/v1/calendar/list`, config);
}

export function getCalendarEntryStatuses(config = {}) {
    return axios.get(`/v1/calendar/statuses`, config);
}

export function deleteCalendarEntry(id) {
    return axios.delete(`/v1/calendar/${id}`);
}

export function getCalendarEntryById(id, config = {}) {
    return axios.get(`/v1/calendar/${id}`, config);
}

export function getTodayEntries(config = {}) {
    return axios.get(`/v1/calendar/today`, config);
}

export function changePatientInfirmary(calendarEntryId, config = {}) {
    return axios.patch(`/v1/calendar/${calendarEntryId}/update-patients-infirmary`, {}, config);
}

export function getPatientCalendarEntries(id, config = {}) {
    return axios.get(`/v1/calendar/get-by-patient/${id}`, config);
}

export function getCalendarEntriesRecords(config = {}) {
    return axios.get(`/v1/calendar/list-by-date`, config);
}

export function syncCalendarEntry(config = {}) {
    return axios.get(`/v1/calendar/sync-calendar-entry`, config);
}

export function getPriceEstimateStatistics(config = {}) {
    return axios.get(`/v1/calendar/service-price-estimate`, config);
}

const actions = {
    createCalendarEvent,
    getCalendarEvents,
    getCalendarEntryStatuses,
    deleteCalendarEntry,
    getCalendarEntryById,
    updateCalendarEvent,
    getTodayEntries,
    updateCalendarEventStatus,
    changePatientInfirmary,
    getPatientCalendarEntries,
    getCalendarEntriesRecords,
    syncCalendarEntry,
    getPriceEstimateStatistics
}

export default actions;
