import types from './types';

const initialState = {
    patients: [],
    patientsTotalElements: 0,
    patientsParams: {
        page: 0,
        size: 10,
        sort: 'created_date,desc'
    },
    patientsLoading: false,
    genders: [],
    sources: [],
    counties: [],
    patientList: [],
    currentPatient: null,
    patientId: null,
    patientFiles: null,
    isLoadingImport: false,
    gdprPromo: false,
    popoverOpen: false
}

export default function patient(state = initialState, action) {
    switch (action.type) {
        case types.SET_PATIENTS_LOADING:
            return {
                ...state,
                patientsLoading: true
            }
        case types.SET_PATIENTS:
            return {
                ...state,
                patients: action.payload.content,
                patientsTotalElements: action.payload ? action.payload.totalElements : 0,
                patientsLoading: false
            }
        case types.SET_PATIENTS_PAGE:
            return {
                ...state, patientsParams: { ...state.patientsParams, page: action.payload }
            }
        case types.SET_PATIENTS_SIZE:
            return {
                ...state, patientsParams: { ...state.patientsParams, size: action.payload, page: 0 }
            }
        case types.SET_PATIENTS_PARAMS:
            return {
                ...state,
                patientsParams: { ...state.patientsParams, sort: 'created_date,desc' }
            }
        case types.SET_GENDERS:
            return {
                ...state,
                genders: action.payload
            }
        case types.SET_COUNTIES:
            return {
                ...state,
                counties: action.payload
            }
        case types.SET_SOURCES:
            return {
                ...state,
                sources: action.payload
            }
        case types.SET_PATIENT_LIST:
            return {
                ...state,
                patientList: action.payload
            }
        case types.SET_CURRENT_PATIENT:
            return {
                ...state,
                currentPatient: action.payload
            }
        case types.SET_SIGNER_EVENT:
            return {
                ...state,
                patientId: action.payload,
                gdprPromo: false
            }
        case types.SET_SIGNER_EVENT_PROMO:
            return {
                ...state,
                patientId: action.payload,
                gdprPromo: true
            }
        case types.RESET_PATIENT_DATA:
            return {
                ...initialState,
                patientsParams: {
                    size: 10,
                    page: 0,
                    sort: 'created_date,desc'
                }
            }
        case types.SET_LOADING_IMPORT_FILES:
            return {
                ...state,
                isLoadingImport: action.payload
            }
        case types.SET_PATIENT_FILES:
            return {
                ...state,
                patientFiles: action.payload
            }
        case types.POPOVER_OPEN: {
            return {
                ...state,
                popoverOpen: action.payload
            }
        }
        default:
            return state;
    }
}