import types, { GOOGLE_FORM_LOADING } from './types';
import api from '../../api/settingApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';

export const getDocumentLanguages = () => async (dispatch) => {
    await api.getDocumentLanguages().then(response => {
        dispatch({
            type: types.SET_DOCUMENT_LANGUAGES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_DOCUMENT_LANGUAGES,
            payload: null
        });
        dispatch(snackActions.error(i18next.t("get_document_languages_error")));
    });
    return Promise.resolve();
}

export const getSettings = () => async (dispatch) => {
    return await api.getSettings().then(response => {
        dispatch({
            type: types.SET_SETTINGS,
            payload: response.data
        });
        return response
    }).catch(error => {
        dispatch({
            type: types.SET_SETTINGS,
            payload: null
        });
        dispatch(snackActions.error(i18next.t("get_settings_error")));
    });
}

export const updateSettings = (data) => async (dispatch) => {
    return await api.updateSettings(data).then(response => {
        dispatch(snackActions.success(i18next.t("settings_updated_successfully")));
        return response
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("update_settings_error")));
    });
}

export const getBalance = () => async (dispatch) => {
    return await api.getBalance().then(response => {
        dispatch({
            type: types.SET_BALANCE,
            payload: response.data
        });
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
    });
}

export const getHistory = () => async (dispatch) => {
    let params = store.getState().settingReducer.historyParams;
    params.timeFrom = params.timeFrom ? params.timeFrom : null;
    params.timeTo = params.timeTo ? params.timeTo : null;
    dispatch({
        type: types.SET_HISTORY_LOADING
    });
    await api.getHistory({ params: params }).then(response => {
        dispatch({
            type: types.SET_HISTORY,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_HISTORY,
            payload: null
        });
        dispatch(snackActions.error(i18next.t("get_history_error")));
    });
    return Promise.resolve();
}

export const setHistoryPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY_PAGE,
        payload: Number(value)
    });
    dispatch(getHistory());
    return Promise.resolve();
};

export const setHistorySize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_HISTORY_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getHistory());
    }
    return Promise.resolve();
};

export const setHistoryParams = (params) => (dispatch) => {
    let historyDataParams = { ...store.getState().settingReducer.historyParams };
    historyDataParams.page = 0;
    dispatch({
        type: types.SET_HISTORY_PARAMS,
        payload: Object.assign(historyDataParams, params)
    });
    dispatch(getHistory());
    return Promise.resolve();
};

export const sendBulkSms = (data) => async (dispatch) => {
    return await api.sendBulkSms(data).then((response) => {
        if (response.status === 200 || response.status === 207) {
            let failed = []
            let success = []
            response.data.map(d => {
                if (d.status === "FAILED") {
                    return failed.push(d.status)
                }
                else if (d.status === "QUEUED") {
                    return success.push(d.status)
                }
                else return null
            })
            dispatch(snackActions.success(i18next.t("success_send") + success.length + ", " + i18next.t("failed_send") + failed.length));
        }
        return response
    }).catch((error) => {
        dispatch(snackActions.error(i18next.t("error")));
        return error.response
    });
}

export const getNotificationType = () => async (dispatch) => {
    await api.getNotificationType().then(response => {
        dispatch({
            type: types.SET_NOTIFICATION_TYPE,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_NOTIFICATION_TYPE,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_notification_type_list_error")));
    });
    return Promise.resolve();
}

export const getConfigurations = () => async (dispatch) => {
    dispatch({
        type: types.SET_CONFIGURATIONS_LOADING
    });
    await api.getConfigurations({ params: store.getState().settingReducer.configurationsParams }).then(response => {
        if (store.getState().settingReducer.configurationsParams.page > 0 && response.data.content.length === 0) {
            dispatch(setConfigurationsPage(0));
        }
        dispatch({
            type: types.SET_CONFIGURATIONS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_CONFIGURATIONS,
            payload: null
        });
        dispatch(snackActions.error(i18next.t("get_configurations_error")));
    });
    return Promise.resolve();
}

export const setConfigurationsPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CONFIGURATIONS_PAGE,
        payload: Number(value)
    });
    dispatch(getConfigurations());
    return Promise.resolve();
};

export const setConfigurationsSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_CONFIGURATIONS_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getConfigurations());
    }
    return Promise.resolve();
};

export const setConfigurationsParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_CONFIGURATIONS_PARAMS,
        payload: Object.assign(store.getState().settingReducer.configurationsParams, params)
    });
    dispatch(getConfigurations());
    return Promise.resolve();
};

export const createConfiguration = (data) => async (dispatch) => {
    return await api.createConfiguration(data).then((response) => {
        dispatch(snackActions.success(i18next.t("configuration_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const getTestGoogleForm = (data) => async (dispatch) => {
    dispatch({
        type: GOOGLE_FORM_LOADING,
        payload: true
    })
    return await api.getTestGoogleForm(data).then(response => {
        dispatch({
            type: GOOGLE_FORM_LOADING,
            payload: false
        })
        dispatch({
            type: types.SET_GOOGLE_FORM_TEST_URL,
            payload: response.data
        });

        return response
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        dispatch({
            type: GOOGLE_FORM_LOADING,
            payload: false
        })
        dispatch({
            type: types.SET_GOOGLE_FORM_TEST_URL,
            payload: ''
        });

        return error
    });
}

export const createFormConfiguration = (data) => async (dispatch) => {
    dispatch({
        type: GOOGLE_FORM_LOADING,
        payload: true
    })
    return await api.createFormConfiguration(data).then(response => {
        dispatch({
            type: GOOGLE_FORM_LOADING,
            payload: false
        })
        dispatch(snackActions.success(i18next.t("google_form_created_successfully")));
        return response
    }).catch(error => {
        dispatch({
            type: GOOGLE_FORM_LOADING,
            payload: false
        })
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
    });
}

export const getEmailConfigurations = () => async (dispatch) => {
    let params = {
        googleFormType: 'QUESTIONNAIRE',
        isActive: true
    }
    return await api.getEmailConfigurations({ params: params }).then(response => {
        return response
    }).catch(error => {
        return error
    });
}

export const updateGoogleForm = (data) => async (dispatch) => {
    return await api.updateGoogleForm(data).then(response => {
        dispatch(snackActions.success(i18next.t("google_from_updated_successfully")));
        return response
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("update_google_from_error")));
    });
}

export const getAllGoogleForms = () => async (dispatch) => {
    dispatch({
        type: GOOGLE_FORM_LOADING,
        payload: true
    })
    return await api.getAllGoogleForms({ params: store.getState().settingReducer.googleFormsParams }).then(response => {
        if (store.getState().settingReducer.googleFormsParams.page > 0 && response.data.content.length === 0) {
            dispatch(setGoogleFormsPage(0));
        }
        dispatch({
            type: GOOGLE_FORM_LOADING,
            payload: false
        })
        dispatch({
            type: types.SET_ALL_GOOGLE_FORMS,
            payload: response.data
        });
        return response
    }).catch(error => {
        dispatch({
            type: GOOGLE_FORM_LOADING,
            payload: false
        })
        dispatch({
            type: types.SET_ALL_GOOGLE_FORMS,
            payload: ''
        });
        return error
    });
}

export const setGoogleFormsPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_GOOGLE_FORMS_PAGE,
        payload: Number(value)
    });
    dispatch(getAllGoogleForms());
    return Promise.resolve();
};

export const setGoogleFormsSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_GOOGLE_FORMS_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getAllGoogleForms());
    }
    return Promise.resolve();
};

export const setGoogleFormsParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_GOOGLE_FORMS_PARAMS,
        payload: Object.assign(store.getState().settingReducer.googleFormsParams, params)
    });
    dispatch(getAllGoogleForms());
    return Promise.resolve();
};

export const getGoogleCredentials = () => async (dispatch) => {
    await api.getGoogleCredentials({ params: store.getState().settingReducer.googleCredentialsParams }).then(response => {
        dispatch({
            type: types.SET_GOOGLE_CREDENTIALS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_GOOGLE_CREDENTIALS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_patients_error")));
    });
    return Promise.resolve();
}

export const setGoogleCredentialsPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_GOOGLE_CREDENTIALS_PAGE,
        payload: Number(value)
    });
    dispatch(getGoogleCredentials());
    return Promise.resolve();
};

export const setGoogleCredentialsSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_GOOGLE_CREDENTIALS_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getGoogleCredentials());
    }
    return Promise.resolve();
};

export const setGoogleCredentialsParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_GOOGLE_CREDENTIALS_PARAMS,
        payload: Object.assign(store.getState().settingReducer.googleCredentialsParams, params)
    });
    dispatch(getGoogleCredentials());
    return Promise.resolve();
};

export const createGoogleCredentials = (data) => async (dispatch) => {
    return await api.createGoogleCredentials(data).then(response => {
        dispatch(snackActions.success(i18next.t("credentials_created_successfully")));
        return response
    }).catch((error) => {
        /* dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        )); */
        dispatch(snackActions.error(i18next.t("create_credentials_error")));
    });
}
