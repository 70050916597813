import axios from '../axiosConfig';

export function getInvoices(config = {}) {
    return axios.get(`/v1/invoice`, config);
}

export function getInvoiceTypes(config = {}) {
    return axios.get(`/v1/invoice/types`, config);
}

export function getInvoiceById(id, config = {}) {
    return axios.get(`/v1/invoice/${id}`, config);
}

export function createInvoice(data, config = {}) {
    return axios.post(`/v1/invoice`, data, config);
}

export function updateInvoice(id, data) {
    return axios.patch(`/v1/invoice/${id}`, data);
}

export function exportInvoiceToPdf(id, config = {}) {
    return axios.get(`/v1/invoice/${id}/pdf`, config);
}

export function getInvoiceStatistics(data) {
    return axios.post(`/v1/invoice/stats`, data);
}

export function exportStatisticsToExcel(data, config = {}) {
    return axios.post(`/v1/invoice/stats/xlsx`, data, config);
}

export function exportStatisticsToPdf(data, config = {}) {
    return axios.post(`/v1/invoice/stats/pdf`, data, config);
}

export function getReferenceNumber(config = {}) {
    return axios.get(`/v1/invoice/invoice-reference`, config);
}

const actions = {
    getInvoices,
    getInvoiceTypes,
    getInvoiceById,
    createInvoice,
    updateInvoice,
    exportInvoiceToPdf,
    getInvoiceStatistics,
    exportStatisticsToExcel,
    exportStatisticsToPdf,
    getReferenceNumber
}

export default actions;
