import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Tooltip,
    Zoom
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import Base58Table from "../../components/common/Base58Table";
import { getServiceGroups, deleteServiceGroup, setServiceGroupsPage, setServiceGroupsSize, setServiceGroupsParams } from '../../redux/serviceGroup/actions'
import { getDoctors } from '../../redux/user/actions'
import CreateOrUpdateServiceGroup from './CreateOrUpdateServiceGroup';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import { hasAnyRole } from '../../utils/Security';
import store from '../../redux/store';
import types from '../../redux/serviceGroup/types';

class ServiceGroups extends React.Component {

    emptyServiceGroup = {
        id: null,
        name: '',
        comment: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            serviceGroupToDelete: this.emptyServiceGroup,
            serviceGroupToEdit: null,
            openDeleteDialog: false,
            dialogOpen: false
        };
    }

    componentDidMount() {
        hasAnyRole(["SUPERADMIN", "ADMIN", "DOCTOR", "SYSTEM", "RECEPTIONIST"]) && this.props.getServiceGroups()
        hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM"]) && this.props.getDoctors()
    }

    componentWillUnmount() {
        this.props.setServiceGroupsSize(10, true)
        store.dispatch({
            type: types.RESET_SERVICE_GROUP_DATA
        })
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false, serviceGroupToEdit: null });
    }

    deleteServiceGroup = () => {
        this.props.deleteServiceGroup(this.state.serviceGroupToDelete.id).then((response) => {
            if (response.status !== 400) {
                this.setState({ openDeleteDialog: false }, () => this.props.getServiceGroups(), this.props.setServiceGroupsPage(0));
            }
        })
    }

    render() {
        const { dialogOpen } = this.state;
        const { isLoading, serviceGroups, serviceGroupsParams, serviceGroupsTotalElements } = this.props.serviceGroupReducer;

        let serviceGroupList = serviceGroups && serviceGroups.map((serviceGroup, index) => {
            return <TableRow key={index}>
                <TableCell>{serviceGroup.name && serviceGroup.name.length > 50 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={serviceGroup.name} style={{ cursor: 'pointer' }}>
                    <span>{serviceGroup.name && serviceGroup.name.length > 50 ? serviceGroup.name.substring(0, 50) + "..." : serviceGroup.name}</span>
                </Tooltip> :
                    serviceGroup.name}</TableCell>
                <TableCell>{serviceGroup.doctors.map(data => {
                    return data.firstName + " " + data.lastName + " (" + data.phone + ")"
                }).join(", ")}
                </TableCell>
                <TableCell>{serviceGroup.comment && serviceGroup.comment.length > 50 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={serviceGroup.comment} style={{ cursor: 'pointer' }}>
                    <span>{serviceGroup.comment && serviceGroup.comment.length > 50 ? serviceGroup.comment.substring(0, 50) + "..." : serviceGroup.comment}</span>
                </Tooltip> :
                    serviceGroup.comment}</TableCell>
                {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM"]) && <TableCell>
                    <Button title={this.props.t("edit")} className="default-button" style={{ marginRight: 10 }} color="secondary" onClick={() => this.setState({ dialogOpen: true, serviceGroupToEdit: serviceGroup.id })}>
                        <Edit />
                        {this.props.t("edit")}
                    </Button>
                    <Button title={this.props.t("delete")} className="default-button" color="primary" onClick={() => this.setState({ serviceGroupToDelete: serviceGroup, openDeleteDialog: true })}>
                        <Delete />
                        {this.props.t("delete")}
                    </Button>
                </TableCell>}
            </TableRow>
        })

        let columns = [
            {
                name: "name_service_group",
                dbName: 'name',
                width: '30%',
                sort: true
            },
            {
                name: "doctors",
                width: '30%'
            },
            {
                name: "comment",
                width: '25%'
            }
        ]

        const actions = { name: "", width: '15%' };

        if (hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM"])) {
            columns.push(actions);
        }

        return (
            <>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }} mt={1}>
                            <span>{this.props.t("service_groups")}</span>
                            {hasAnyRole(["SUPERADMIN", "ADMIN", "SYSTEM"]) && <Button color="primary" className="default-button" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ dialogOpen: true })} >
                                <Add />{this.props.t("add_new_service_group")}
                            </Button>}
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                        <Base58Table
                            isLoading={isLoading}
                            columns={columns}
                            data={serviceGroupList}
                            count={serviceGroupsTotalElements}
                            rowsPerPage={serviceGroupsParams.size}
                            page={serviceGroupsParams.page}
                            onPageChange={(e, page) => this.props.setServiceGroupsPage(page)}
                            onRowsPerPageChange={(e) => this.props.setServiceGroupsSize(e.target.value)}
                            onFilterChange={(params) => this.props.setServiceGroupsParams(params)}
                        />
                    </Grid>
                </Grid>
                <CreateOrUpdateServiceGroup
                    isOpen={dialogOpen}
                    onClose={this.closeDialog}
                    serviceGroupToEdit={this.state.serviceGroupToEdit}
                    onSave={() => this.setState({ dialogOpen: false, serviceGroupToEdit: null }, () => this.props.getServiceGroups())} />
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_service_group")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_service_group_message", { name: this.state.serviceGroupToDelete.name }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.deleteServiceGroup()}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    serviceGroupReducer: state.serviceGroupReducer
})

const mapActionsToProps = { getServiceGroups, getDoctors, deleteServiceGroup, setServiceGroupsPage, setServiceGroupsSize, setServiceGroupsParams }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(ServiceGroups))
