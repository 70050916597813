import types from './types';
import api from '../../api/infirmaryApi';
import { snackActions } from '../snackbar/reducer';
import { getLoggedUser } from '../auth/actions';
import i18next from 'i18next';
import store from '../store';

export const getInfirmaries = () => async (dispatch) => {
    await api.getInfirmaries({ params: store.getState().infirmaryReducer.infirmariesParams }).then(response => {
        if (store.getState().infirmaryReducer.infirmariesParams.page > 0 && response.data.content.length === 0) {
            dispatch(setInfirmariesPage(0));
        }
        dispatch({
            type: types.SET_INFIRMARIES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_INFIRMARIES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_infirmaries_error")));
    });
    return Promise.resolve();
}

export const setInfirmariesPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_INFIRMARIES_PAGE,
        payload: Number(value)
    });
    dispatch(getInfirmaries());
    return Promise.resolve();
};

export const setInfirmariesSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_INFIRMARIES_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getInfirmaries());
    }
    return Promise.resolve();
};

export const setInfirmariesParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_INFIRMARIES_PARAMS,
        payload: Object.assign(store.getState().infirmaryReducer.infirmariesParams, params)
    });
    dispatch(getInfirmaries());
    return Promise.resolve();
};

export const getInfirmaryList = (config = {}) => async (dispatch) => {
    await api.getInfirmaryList(config).then(response => {
        dispatch({
            type: types.SET_INFIRMARY_LIST,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_INFIRMARY_LIST,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_infirmary_list_error")));
    });
    return Promise.resolve();
}

export const createInfirmary = (data) => async (dispatch) => {
    return await api.createInfirmary(data).then((response) => {
        dispatch(snackActions.success(i18next.t("infirmary_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const updateInfirmary = (id, data) => async (dispatch) => {
    return await api.updateInfirmary(id, data).then((response) => {
        dispatch(snackActions.success(i18next.t("infirmary_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const deleteInfirmary = (id) => async (dispatch) => {
    return await api.deleteInfirmary(id).then((response) => {
        dispatch(snackActions.success(i18next.t("infirmary_deleted_successfully")));
        return response
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const getInfirmaryById = (id, config = {}) => async (dispatch) => {
    return await api.getInfirmaryById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_infirmary_by_id_error")));
    });
}
export const occupyInfirmary = (id, data) => async (dispatch) => {
    await api.occupyInfirmary(id, data).then(() => {
        dispatch(snackActions.success(i18next.t("occupy_infirmary_successfully")));
        dispatch(getLoggedUser())
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("occupy_infirmary_error")));
    });
    return Promise.resolve();
}