import axios from '../axiosConfig';

export function getServices(config = {}) {
    return axios.get(`/v1/service-data`, config);
}

export function getServiceById(id, config = {}) {
    return axios.get(`/v1/service-data/${id}`, config);
}

export function createService(data) {
    return axios.post(`/v1/service-data`, data);
}

export function updateService(id, data) {
    return axios.patch(`/v1/service-data/${id}`, data);
}

export function deleteService(id) {
    return axios.delete(`/v1/service-data/${id}`);
}

export function getServiceList() {
    return axios.get(`/v1/service-data/list`);
}

export function importServices(config = {}) {
    return axios.post(`/v1/service-data/import`, config);
}

export function getServicesByDoctorId(id, config = {}) {
    return axios.get(`/v1/service-data/list/doctors/${id}`);
}

export function changeServiceStatus(id, config) {
    return axios.patch(`/v1/service-data/${id}/active`, {}, config);
}

const actions = {
    getServices,
    getServiceById,
    getServiceList,
    createService,
    updateService,
    deleteService,
    importServices,
    getServicesByDoctorId,
    changeServiceStatus
}

export default actions;
