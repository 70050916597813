import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Button,
    Slide,
    FormControl,
    TableRow,
    TableCell,
    Zoom,
    Tooltip,
    TextField,
    Fab,
    Grid,
    Autocomplete
} from '@mui/material';
import PropTypes from 'prop-types';
import { Delete, Add, Close, Save } from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import { getExaminationById, updateExamination } from '../../redux/examination/actions'
import Base58Table from '../../components/common/Base58Table';
import NumberFormat from 'react-number-format';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ExaminationServicesModal extends Component {


    emptyExamination = {
        patientId: null,
        comment: '',
        diagnosisIds: [],
        internalNote: '',
        examinationServices: []
    }

    emptyExaminationService = {
        serviceId: null,
        price: null,
        quantity: null,
        discountAmount: null,
        discountPercentage: null,
        comment: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            examination: JSON.parse(JSON.stringify(this.emptyExamination)),
            examinationService: JSON.parse(JSON.stringify(this.emptyExaminationService)),
            initialExamination: JSON.parse(JSON.stringify(this.emptyExaminationService)),
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.examinationId && prevProps.examinationId !== this.props.examinationId) {
            this.props.getExaminationById(this.props.examinationId.toString()).then(response => {
                this.setState({
                    examination: JSON.parse(JSON.stringify(response.data)),
                    initialExamination: JSON.parse(JSON.stringify(response.data))
                });
            })
        }
    }

    addNewService = () => {
        let { examination } = this.state;
        examination.examinationServices.push(JSON.parse(JSON.stringify(this.emptyExaminationService)));
        this.setState({ examination: examination });
    }

    removeService = (index) => {
        let { examination } = this.state;
        examination.examinationServices.splice(index, 1);
        this.setState({ examination: examination });
    }

    calculateDiscount = (index) => {
        let { examination } = this.state;
        let result = (examination.examinationServices[index].price * examination.examinationServices[index].quantity) * (examination.examinationServices[index].discountPercentage / 100)
        examination.examinationServices[index].discountAmount = result.toFixed(2);
        this.setState({ examination: examination })
    }

    handleChangeQuantity = (e, index) => {
        if (e.target) {
            let { examination } = this.state;
            examination.examinationServices[index][e.target.name] = Number(e.target.value);
            examination.examinationServices[index].discountAmount = null
            examination.examinationServices[index].discountPercentage = null
            this.setState({ examination: examination })
        }
    }

    handleChangePrice = (values, sourceInfo, index) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            let { examination } = this.state;
            examination.examinationServices[index][sourceInfo.event.target.name] = floatValue;
            this.setState({ examination: examination })
        }
    }

    handleChangeDiscountPercentage = (values, sourceInfo, index) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            let { examination } = this.state;
            if (sourceInfo.event.target.name === "discountPercentage" && examination.examinationServices[index].discountAmount) {
                examination.examinationServices[index].discountAmount = null
            }
            examination.examinationServices[index].discountPercentage = floatValue;
            this.setState({ examination: examination })
        }
    }

    calculatePercentage = (index) => {
        let { examination } = this.state;
        let result = (examination.examinationServices[index].discountAmount / (examination.examinationServices[index].price * examination.examinationServices[index].quantity)) * 100
        examination.examinationServices[index].discountPercentage = result.toFixed(2);
        this.setState({ examination: examination })
    }

    handleChangeDiscountPrice = (values, sourceInfo, index) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            let { examination } = this.state;
            if (sourceInfo.event.target.name === "discountAmount" && examination.examinationServices[index].discountPercentage) {
                examination.examinationServices[index].discountPercentage = null
            }
            examination.examinationServices[index].discountAmount = floatValue
            this.setState({ examination: examination })
        }
    }

    handleChangeServiceComment = (e, name, index) => {
        let { examination } = this.state;
        examination.examinationServices[index][name] = e.target.value;
        this.setState({ examination: examination })
    }

    handleChangeService = (e, service, index) => {
        let { examination } = this.state;
        let examinationService = {};
        if (service !== null) {
            examinationService.serviceId = service.id
            examinationService.price = service.price
            examinationService.quantity = 1
            examination.examinationServices[index] = examinationService;
        } else {
            examinationService.serviceId = null
            examinationService.price = ''
            examinationService.quantity = null
            examinationService.discountAmount = null
            examinationService.discountPercentage = null
            examination.examinationServices[index] = examinationService;
        }
        this.setState({ examination: examination })
    }

    updateExamination = () => {
        let { examination } = this.state

        this.props.updateExamination(this.props.examinationId, examination).then(() => {
            this.setState({ examination: JSON.parse(JSON.stringify(this.emptyExamination)), examinationService: JSON.parse(JSON.stringify(this.emptyExaminationService)) });
            this.props.onSave()
        })
    }

    disabledOption = (id) => {
        let { examination } = this.state
        for (let i = 0; i < examination.examinationServices.length; i++) {
            if (examination.examinationServices[i].serviceId === id) {
                return true;
            }
        }
        return false;
    }

    validateForm() {
        let { examination, initialExamination } = this.state;
        let valid = true;
        if (JSON.stringify(examination) === JSON.stringify(initialExamination)) {
            valid = false
        }
        if (examination.examinationServices.length === 0) {
            valid = false
        }
        for (let i = 0; i < examination.examinationServices.length; i++) {
            if (examination.examinationServices[i].serviceId === null ||
                examination.examinationServices[i].price === undefined ||
                (examination.examinationServices[i].comment && examination.examinationServices[i].comment.length > 255) ||
                (examination.examinationServices[i].discountPercentage && examination.examinationServices[i].discountPercentage > 100) ||
                (examination.examinationServices[i].quantity === 0) ||
                (examination.examinationServices[i].discountAmount > (examination.examinationServices[i].price * examination.examinationServices[i].quantity))) {
                return valid = false;
            }
        }
        return valid;
    }

    render() {

        let { examination } = this.state
        const { serviceList } = this.props.serviceReducer

        let examinationServices = examination && examination.examinationServices.map((examinationService, index) => {
            return <React.Fragment key={index}>
                <TableRow>
                    <TableCell>
                        <FormControl>
                            {
                                serviceList.some(es => es.id === examinationService.serviceId) ?
                                    <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={<div style={{ fontSize: '14px', maxWidth: '250px' }}>
                                        {serviceList.some(es => es.id === examinationService.serviceId) ? <p><b>{this.props.t("code")}:</b> {serviceList.find(es => es.id === examinationService.serviceId).code}</p> : null}
                                        {serviceList.some(es => es.id === examinationService.serviceId) ? <p><b>{this.props.t("name")}:</b> {serviceList.find(es => es.id === examinationService.serviceId).name}</p> : null}
                                    </div>}>
                                        <Autocomplete
                                            required
                                            label={this.props.t("select_service")}
                                            placeholder={this.props.t("select_service")}
                                            name="selectedService"
                                            value={serviceList.some(es => es.id === examinationService.serviceId) ? serviceList.find(es => es.id === examinationService.serviceId) : null}
                                            getOptionLabel={(option) => option ? option.code + " - " + option.name : ""}
                                            options={serviceList}
                                            getOptionDisabled={option => this.disabledOption(option.id)}
                                            onChange={(event, service) => {
                                                this.handleChangeService(event, service, index)
                                            }}
                                            clearText={this.props.t("clear")}
                                            closeText={this.props.t("close")}
                                            openText={this.props.t("open")}
                                            renderInput={(params) => <TextField {...params} required label={this.props.t("select_service")} inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', spellCheck: false // disable autocomplete and autofill
                                            }} />}
                                        />
                                    </Tooltip>
                                    : <Autocomplete
                                        required
                                        label={this.props.t("select_service")}
                                        placeholder={this.props.t("select_service")}
                                        name="selectedService"
                                        value={serviceList.some(es => es.id === examinationService.serviceId) ? serviceList.find(es => es.id === examinationService.serviceId) : null}
                                        getOptionLabel={(option) => option ? option.code + " - " + option.name : ""}
                                        options={serviceList}
                                        getOptionDisabled={option => this.disabledOption(option.id)}
                                        onChange={(event, service) => {
                                            this.handleChangeService(event, service, index)
                                        }}
                                        clearText={this.props.t("clear")}
                                        closeText={this.props.t("close")}
                                        openText={this.props.t("open")}
                                        renderInput={(params) => <TextField {...params} required label={this.props.t("select_service")}
                                            error={examination.examinationServices[index].serviceId === null} inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', spellCheck: false // disable autocomplete and autofill
                                            }} />}
                                    />
                            }
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <TextField
                            required
                            id="quantity"
                            name="quantity"
                            type="number"
                            value={examination.examinationServices[index].quantity || ''}
                            error={examination.examinationServices[index].quantity === 0 || examination.examinationServices[index].quantity === ''}
                            onChange={(e) => this.handleChangeQuantity(e, index)}
                            disabled={examination.examinationServices[index].serviceId === null}
                            InputProps={{
                                inputProps: {
                                    min: 1
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell>
                        <NumberFormat
                            id="price"
                            name="price"
                            customInput={TextField}
                            decimalScale={2}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            value={examination.examinationServices[index].price}
                            onValueChange={(values, sourceInfo) => this.handleChangePrice(values, sourceInfo, index)}
                            allowNegative={false}
                            disabled={examination.examinationServices[index].serviceId === null}
                        />
                    </TableCell>
                    <TableCell>
                        <NumberFormat
                            id="discountAmount"
                            name="discountAmount"
                            title={this.props.t("press_enter_info_discount_amount")}
                            customInput={TextField}
                            decimalScale={2}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            value={examination.examinationServices[index].discountAmount || ''}
                            onValueChange={(values, sourceInfo) => this.handleChangeDiscountPrice(values, sourceInfo, index)}
                            disabled={examination.examinationServices[index].serviceId === null}
                            error={examination.examinationServices[index].discountAmount > (examination.examinationServices[index].price * examination.examinationServices[index].quantity) ? true : false}
                            allowNegative={false}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.calculatePercentage(index)
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell>
                        <NumberFormat
                            id="discountPercentage"
                            name="discountPercentage"
                            title={this.props.t("press_enter_info_discount_price")}
                            customInput={TextField}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={examination.examinationServices[index].discountPercentage || ''}
                            onValueChange={(values, sourceInfo) => this.handleChangeDiscountPercentage(values, sourceInfo, index)}
                            allowNegative={false}
                            disabled={examination.examinationServices[index].serviceId === null}
                            error={examination.examinationServices[index].discountPercentage > 100 ? true : false}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.calculateDiscount(index)
                                }
                            }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan="4">
                        <TextField
                            label={this.props.t("comment")}
                            name="comment"
                            value={examination.examinationServices[index].comment || ''}
                            onChange={(e) => this.handleChangeServiceComment(e, "comment", index)}
                            disabled={examination.examinationServices[index].serviceId === null}
                            error={examination.examinationServices[index].comment && examination.examinationServices[index].comment.length > 255 ? true : false}
                            helperText={examination.examinationServices[index].comment && examination.examinationServices[index].comment.length > 255 && this.props.t("number_regex_message", { max: 255 })}
                            inputProps={{ autoComplete: 'off', spellCheck: false }} />
                    </TableCell>
                    <TableCell align='right'>
                        <Fab title={this.props.t("delete")} className="table-fab" color="primary" aria-label="delete" onClick={() => this.removeService(index)}>
                            <Delete className="table-fab-icon" />
                        </Fab>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        })

        let columns = [
            {
                name: "service",
                width: '45%'
            },
            {
                name: "quantity",
                width: '10%',
            },
            {
                name: "price",
                width: '18%'
            },
            {
                name: "discount_amount",
                width: '15%',
            },
            {
                name: "discount_percentage_%",
                width: '12%',
            }
        ]


        return (

            <BootstrapDialog
                fullWidth
                onClose={() => this.props.onClose()}
                aria-labelledby="customized-dialog-title"
                open={this.props.isOpen}
                TransitionComponent={Transition}
                maxWidth={"xl"}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => this.props.onClose()}>
                    {this.props.t("services")}
                </BootstrapDialogTitle>
                <DialogContent>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Fab className="table-fab"
                                title={this.props.t("add")}
                                size="small"
                                color="primary"
                                style={{ marginLeft: 'auto' }}
                                onClick={() => this.addNewService()}>
                                <Add className="table-fab-icon" />
                            </Fab>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Base58Table
                                columns={columns}
                                data={examinationServices}
                                pagination={false}
                            />
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button className="default-button"
                                title={this.props.t("save")}
                                size="small"
                                color="primary"
                                style={{ marginLeft: 'auto' }}
                                onClick={() => this.updateExamination()}
                                disabled={!this.validateForm()}>
                                <Save className="table-fab-icon" />{this.props.t("save")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    examinationReducer: state.examinationReducer,
    serviceReducer: state.serviceReducer
})

const mapActionsToProps = { getExaminationById, updateExamination }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(ExaminationServicesModal))
