import React, { Component } from 'react';
import { Box, Drawer, List, ListItem, ListItemText, Icon } from '@mui/material';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import './Sidebar.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { hasAnyRole } from '../../utils/Security';

const drawerWidth = 240;

class Sidebar extends Component {

    state = {
        items: this.props.items,
    };

    handleDrawerToggle = () => {
        this.props.handleDrawerToggle();
    };

    handleClickItem = () => {
        if (this.props.mobileOpen) {
            this.props.handleDrawerToggle();
        }
    };


    handleClickItemWithChilds = (index) => {
        let { items } = this.state;
        items[index].opened = !items[index].opened;
        this.setState({ items: items });
    };

    render() {

        const drawer = (
            <div>
                <div className="navbar-name">
                    {"Medica"}
                </div>
                <List disablePadding>
                    <PerfectScrollbar style={{ height: 'calc(100vh - 70px)', marginTop: '7px' }}>
                        {this.state.items.map((item, parentIndex) => {
                            if (hasAnyRole(item.roles)) {
                                return <ListItem
                                    id={item.title + "-sidebar-item"}
                                    classes={{ selected: "sidebar-item-selected" }}
                                    className={this.props.location.pathname === item.link ? "sidebar-item-selected" : "sidebar-item-not-selected"}
                                    selected={this.props.location.pathname === item.link}
                                    button
                                    key={"parent-" + parentIndex}
                                    onClick={() => this.handleClickItem()}
                                    component={Link}
                                    to={item.link}>
                                    <Icon style={{ marginRight: 10, color: '#283630' }}>{item.icon}</Icon>
                                    <ListItemText style={{ color: '#283630' }} primary={this.props.t(item.title)} />
                                </ListItem>
                            }
                            return null;
                        })}
                    </PerfectScrollbar>
                </List>
                <div style={{ position: 'absolute', textAlign: 'center', bottom: 20, width: '100%', color: 'white' }}>
                    v{process.env.REACT_APP_VERSION || 'NOT_SET'}
                </div>
            </div>
        );

        const container = window !== undefined ? () => window.document.body : undefined;
        return (
            <Box
                style={{ display: this.props.location.pathname === '/choose-infirmary' ? 'none' : 'block' }}
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={this.props.mobileOpen}
                    onClose={this.handleDrawerToggle}
                    classes={{
                        paper: "drawerPaper",
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: "drawerPaper",
                    }}
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.authReducer.user
});

export default connect(mapStateToProps, {})(withRouter(withTranslation()(Sidebar)));
