import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    Tab,
    Tabs
} from '@mui/material';
import Languages from './Languages';
import SmsProvider from './SmsProvider';
import GoogleQuestionnaire from './GoogleQuestionnaire';
import Configuration from './Configuration';
import { CustomTabPanel, a11yProps } from '../../components/common/tabs';

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }

    handleChangeTab = (event, value) => {
        this.setState({ selectedTab: value });
    }

    render() {
        const { selectedTab } = this.state

        return (
            <Grid item container>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTab} onChange={this.handleChangeTab}>
                            <Tab label={this.props.t("sms")} {...a11yProps(0)} wrapped />
                            <Tab label={this.props.t("google_questionnaire")} {...a11yProps(1)} wrapped />
                            <Tab label={this.props.t("languages")} {...a11yProps(2)} wrapped />
                            <Tab label={this.props.t("credentials")} {...a11yProps(2)} wrapped />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={selectedTab} index={0} style={{ width: "100%" }}>
                        <SmsProvider />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={1} style={{ width: "100%" }}>
                        <GoogleQuestionnaire />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={2} style={{ width: "100%" }}>
                        <Languages />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={3} style={{ width: "100%" }}>
                        <Configuration />
                    </CustomTabPanel>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(withTranslation()(Settings))
