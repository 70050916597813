import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    TableCell,
    TableRow,
} from '@mui/material';
import { getCalendarEntriesRecords } from '../../../redux/calendar/actions'
import { sendBulkSms } from '../../../redux/setting/actions'
import WrappedDatePicker from '../../../components/common/WrappedDatePicker';
import moment from 'moment';
import Base58Table from '../../../components/common/Base58Table';
import { History, CheckBox, Send } from '@mui/icons-material';
import CustomMessagesModal from './CustomMessagesModal';

class CustomMessageTab extends Component {

    emptyData = {
        startTime: null
    }

    constructor(props) {
        super(props);
        this.state = {
            data: this.emptyData,
            selectedDate: null,
            calendarIds: [],
            phoneNumbers: [],
            openModal: false,
            displayData: false
        };
    }

    handleChangeDate = (date) => {
        let { data } = this.state
        if (date !== null && date._isValid) {
            data.startTime = moment(date).format("YYYY-MM-DDT00:00:00.000Z")
            this.setState({ data: data, selectedDate: date, displayData: false });
        }
    }

    getCalendarEntries = () => {
        let { data } = this.state
        let params = {}
        params.startTime = data.startTime
        this.props.getCalendarEntriesRecords({ params: params }).then(() => {
            this.setState({ displayData: true })
        })
    }

    handleChange = (cr) => {
        let { calendarIds, phoneNumbers } = this.state
        if (!calendarIds.includes(cr.id)) {
            calendarIds.push(cr.id)
            phoneNumbers.push(cr.patient.phone)
        } else {
            calendarIds.splice(calendarIds.indexOf(cr.id), 1);
            phoneNumbers.splice(phoneNumbers.indexOf(cr.patient.phone), 1)
        }
        this.setState({ calendarIds: calendarIds, phoneNumbers: phoneNumbers })
    }

    handleChangeAll = () => {
        const { calendarRecords } = this.props.calendarReducer
        let { calendarIds, phoneNumbers } = this.state
        if (calendarRecords.length === calendarIds.length) {
            this.setState({ calendarIds: [], phoneNumbers: [] })
        } else {
            calendarRecords.forEach(cr => {
                if (!calendarIds.includes(cr.id)) {
                    calendarIds.push(cr.id)
                    phoneNumbers.push(cr.patient.phone)
                }
                this.setState({ calendarIds: calendarIds, phoneNumbers: phoneNumbers })
            })
        }
    }

    closeDialog = () => {
        this.setState({ openModal: false });
    }

    sendMessages = (message) => {
        let { phoneNumbers } = this.state
        let request = {
            patientsPhones: phoneNumbers,
            smsText: message
        }
        this.props.sendBulkSms(request).then(() => {
            this.setState({ openModal: false, phoneNumbers: [], calendarIds: [] });
        })
    }

    render() {
        const { selectedDate, calendarIds, openModal } = this.state
        const { calendarRecords, calendarRecordsLoading } = this.props.calendarReducer

        let calendarRecordsList;
        if (calendarRecords && calendarRecords.length > 0) {
            calendarRecordsList = calendarRecords.map((cr, index) => {
                return <React.Fragment key={index}>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                style={{ padding: '0px' }}
                                edge="end"
                                onChange={() => this.handleChange(cr)}
                                checked={calendarIds.some(item => item === cr.id)}
                                inputProps={{ 'aria-labelledby': `checkbox-list-secondary-label-${index}` }}
                            />
                        </TableCell>
                        <TableCell>{moment(cr.startTime).format("HH:mm") + " - " + moment(cr.endTime).format("HH:mm")}</TableCell>
                        <TableCell>{cr.patient && cr.patient.firstName}</TableCell>
                        <TableCell>{cr.patient && cr.patient.lastName}</TableCell>
                        <TableCell>{cr.patient && cr.patient.phone}</TableCell>
                        <TableCell style={{ color: cr.infirmary && cr.infirmary.calendarHexColor }}>{cr.infirmary && cr.infirmary.name}</TableCell>

                        <TableCell>{cr.comment}</TableCell>

                    </TableRow>
                </React.Fragment>
            })
        } else {
            calendarRecordsList = <TableRow>
                <TableCell align="center" colSpan={7}>
                    {this.props.t("records_not_found")}
                </TableCell>
            </TableRow>
        }

        let columns = [
            {
                name: "",
                width: '5%'
            },
            {
                name: "time",
                width: '10%'
            },
            {
                name: "first_name",
                width: '15%'
            },
            {
                name: "last_name",
                width: '15%'
            },
            {
                name: "phone",
                width: '15%'
            },
            {
                name: "infirmary",
                width: '15%'
            },

            {
                name: "comment",
                width: '20%'
            }
        ]


        return (
            <Grid item container direction="row" spacing={2}>
                <Grid item container direction="column" xs={8} sm={8} md={8} lg={3} xl={3}>
                    <Grid item container>
                        <FormControl>
                            <WrappedDatePicker
                                label="date"
                                name="date"
                                maxDate={moment(new Date()).add(2, 'month')}
                                onChange={this.handleChangeDate}
                                value={selectedDate ? Date.parse(selectedDate) : null}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={8} sm={8} md={8} lg={1} xl={1}>
                    <Grid item container>
                        <FormControl>
                            <Button
                                style={{ marginTop: '8px', height: '40px' }}
                                title={this.props.t("get_history")}
                                color="primary"
                                onClick={() => this.getCalendarEntries()}
                                disabled={!selectedDate}>
                                <History />
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={8} sm={8} md={8} lg={8} xl={8}></Grid>
                {calendarRecords && calendarRecords.length > 0 && this.state.displayData && <Grid item container direction="column" xs={8} sm={8} md={8} lg={2} xl={2}>
                    <Grid item container>
                        <Button fullWidth title={calendarRecords.length === calendarIds.length ? this.props.t("uncheck_all") : this.props.t("check_all")} className="default-button" color="primary" onClick={() => this.handleChangeAll()}>
                            <CheckBox />
                            <span style={{ marginLeft: '5px' }}>{calendarRecords.length === calendarIds.length ? this.props.t("uncheck_all") : this.props.t("check_all")}</span>
                        </Button>
                    </Grid>
                </Grid>}
                {calendarRecords && calendarRecords.length > 0 && this.state.displayData && <Grid item container direction="column" xs={8} sm={8} md={8} lg={2} xl={2}>
                    <Grid item container>
                        <Button
                            className="default-button"
                            title={this.props.t("send_messages")}
                            color="secondary"
                            disabled={!calendarIds.length > 0}
                            onClick={() => this.setState({ openModal: true })}>
                            <Send />
                            <span style={{ marginLeft: '5px' }}>{this.props.t("send_messages")}</span>
                        </Button>
                    </Grid>
                </Grid>}
                {calendarRecords && this.state.displayData && <Grid item container>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                        <Base58Table
                            isLoading={calendarRecordsLoading}
                            columns={columns}
                            data={calendarRecordsList}
                            pagination={false}
                        />
                    </Grid>
                </Grid>}
                {openModal && <CustomMessagesModal
                    isOpen={openModal}
                    onClose={this.closeDialog}
                    sendMessage={(message) => this.sendMessages(message)} />}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    calendarReducer: state.calendarReducer
})

export default connect(mapStateToProps, { getCalendarEntriesRecords, sendBulkSms })(withTranslation()(CustomMessageTab))
