import React, { Component } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    IconButton
} from '@mui/material';
import PropTypes from 'prop-types';

import { Close } from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class ParamsDetailsDialog extends Component {

    render() {

        return (
            <BootstrapDialog
                onClose={this.props.onClose}
                open={this.props.isOpen}
                TransitionComponent={Transition}
                maxWidth={'md'}>
                <BootstrapDialogTitle onClose={this.props.onClose}>
                    {this.props.title ? this.props.t(this.props.title) : this.props.t("params_details")}
                </BootstrapDialogTitle>
                <DialogContent>
                    <pre>
                        {JSON.stringify(this.props.selectedParams, null, 2)}
                    </pre>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => this.props.onClose()}>
                        {this.props.closeButtonTitle}
                    </Button>
                </DialogActions>
            </BootstrapDialog>

        );
    }
}

export default withTranslation()(ParamsDetailsDialog);
