import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Zoom,
    Tooltip
} from '@mui/material';
import { Add, Edit, Delete, Help } from '@mui/icons-material';
import Base58Table from "../../components/common/Base58Table";
import { getUsers, getRoles, deleteUser, setUsersPage, setUsersSize, setUsersParams } from '../../redux/user/actions'
import CreateOrUpdateUser from './CreateOrUpdateUser';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import UploadSignatureModal from './UploadSignatureModal';

class Users extends React.Component {

    emptyUser = {
        id: null,
        firstName: '',
        lastName: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            userToDelete: this.emptyUser,
            userToEdit: null,
            openDeleteDialog: false,
            openFormDialog: false,
            openSignatureDialog: false,
            userId: null
        };
    }

    componentDidMount() {
        this.props.getUsers()
        this.props.getRoles()
    }

    componentWillUnmount() {
        this.props.setUsersSize(10, true)
    }

    closeDialog = () => {
        this.setState({ openFormDialog: false, userToEdit: null });
    }

    closeSignatureDialog = () => {
        this.setState({ openSignatureDialog: false, userId: null });
    }

    render() {
        const { openFormDialog, openSignatureDialog } = this.state;
        const { loadingUsers, users, usersParams, usersTotalElements } = this.props.userReducer;

        let usersList = users && users.map((user, index) => {
            return <TableRow key={index}>
                <TableCell>{user.firstName && user.firstName.length > 30 ? <Tooltip
                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={user.firstName} style={{ cursor: 'pointer' }}>
                    <span>{user.firstName && user.firstName.length > 30 ? user.firstName.substring(0, 30) + "..." : user.firstName}</span>
                </Tooltip> :
                    user.firstName}</TableCell>
                <TableCell>{user.lastName && user.lastName.length > 30 ? <Tooltip
                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={user.lastName} style={{ cursor: 'pointer' }}>
                    <span>{user.lastName && user.lastName.length > 30 ? user.lastName.substring(0, 30) + "..." : user.lastName}</span>
                </Tooltip> :
                    user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>
                    {this.props.t(user.role)} {user.role && user.role === "DOCTOR" ?
                        <Tooltip
                            disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={<span>
                                <p>{this.props.t("primary_title") + " : " + (user.primaryTitle === null ? "" : user.primaryTitle)} </p>
                                <p>{this.props.t("secondary_title") + " : " + (user.secondaryTitle === null ? "" : user.secondaryTitle)} </p>
                            </span>}>
                            <Help style={{ fontSize: '18px', cursor: 'pointer' }} />
                        </Tooltip>
                        : null}
                </TableCell>
                <TableCell>
                    {user.role && user.role === "DOCTOR" ? <Button title={this.props.t("signature")} className="default-button" color="primary" onClick={() => this.setState({ openSignatureDialog: true, userId: user.id })}>
                        <Edit />
                        {this.props.t("signature")}
                    </Button> : null}
                </TableCell>
                <TableCell>
                    <Button title={this.props.t("edit")} style={{ marginRight: 10 }} className="default-button" color="secondary" onClick={() => this.setState({ openFormDialog: true, userToEdit: user.id })}>
                        <Edit />
                        {this.props.t("edit")}
                    </Button>
                    <Button title={this.props.t("delete")} color="primary" className="default-button" onClick={() => this.setState({ userToDelete: user, openDeleteDialog: true })}>
                        <Delete />
                        {this.props.t("delete")}
                    </Button>
                </TableCell>
            </TableRow>
        })

        let columns = [
            {
                name: "first_name",
                dbName: 'first_name',
                width: '17%',
                sort: true
            },
            {
                name: "last_name",
                dbName: 'last_name',
                width: '17%',
                sort: true
            },
            {
                name: "email",
                dbName: 'email',
                width: '20%',
                sort: true
            },
            {
                name: "phone",
                width: '13%'
            },
            {
                name: "role",
                width: '10%',
            },
            {
                name: "signature",
                width: '8%',
            },
            {
                name: "",
                width: '15%',
            }
        ]

        return (
            <>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }} mt={1}>
                            <span>{this.props.t("users")}</span>
                            <Button color="primary" className="default-button" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ openFormDialog: true })} >
                                <Add />{this.props.t("add_new_user")}
                            </Button>
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                        <Base58Table
                            isLoading={loadingUsers}
                            columns={columns}
                            data={usersList}
                            count={usersTotalElements}
                            rowsPerPage={usersParams.size}
                            page={usersParams.page}
                            onPageChange={(e, page) => this.props.setUsersPage(page)}
                            onRowsPerPageChange={(e) => this.props.setUsersSize(e.target.value)}
                            onFilterChange={(params) => this.props.setUsersParams(params)}
                        />
                    </Grid>
                </Grid>
                <CreateOrUpdateUser
                    isOpen={openFormDialog}
                    onClose={this.closeDialog}
                    userToEdit={this.state.userToEdit}
                    onSave={() => this.setState({ openFormDialog: false, userToEdit: null }, () => this.props.getUsers())}
                />
                <UploadSignatureModal
                    isOpen={openSignatureDialog}
                    onClose={this.closeSignatureDialog}
                    userId={this.state.userId}
                />
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_user")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_user_message", { firstName: this.state.userToDelete.firstName, lastName: this.state.userToDelete.lastName }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.props.deleteUser(this.state.userToDelete.id).then(() => {
                        this.setState({ openDeleteDialog: false }, () => this.props.getUsers(), this.props.setUsersPage(0));
                    })}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer
})

const mapActionsToProps = { getUsers, getRoles, deleteUser, setUsersPage, setUsersSize, setUsersParams }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Users))
