import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    Tab,
    Tabs
} from '@mui/material';
import { getBalance, getHistory, setHistoryPage, setHistorySize, setHistoryParams, getNotificationType } from '../../redux/setting/actions'
import { syncCalendarEntry } from '../../redux/calendar/actions'
import moment from 'moment';
import { Sync } from '@mui/icons-material'
import { hasRole } from '../../utils/Security';
import HistoryMessagesTab from './smsTabs/HistoryMessagesTab';
import CustomMessageTab from './smsTabs/CustomMessageTab';
import ConfigurationMessageTab from './smsTabs/ConfigurationMessageTab';
import types from '../../redux/setting/types'
import store from '../../redux/store';
import { CustomTabPanel, a11yProps } from '../../components/common/tabs';

class SmsProvider extends Component {

    emptyData = {
        startDate: null,
        endDate: null
    }

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: true,
            selectedStartDate: null,
            selectedEndDate: null,
            data: this.emptyData,
            displayData: false,
            selectedTab: 0
        };
    }

    componentDidMount() {
        Promise.all([
            this.props.getNotificationType(),
            this.props.getBalance()])
            .then(() => {
                this.setState({ mainLoading: false });
            })
            .catch(error => {
                this.setState({ mainLoading: false });
            })
    }

    syncCalendarEntry = () => {
        let params = {}
        params.startTime = moment(new Date())
        this.props.syncCalendarEntry(params)
    }

    componentWillUnmount() {
        store.dispatch({
            type: types.SET_HISTORY,
            payload: []
        });
        store.dispatch({
            type: types.SET_HISTORY_PARAMS,
            payload: { page: 0, size: 10 }
        });
    }

    handleChangeTab = (event, value) => {
        this.props.calendarReducer.calendarRecords = []
        this.setState({ selectedTab: value, selectedEndDate: null, selectedStartDate: null, data: this.emptyData, displayData: false });
    }

    render() {
        const { mainLoading, selectedTab } = this.state
        const { balance } = this.props.settingReducer;

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        let balanceTitle = balance ? <strong>{this.props.t("balance") + ": " + balance + " $"}</strong> : <strong>{this.props.t("balance") + ": "}</strong>

        return (<>
            {hasRole("SUPERADMIN") && <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={3} lg={2} xl={2} mt={1}>
                    <Grid item container>
                        <Button title={this.props.t("sync_data")} className="default-button" onClick={() => this.syncCalendarEntry()}>
                            <Sync className="table-fab-icon" />
                            {this.props.t("sync_calendar_entry")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>}
            <Grid item container direction="row">
                <Grid item container direction="column" xs={6} sm={6} md={6} lg={3} xl={3}>
                    <Card>
                        <CardHeader subheader={balanceTitle} />
                    </Card>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                    <Box>
                        <Tabs value={selectedTab} onChange={this.handleChangeTab} selectionFollowsFocus >
                            <Tab label={this.props.t("configuration_message")} {...a11yProps(0)} wrapped />
                            <Tab label={this.props.t("history")} {...a11yProps(1)} wrapped />
                            <Tab label={this.props.t("custom_message")} {...a11yProps(1)} wrapped />
                        </Tabs>
                    </Box>
                    <Divider />
                    <CustomTabPanel value={selectedTab} index={0} style={{ width: "100%" }}>
                        <ConfigurationMessageTab />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={1} style={{ width: "100%" }}>
                        <HistoryMessagesTab />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={2} style={{ width: "100%" }}>
                        <CustomMessageTab />
                    </CustomTabPanel>
                </Grid>
            </Grid>
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer,
    calendarReducer: state.calendarReducer
})

const mapActionsToProps = { getBalance, getHistory, setHistoryPage, setHistorySize, setHistoryParams, syncCalendarEntry, getNotificationType }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(SmsProvider))
