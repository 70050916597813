import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Button,
    FormControl,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    TableBody,
    Typography
} from '@mui/material';
import './Statistics.css'
import { getServicesStatistics, exportStatisticsPerServiceToPdf, exportStatisticsPerServiceToExcel } from '../../redux/examination/actions'
import { QueryStats } from '@mui/icons-material';
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import moment from 'moment';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import PerfectScrollbar from 'react-perfect-scrollbar'
import NumberFormat from 'react-number-format';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { RiFileExcel2Fill } from 'react-icons/ri'
import { PictureAsPdf } from '@mui/icons-material';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    PieController,
    ArcElement,
    LineController,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    PieController,
    LineController,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
);

class DashboardStatisticsProfitPerServices extends Component {

    emptyData = {
        serviceIds: [],
        startDate: null,
        endDate: null
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedServices: [],
            selectedStartDate: null,
            selectedEndDate: null,
            data: this.emptyData
        };
    }

    componentWillUnmount() {
        this.props.examinationReducer.examinationStatsPerServices = null
    }

    handleChangeService = (e, types) => {
        let { data } = this.state
        let selectedServices = { ...this.state.selectedServices }
        if (types.length === 0) {
            data.serviceIds = null
            this.props.examinationReducer.examinationStatsPerServices = null
            this.setState({ data: data, selectedServices: [] });
        } else {
            selectedServices = types;
            data.serviceIds = []
            selectedServices.forEach(s => {
                data.serviceIds.push(s.id)
            })
            this.props.examinationReducer.examinationStatsPerServices = null
            this.setState({ selectedServices: selectedServices, data: data });
        }
    }

    handleChangeStartDate = (date) => {
        let { data } = this.state
        data.startDate = moment(date)
        this.props.examinationReducer.examinationStatsPerServices = null
        this.setState({ data: data, selectedStartDate: date });
    }

    handleChangeEndDate = (date) => {
        let { data } = this.state
        data.endDate = moment(date).add(24, 'hours')
        this.props.examinationReducer.examinationStatsPerServices = null
        this.setState({ data: data, selectedEndDate: date });
    }

    getStatistics = () => {
        this.props.getServicesStatistics(this.state.data)
    }

    randomColor = () => {
        const red = Math.floor((1 + Math.random()) * 256 / 2);
        const green = Math.floor((1 + Math.random()) * 256 / 2);
        const blue = Math.floor((1 + Math.random()) * 256 / 2);
        return "rgba(" + red + ", " + green + ", " + blue + ",0.4)";
    }

    render() {
        const { selectedServices, selectedStartDate, selectedEndDate } = this.state
        const { serviceList } = this.props.serviceReducer
        const { examinationStatsPerServices } = this.props.examinationReducer

        const optionsPieChart = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.label || '';
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed !== null) {
                                label += new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(context.parsed);
                            }
                            return label;
                        }
                    }
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        boxHeight: 8,
                        boxWidth: 20,
                        font: {
                            size: 10
                        }
                    }
                },
                datalabels: {
                    color: "grey",
                    font: {
                        weight: 700,
                        family: 'Roboto'
                    },
                    formatter(value, categories) {
                        let sum = 0;
                        let dataArr = categories.chart.data.datasets[0].data;
                        dataArr.map(data => {
                            return sum += Number(data)
                        });
                        let percentage = (value * 100 / sum).toFixed(2) + "%";
                        return percentage;
                    },
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
            }
        }

        return (
            <>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6">{this.props.t("statistics_for_services")}</Typography>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={4} lg={3} xl={3}>
                                <FormControl>
                                    <WrappedAutocomplete
                                        label={this.props.t("select_service")}
                                        placeholder={this.props.t("select_service")}
                                        name="selectedServices"
                                        value={selectedServices}
                                        getOptionLabel={(option) => option ? option.name : ""}
                                        options={serviceList}
                                        multiple={true}
                                        onChange={(event, value) => {
                                            this.handleChangeService(event, value)
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container direction="column" xs={5} sm={5} md={3} lg={3} xl={2}>
                                <Grid item container>
                                    <FormControl>
                                        <WrappedDatePicker
                                            label="start_date"
                                            name="startDate"
                                            maxDate={moment(selectedEndDate).add(-1, 'day')}
                                            onChange={this.handleChangeStartDate}
                                            value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" xs={5} sm={5} md={3} lg={3} xl={2}>
                                <Grid item container>
                                    <FormControl>
                                        <WrappedDatePicker
                                            label="end_date"
                                            name="endDate"
                                            minDate={moment(selectedStartDate).add(1, 'day')}
                                            maxDate={moment(new Date()).add(1, 'year')}
                                            onChange={this.handleChangeEndDate}
                                            value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" xs={2} sm={2} md={1} lg={1} xl={1}>
                                <Grid item container>
                                    <FormControl>
                                        <Button
                                            style={{ marginTop: '8px', height: '40px', width: '70px' }}
                                            title={this.props.t("get_statistics")}
                                            color="primary"
                                            onClick={() => this.getStatistics()}
                                            disabled={!selectedEndDate}>
                                            <QueryStats />
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2}>
                            {examinationStatsPerServices && examinationStatsPerServices.serviceCostList.length > 0 && <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                <PerfectScrollbar style={{ maxHeight: '68vh' }}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }} width='70%'>{this.props.t("service")}</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} width='30%' align="right">{this.props.t("total")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {examinationStatsPerServices.serviceCostList.map((data, index) => {
                                                    return <TableRow key={index}>
                                                        <TableCell>
                                                            {data.serviceName}
                                                        </TableCell>
                                                        <TableCell align='right' style={{ fontWeight: 'bold' }}>
                                                            <NumberFormat value={data.serviceCost} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                                <TableRow style={{ backgroundColor: 'lightgray', borderTop: '2px solid black' }}>
                                                    <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("total")}</TableCell>
                                                    <TableCell align='right' style={{ fontWeight: 'bold' }}>
                                                        <NumberFormat value={examinationStatsPerServices.serviceTotal} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Button color="primary" className="default-button" style={{ marginTop: 10 }} onClick={() => this.props.exportStatisticsPerServiceToExcel(this.state.data)} >
                                        <RiFileExcel2Fill style={{ fontSize: '18px' }} /> {this.props.t("excel")}
                                    </Button>
                                    <Button color="error" className="default-button" style={{ marginTop: 10, marginLeft: 10 }} onClick={() => this.props.exportStatisticsPerServiceToPdf(this.state.data)} >
                                        <PictureAsPdf style={{ fontSize: '18px' }} /> {this.props.t("pdf")}
                                    </Button>
                                </PerfectScrollbar>
                            </Grid>}{/* 
                            <Grid item container direction="column" lg={1} xl={1}></Grid> */}
                            {examinationStatsPerServices && examinationStatsPerServices.serviceCostList.length > 1 && <Grid item container direction="column" xs={12} sm={6} md={6} lg={5} xl={5} /* justifyContent="center"  alignItems="center" */>
                                <div className="chart-container">
                                    <Pie
                                        data={{
                                            labels: examinationStatsPerServices.serviceCostList.map(d => { return d.serviceName }),
                                            datasets: [{
                                                data: examinationStatsPerServices.serviceCostList.map(data => { return data.serviceCost }),
                                                backgroundColor: examinationStatsPerServices.serviceCostList.map(data => { return this.randomColor() }),
                                                hoverOffset: 4
                                            }]
                                        }}
                                        options={optionsPieChart}
                                    />
                                </div>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    serviceReducer: state.serviceReducer,
    examinationReducer: state.examinationReducer
})

export default connect(mapStateToProps, { getServicesStatistics, exportStatisticsPerServiceToExcel, exportStatisticsPerServiceToPdf })(withTranslation()(DashboardStatisticsProfitPerServices))