import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { setSnackbar } from "../../redux/snackbar/reducer";
import i18next from 'i18next';

const CustomizedSnackbars = () => {
    const dispatch = useDispatch();
    const snackbarOpen = useSelector(state => state.snackbarReducer.snackbarOpen);
    const snackbarType = useSelector(state => state.snackbarReducer.snackbarType);
    const snackbarMessage = useSelector(state => state.snackbarReducer.snackbarMessage);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(setSnackbar(false, snackbarType, snackbarMessage));
    };

    return (
        <Snackbar open={snackbarOpen} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }} autoHideDuration={5000} onClose={handleClose}>
            <Alert severity={snackbarType} elevation={12} square={true} variant="filled" onClose={handleClose} color={snackbarType} closeText={i18next.t("close")}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    );
};

export default CustomizedSnackbars;
